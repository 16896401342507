import React, { useState, useRef, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import AuthService from "../services/auth.service";
// import UserService from "../services/user.service";

// import { ReactComponent as BGsvg } from '../images/pattern.svg';
import { ReactComponent as BGsvg } from '../images/pattern.svg';


const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const Login = (props) => {

    useEffect(() => {
        // Kdyz user zustal prihlasen, a otevre server adresu, tak ho to rovnou presmeruje na user/admin stranku
        try {
            let us = localStorage.getItem("user")

            if (JSON.parse(us).roles[0] === 'ROLE_ADMIN') {
                props.history.push("/admin_all");
                window.location.reload();
            }
            if (JSON.parse(us).roles[0] === 'ROLE_MODERATOR') {
                props.history.push("/admin_all");
                window.location.reload();
            }
            if (JSON.parse(us).roles[0] === 'ROLE_USER') {
                props.history.push("/user");
                window.location.reload();
            }
        }
        catch { }
    }, [])


    const form = useRef();
    const checkBtn = useRef();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = (e) => {
        e.preventDefault();

        setMessage("");
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {

            AuthService.login(username, password).then(
                () => {
                    let us = localStorage.getItem("user")

                    // console.log('Login localStorage xxxxxxxxx',JSON.parse(us))

                    if (JSON.parse(us).roles[0] === 'ROLE_ADMIN') props.history.push("/admin_all");
                    if (JSON.parse(us).roles[0] === 'ROLE_MODERATOR') props.history.push("/admin_all");
                    if (JSON.parse(us).roles[0] === 'ROLE_USER') props.history.push("/user");
                    window.location.reload();
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setLoading(false);
                    setMessage(resMessage);
                }
            );
        } else {
            setLoading(false);
        }
    };

    return (
        // <div className="bg-4 w100 h100" style={{}}>
        <div className="col-md-12 pos-r">

            {/* <div className="dfc pos-a bg-9x  h100"
                style={{
                    top: '0',
                    width: '1226px',
                     left: '50%',
                    transform: 'translate(-50%)'
                }} > */}

                {/* <svg className="dfc bg-12 pos-a" height="100%" width="100%">
                    <BGsvg
                        stroke={'red'}
                        strokeWidth="0.5"
                        fill={'redx'}
                        // width="100%"
                        // height='100%'
                        style={{
                            // height: '100px',
                            // width: '20px',
                            // userSelect: 'none',
                            filter: 'brightness(0) saturate(100%) invert(96%) sepia(0%) saturate(2%) hue-rotate(252deg) brightness(107%) contrast(90%)'
                        }}
                    />
                </svg> */}

            {/* </div> */}

            <div className="card card-container" >
                <img
                    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                    alt="profile-img"
                    className="profile-img-card"
                />

                <Form onSubmit={handleLogin} ref={form}>
                    <div className="form-group">
                        <label htmlFor="username">Uživatelské jméno</label>
                        <Input
                            type="text"
                            className="form-control"
                            name="username"
                            value={username}
                            onChange={onChangeUsername}
                            validations={[required]}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Heslo</label>
                        <Input
                            type="password"
                            className="form-control"
                            name="password"
                            value={password}
                            onChange={onChangePassword}
                            validations={[required]}
                        />
                    </div>

                    <div className="form-group">
                        <button className="btn btn-primary btn-block" disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>Přihlásit</span>
                        </button>
                    </div>

                    {message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                        </div>
                    )}
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
            </div>

            {/* </div> */}
        </div>
    );
};

export default Login;
