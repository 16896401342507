import React, { useState, useEffect } from "react";
import "./Board.css";

import { ReactComponent as IcoRight } from '../images/angle-right.svg';
import { ReactComponent as IcoBut } from '../images/o-button-svgrepo-com.svg';


const GetPhoneDetail = (pr) => { //***************************************************************************************************

    const nadpis = (t1) => {
        return (
            <>
                <div className="df bg-16" style={{ minHeight: '50px' }}>
                    <div className="dfc f1" style={{ width: '2%' }}></div>

                    <div className="dfc f7 jcs fs-c4 fo-b" >{t1}</div>
                    <div className="dfc f12 jce" ></div>
                    <div className="dfc f05 jcs" ></div>

                    <div className="dfc f1" style={{ width: '2%' }}></div>
                </div >
            </>
        )
    }

    const radek = (t1, t2, t3) => {
        return (
            <>
                <div className="df bg-15" style={{ minHeight: '50px' }}>
                    <div className="dfc f1" style={{ width: '2%' }}></div>

                    <div className="dfc f7 jcs fs-c4" >{t1}</div>
                    <div className="dfc f9 jce fo-b" >{t2}</div>
                    <div className="dfc f1 jcs" ></div>
                    <div className="dfc f05 jcs" ></div>
                    <div className="dfc f2 jcs fs-c4" >{t3}</div>

                    <div className="dfc f1" style={{ width: '2%' }}></div>
                </div >
                <hr className="cara1"></hr>
            </>
        )
    }

    const radek3 = (n, t1, t2, t3, j) => {
        return (
            <>
                <div className="df bg-15" style={{ minHeight: '50px' }}>
                    <div className="dfc f1" style={{ width: '2%' }}></div>

                    <div className="dfc f6 jcs fs-c4" >{n}</div>
                    <div className="dfc f10 jce fo-b bg-8x" >{`${t1} / ${t2} / ${t3}`}</div>
                    <div className="dfc f1 jcs" ></div>
                    <div className="dfc f05 jcs" ></div>
                    <div className="dfc f2 jcs fs-c4" >{j}</div>

                    <div className="dfc f1" style={{ width: '2%' }}></div>
                </div >
                <hr className="cara1"></hr>
            </>
        )
    }

    const radek2 = (n, t1, t2, j) => {
        return (
            <>
                <div className="df bg-15" style={{ minHeight: '50px' }}>
                    <div className="dfc f1" style={{ width: '2%' }}></div>

                    <div className="dfc f6 jcs fs-c4" >{n}</div>
                    <div className="dfc f10 jce fo-b bg-8x" >{`${t1} / ${t2}`}</div>
                    <div className="dfc f1 jcs" ></div>
                    <div className="dfc f05 jcs" ></div>
                    <div className="dfc f2 jcs fs-c4" >{j}</div>

                    <div className="dfc f1" style={{ width: '2%' }}></div>
                </div >
                <hr className="cara1"></hr>
            </>
        )
    }

    const mezera = () => {
        return (
            <hr className="cara5"></hr>
        )
    }

    let cont = []

    if (pr.resp?.reg && pr.resp?.online === 'true' && pr.resp?.connect === 'true') {

        if (pr.resp.invert === 'Alpha ESS') {

            cont.push(radek('SN', pr.resp.reg.sn, ''))

            cont.push(nadpis('Inverter data'))
            cont.push(radek2('PV power', 
                pr.resp.reg.pv1_P, 
                pr.resp.reg.pv2_P ? pr.resp.reg.pv2_P : '0', 
                'W'))

            cont.push(mezera())
            cont.push(radek3('Phase grid voltage',
                (pr.resp.reg.inv_L1_U / 10).toFixed(0),
                (pr.resp.reg.inv_L2_U / 10).toFixed(0),
                (pr.resp.reg.inv_L3_U / 10).toFixed(0), 'V'))
            cont.push(radek3('Phase grid power', pr.resp.reg.inv_L1_P, pr.resp.reg.inv_L2_P, pr.resp.reg.inv_L3_P, 'W'))

            cont.push(mezera())
            cont.push(radek3('Active power', pr.resp.reg.L1_P, pr.resp.reg.L2_P, pr.resp.reg.L3_P, 'W'))
            cont.push(radek('Total active power', pr.resp.reg.tot_P, 'W'))

            cont.push(nadpis('Battery'))
            cont.push(radek('SOC', (pr.resp.reg.SOC / 10).toFixed(1), '%'))
            cont.push(radek('Power', (pr.resp.reg.bat_P/100).toFixed(0), 'W'))
            cont.push(radek('Voltage', (pr.resp.reg.bat_U / 10).toFixed(0), 'V'))
            cont.push(radek('Current', (pr.resp.reg.bat_I / 10).toFixed(1), 'A'))

        }

        else if (pr.resp.invert === 'Goodwe') {

            cont.push(radek('SN', pr.resp.reg.sn, ''))
            cont.push(radek('Model name', pr.resp.reg.model, ''))

            // Inverter data ******************************')
            cont.push(nadpis('Inverter data'))
            cont.push(radek2('PV voltage', 
                (pr.resp.reg.pv1_U / 10).toFixed(1),
                (pr.resp.reg.pv2_U / 10).toFixed(1), 'V'))
            cont.push(radek2('PV current', 
                (pr.resp.reg.pv1_I / 10).toFixed(1), 
                (pr.resp.reg.pv2_I / 10).toFixed(1), 'A'))
            cont.push(radek2('PV power', pr.resp.reg.pv1_P, pr.resp.reg.pv2_P, 'W'))
            // cont.push(radek('PV mode', pr.resp.reg.pv_mode, ''))
            cont.push(mezera())

            cont.push(radek3('Phase grid voltage',
                (pr.resp.reg.inv_f1_U / 10).toFixed(1),
                (pr.resp.reg.inv_f2_U / 10).toFixed(1),
                (pr.resp.reg.inv_f3_U / 10).toFixed(1), 'V'))
            cont.push(radek3('Phase grid current',
                (pr.resp.reg.inv_f1_I / 10).toFixed(1),
                (pr.resp.reg.inv_f2_I / 10).toFixed(1),
                (pr.resp.reg.inv_f3_I / 10).toFixed(1), 'A'))
            cont.push(radek3('Phase grid frequency',
                (pr.resp.reg.inv_f1_F / 100).toFixed(2),
                (pr.resp.reg.inv_f2_F / 100).toFixed(2),
                (pr.resp.reg.inv_f3_F / 100).toFixed(2), 'Hz'))
            cont.push(radek3('Phase grid power', pr.resp.reg.inv_f1_P, pr.resp.reg.inv_f2_P, pr.resp.reg.inv_f3_P, 'W'))

            cont.push(radek('Total inv power', pr.resp.reg.inv_tot_P, 'W'))
            cont.push(radek('Total load power', pr.resp.reg.tot_P, 'W'))


            // Meter data ******************************')
            cont.push(nadpis('Meter data'))
            cont.push(radek3('Voltage',
                (pr.resp.reg.f1_U / 10).toFixed(1),
                (pr.resp.reg.f2_U / 10).toFixed(1),
                (pr.resp.reg.f3_U / 10).toFixed(1), 'V'))
            cont.push(radek3('Current',
                (pr.resp.reg.f1_I / 10).toFixed(1),
                (pr.resp.reg.f2_I / 10).toFixed(1),
                (pr.resp.reg.f3_I / 10).toFixed(1), 'A'))
            cont.push(radek3('Active power', pr.resp.reg.f1_AP, pr.resp.reg.f2_AP, pr.resp.reg.f3_AP, 'W'))
            cont.push(radek('Total active power', pr.resp.reg.tot_AP, 'W'))

            // BMS information **************************')
            cont.push(nadpis('Battery'))
            cont.push(radek('SOC', pr.resp.reg.SOC, '%'))
            cont.push(radek('Voltage', (pr.resp.reg.bat_U / 10).toFixed(1), 'V'))
            cont.push(radek('Current', (pr.resp.reg.bat_I / 10).toFixed(1), 'A'))
            cont.push(radek('Power', pr.resp.reg.bat_P, 'W'))

        }

        else if (pr.resp.invert === 'Solax') {

            cont.push(radek('SN', pr.resp.reg.sn, ''))
            cont.push(radek('Model name', pr.resp.reg.model, ''))

            // Inverter data ******************************')
            cont.push(nadpis('Inverter data'))
            cont.push(radek2('PV voltage', pr.resp.reg.pv1_U, pr.resp.reg.pv2_U, 'V'))
            cont.push(radek2('PV current', pr.resp.reg.pv1_I, pr.resp.reg.pv2_I, 'A'))
            cont.push(radek2('PV power', pr.resp.reg.pv1_P, pr.resp.reg.pv2_P, 'W'))
            // cont.push(radek('PV mode', pr.resp.reg.pv_mode, ''))
            cont.push(mezera())

            cont.push(radek3('Phase grid voltage',
                (pr.resp.reg.inv_f1_U / 10).toFixed(1),
                (pr.resp.reg.inv_f2_U / 10).toFixed(1),
                (pr.resp.reg.inv_f3_U / 10).toFixed(1), 'V'))
            cont.push(radek3('Phase grid current',
                (pr.resp.reg.inv_f1_I / 10).toFixed(1),
                (pr.resp.reg.inv_f2_I / 10).toFixed(1),
                (pr.resp.reg.inv_f3_I / 10).toFixed(1), 'A'))
            cont.push(radek3('Phase grid frequency',
                (pr.resp.reg.inv_f1_F / 100).toFixed(2),
                (pr.resp.reg.inv_f2_F / 100).toFixed(2),
                (pr.resp.reg.inv_f3_F / 100).toFixed(2), 'Hz'))
            cont.push(radek3('Phase grid power', pr.resp.reg.inv_f1_P, pr.resp.reg.inv_f2_P, pr.resp.reg.inv_f3_P, 'W'))

            cont.push(radek('Total inv power', pr.resp.reg.inv_tot_P, 'W'))
            cont.push(radek('Total load power', pr.resp.reg.tot_P, 'W'))


            // Meter data ******************************')
            cont.push(nadpis('Meter data'))
            cont.push(radek3('Voltage',
                (pr.resp.reg.f1_U / 10).toFixed(1),
                (pr.resp.reg.f2_U / 10).toFixed(1),
                (pr.resp.reg.f3_U / 10).toFixed(1), 'V'))
            cont.push(radek3('Current',
                (pr.resp.reg.f1_I / 10).toFixed(1),
                (pr.resp.reg.f2_I / 10).toFixed(1),
                (pr.resp.reg.f3_I / 10).toFixed(1), 'A'))
            cont.push(radek3('Active power', pr.resp.reg.f1_AP, pr.resp.reg.f2_AP, pr.resp.reg.f3_AP, 'W'))
            cont.push(radek('Total active power', pr.resp.reg.tot_AP, 'W'))

            // BMS information **************************')
            cont.push(nadpis('Battery'))
            cont.push(radek('SOC', pr.resp.reg.SOC, '%'))
            cont.push(radek('Voltage', pr.resp.reg.bat_U, 'V'))
            cont.push(radek('Current', pr.resp.reg.bat_I, 'A'))
            cont.push(radek('Power', pr.resp.reg.bat_P, 'W'))
        }

    }
    else {
        cont.push(
            <div className="df jc-c fdir-c bg-15 w100" style={{ height: '70px' }}>
                <GetPhoneUWstav resp={pr.resp} />
            </div>
        )
    }
    return (
        <>
            {cont}
        </>
    )
}
export default GetPhoneDetail;

export const GetPhoneControl = (pr) => { //***************************************************************************************************

    const [content, setContent] = useState()

    const radekBtn = (t1, callb, par) => {
        return (
            <>
                <div className="df bg-15" style={{ minHeight: '60px' }}>
                    <div className="dfc f1" style={{ width: '2%' }}></div>
                    <div className="dfc f12 jcs fs-c4" >{t1}</div>
                    <div className="dfc f5 jcs" ></div>
                    <div className="dfc f3 jcs fs-c4" ><BtnSvg ico={IcoBut} hand={() => callb(par)} /></div>

                    <div className="dfc f1" style={{ width: '2%' }}></div>
                </div >
                <hr className="cara1"></hr>
            </>
        )
    }
    const mezera = () => {
        return (
            <hr className="cara5"></hr>
        )
    }

    useEffect(() => {

        let cont = []

        cont.push(radekBtn('47510 to 50', pr.fn.testcontrol, 1))
        cont.push(radekBtn('47510 to 0', pr.fn.testcontrol, 2))
        // cont.push(radekBtn('Reset Uni:wattu', pr.fn.testcontrol, 3))
        // cont.push(radekBtn('Reset Uni:wattu', pr.fn.testcontrol, 4))
        // cont.push(radekBtn('Reset Uni:wattu', pr.fn.testcontrol, 5))

        setContent(cont)

    }, [pr]);

    return (
        <>
            {content}
        </>
    )
}



export const GetPhoneSettings = (pr) => { //***************************************************************************************************

    const [stridac, setStridac] = useState(pr.stridac)
    const [edit, setEdit] = useState(0)
    const [content, setContent] = useState()

    const nadpis = (t1) => {
        return (
            <>
                <div className="df bg-16" style={{ minHeight: '50px' }}>
                    <div className="dfc f1" style={{ width: '2%' }}></div>

                    <div className="dfc f7 jcs fs-c4 fo-b" >{t1}</div>
                    <div className="dfc f12 jce" ></div>
                    <div className="dfc f05 jcs" ></div>

                    <div className="dfc f1" style={{ width: '2%' }}></div>
                </div >
            </>
        )
    }

    const radek = (t1, t2, num) => {
        return (
            <>
                <div className="df bg-15" style={{ minHeight: '50px' }}>
                    <div className="dfc f1" style={{ width: '2%' }}></div>

                    <div className="dfc f12 jcs fs-c4" >{t1}</div>
                    <div className="dfc f8 jce fo-b" >{t2}</div>
                    <div className="dfc f2 jcs" ></div>
                    <div className="dfc f1 jcs fs-c4" ><BtnSvg ico={IcoRight} hand={() => setEdit(num)} /></div>

                    <div className="dfc f1" style={{ width: '2%' }}></div>
                </div >
                <hr className="cara1"></hr>
            </>
        )
    }
    const radekBtn = (t1, callb) => {
        return (
            <>
                <div className="df bg-15" style={{ minHeight: '50px' }}>
                    <div className="dfc f1" style={{ width: '2%' }}></div>
                    <div className="dfc f12 jcs fs-c4" >{t1}</div>
                    <div className="dfc f5 jcs" ></div>
                    <div className="dfc f3 jcs fs-c4" ><BtnSvg ico={IcoBut} hand={() => callb()} /></div>

                    <div className="dfc f1" style={{ width: '2%' }}></div>
                </div >
                <hr className="cara1"></hr>
            </>
        )
    }
    const mezera = () => {
        return (
            <hr className="cara5"></hr>
        )
    }

    const sStridac = (val) => {
        if (val) {
            const value = { label: val }
            pr.fn.setstridac(value)
            setStridac(val)
        }
        setEdit(0)
    }
    const sAdr = (val) => {
        if (val) pr.fn.setip(val)
        setEdit(0)
    }
    const sPort = (val) => {
        if (val) pr.fn.setport(val)
        setEdit(0)
    }

    useEffect(() => {

        let cont = []

        if (!edit) {
            cont.push(nadpis('Stav komunikace'))
            cont.push(<GetPhoneUWstav resp={pr.resp} />)

            cont.push(nadpis('Nastavení'))
            if (pr?.resp?.online === 'true') {
                cont.push(radek('IP adresa Modbus komunikace se střídačem', pr.resp.ipadr, 2))
                cont.push(radek('Port Modbus komunikace se střídačem', pr.resp.port, 3))
                cont.push(radek('Typ střídače', stridac, 1))
                cont.push(radekBtn('Reset UNI:WATTU', pr.fn.resetUW))
                cont.push(mezera())
            }
            setContent(cont)
        }
        else {
            if (edit === 1) cont.push(<SelectStridac text={pr.stridac} callb={(val) => sStridac(val)} />)
            if (edit === 2) {
                cont.push(<TextBox text={pr.resp.ipadr} callb={(val) => sAdr(val)} />)
                cont.push(<ScanLan callb={() => {
                    pr.fn.scanlan()
                    setEdit(0)
                }} />)
            }
            if (edit === 3) cont.push(<TextBox text={pr.resp.port} callb={(val) => sPort(val)} />)
            setContent(cont)
        }

    }, [pr, edit]);

    return (
        <>
            {content}
        </>
    )
}

export const GetPhoneUWstav = (pr) => { //***************************************************************************************************

    const radek = (t1, t2, t3, t4, t5, col) => {
        return (
            <>
                <div className="df bg-15 w100" style={{ height: '50px' }}>
                    <div className="dfc f1" style={{ width: '2%' }}></div>

                    <div className="dfc f4 jcs fs-c4" >{t1}</div>
                    <div className="dfc f2 jcs fs-c4" >{t2}</div>
                    <div className="dfc f7 jcs fs-c4 fo-b" >{t3}</div>
                    <div className="dfc f2" ></div>
                    <div className="dfc f2 jcs fs-c4" >{t4}</div>
                    <div className="dfc f3 jce fs-c4 fo-b" style={{ color: col === 'onl' ? '#00D700' : col === 'offl' ? 'gray' : 'black' }}>{t5}</div>

                    <div className="dfc f2" style={{ width: '2%' }}></div>
                </div >
            </>
        )
    }

    let cont = []

    if (pr?.resp) {
        cont.push(radek(
            'Uni:watt',
            'ID',
            pr.resp?.devid,
            'Status',
            pr.resp?.online === 'true' ? "Online" : "Offline",
            pr.resp?.online === 'true' ? "onl" : "offl"
        ))
        cont.push(<hr className="cara1"></hr>)
        cont.push(radek(
            'Střídač',
            '',
            '',
            'Status',
            pr.resp?.connect === 'true' && pr.resp?.online === 'true' ? "Online" : "Offline",
            pr.resp?.connect === 'true' && pr.resp?.online === 'true' ? "onl" : "offl"
        ))
        return (
            <> {cont} </>
        )
    }
}


const SelectStridac = (pr) => { //*****************************************************************************

    const back = () => { pr.callb() }
    const save = (text) => { pr.callb(text) }

    const radek = (t1) => {
        return (
            <div className="dfc f1 jcs fs4 cl-m hov" style={{ minHeight: '6vh', marginLeft: '5vh' }}
                onClick={() => save(t1)}
            >{t1}</div>
        )
    }

    let cont = []
    cont.push(radek('Alpha ESS'))
    cont.push(radek('Goodwe'))
    cont.push(radek('Solax'))

    return (
        <>
            <div className="dfc bg-15" style={{ minHeight: '5vh' }}>Vyberte typ střídače</div>
            <div className="dfc bg-15" style={{ minHeight: '1vh' }}></div>
            <div className="dfc fdir-c ai-s bg-15" style={{ minHeight: '1vh' }}>
                {cont}
            </div>
            <div className="dfc bg-15" style={{ minHeight: '10vh' }}>
                <div className="dfc f1 hov cl-m" onClick={() => { back() }}>Zpět</div>
                <div className="dfc f2"></div>
            </div>
        </>
    )
}


const ScanLan = (pr) => { //*****************************************************************************

    return (
        <>
            <div className="dfc bg-15" style={{ minHeight: '10vh' }}></div>
            <div className="dfc bg-15" style={{ minHeight: '5vh' }}>nebo zvolte</div>
            <div className="dfc bg-15 fo-b" style={{ minHeight: '5vh' }}>Automatické skenování sítě</div>
            <div className="dfc bg-15" style={{ minHeight: '2vh' }}></div>
            <div className="dfc ta-c bg-15" style={{ minHeight: '5vh', textAlign: 'center' }}>

                UNI:WATT automaticky proskenuje zařízení v síti a vyhledá střídač.
                <br></br>
                Po dobu skenování přejde UNI:WATT asi na 2 minuty do Offline režimu.
            </div>

            <div className="dfc bg-15" style={{ minHeight: '20vh' }}>
                <div className="dfc f1 hov cl-m" onClick={() => { pr.callb() }}>Spustit skenování</div>
            </div>
        </>
    )
}


const BtnSvg = (pr) => {

    const [press, setPress] = useState(false)

    const cpress = () => {
        if (!press) { pr.hand() }
        setPress(true)
    }
    const crelease = () => {
        setPress(false)
    }

    return (
        <div className="dfc" style={{ height: '2.8vh', marginLeft: '-3px' }}>

            <div style={{
                position: "relative",
                width: "100%",
                height: "80%",
                border: "none",
                backgroundColor: "transparent",
            }}
                // onClick={() => cpress()}
                onMouseDown={() => cpress()}
                onTouchStart={() => cpress()}

                onMouseUp={() => crelease()}
                onMouseOut={() => crelease()}
                onTouchEnd={() => crelease()}
            >
                <pr.ico
                    className='hov'
                    fill={pr.fill ? pr.fill : ''}
                    style={{
                        position: 'absolute',


                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",

                        height: '150%',
                        width: 'auto',
                        userSelect: 'none',
                        filter: press ?
                            'saturate(100%) invert(58%) sepia(99%) saturate(729%) hue-rotate(359deg) brightness(99%) contrast(101%)'
                            :
                            pr.fill ? '' : 'brightness(0) saturate(100%) invert(32%) sepia(80%) saturate(1206%) hue-rotate(178deg) brightness(99%) contrast(108%)'
                    }}
                />
            </div>
        </div>
    )
}


const TextBox = (pr) => {

    const [newtext, setNewtext] = useState(pr.text)
    const [maxlength, setMaxlength] = useState(15)

    const back = () => {
        pr.callb()
    }

    const save = (text) => {
        setNewtext(text)
        pr.callb(text)
    }

    const handleChangeEvents = (e) => {
        // if (isnum === '0' || (isnum === '1' && !isNaN(e.target.value))) {
        if (e.target.value.length > e.maxLength) { e.value = e.target.value; } else { setNewtext(e.target.value) }
        // }
    }

    return (
        <>
            <div className="dfc bg-15" style={{ minHeight: '5vh' }}>Zadejte hodnotu</div>
            <div className="dfc bg-15" style={{ minHeight: '5vh' }}></div>
            <div className="df bg-15" style={{ minHeight: '12vh' }}>
                <div className="dfc f1" style={{ width: '2%' }}></div>

                <div id="formCont">
                    <input id="in1"
                        className="fs5"
                        style={{
                            userSelect: 'none',
                            outline: 'none',
                            color: 'rgb(0, 123, 210)',
                            width: '100%',
                            border: 'none',
                            backgroundColor: 'transparent',
                            textAlign: 'center',
                        }}
                        maxlength={maxlength}

                        value={newtext} type="text" name="login"

                        onClick={() => {

                        }}
                        onBlur={() => {
                            // pr.callb(newtext)
                        }}
                        onChange={e => handleChangeEvents(e)}
                    />

                </div>

                <div className="dfc f1" style={{ width: '2%' }}></div>
            </div >
            <div className="dfc bg-15" style={{ minHeight: '10vh' }}>
                <div className="dfc f1 hov cl-m" onClick={() => { back() }}>Zpět</div>
                <div className="dfc f1"></div>
                <div className="dfc f1 hov cl-m" onClick={() => { save(newtext) }}>Potvrdit</div>
            </div>
        </>
    )
}
