import React, { useState, useEffect } from "react";
import AppService from "../services/app.service";
import { useLocation } from "react-router-dom";
import EventBJS from "../common/EventBusJS.js";
import Trace from "./trace.js";

import gl from "../global_var.js";

import Menu from "./menu.js";
import PageVyroba from "./page-vyroba.js";
import PageInv from "./page-inv.js";

import { ReactComponent as Itest } from '../images/eye-svgrepo-com.svg';

import ReactLoading from 'react-loading';

import "./Page.css";


const PageMain = (user) => {

    const location = useLocation()

    // const fsmenu = { fontSize: '0.8em' }
    const [content, setContent] = useState("");

    const [phone, setPhone] = useState(window.innerWidth < 600 ? true : false)

    const handleResize = () => {
        if (window.innerWidth < 600) setPhone(true)
        else setPhone(false)
    }

    React.useEffect(() => {
        window.addEventListener("resize", handleResize, false);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    React.useEffect(() => {

        if (location.site != localStorage.getItem("actapp") || Object.keys(gl.app).length == 0) {

            if (location.site !== undefined) localStorage.setItem("actapp", location.site); //do cookies

            AppService.getAppStructure(localStorage.getItem("actapp")).then( //z cookies

                (response) => {

                    const arAppStruct = response.data.app

                    gl.appStruct = [...arAppStruct]
                    Trace.switchApp(0)
                })
        }
        return () => {
            localStorage.removeItem("actapp");
        };
    }, []);

    //********************************************************************************

    const sestavPage = () => {
        let buildc = []

        if (gl.app.type === 'fve-vyroba') buildc.push(<PageVyroba key={1}/>)
        if (gl.app.type === 'menu') {
            buildc.push(<PageInv key={2}/>)
            buildc.push(<Menu arr={gl.app.items} key={3}/>)
        }
        setContent(buildc)
    }

    //********************************************************************************


    useEffect(() => {

        EventBJS.on("refMain", () => { sestavPage() });
        sestavPage();
        return () => {
            EventBJS.remove("refMain");
        };
    }, []);


    return (

        <div
            className="dfc bg-0"
            style={{
                position: 'fixed',
                top: '0',
                width: '100vw',
                height: '100vh',
                // overflowY: 'auto'
            }}>

            <div className="newfont df ai-c fdir-c h100 w100 bg-16 pos-r" style={{ maxWidth: '80vh' }}>
                {content}
            </div>

        </div>
    );
};

export default PageMain;


