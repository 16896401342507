import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import UserService from "../services/user.service";
import global from "../global_var.js";
import EventBus from "../common/EventBus";

import "./Board.css";

import { ReactComponent as IcoGraf } from '../images/chart-bar.svg';
import { ReactComponent as IcoList } from '../images/list.svg';

const logo = '../images/UWlogo.png'

const serveradr = require('../services/IPadr.js').serveradr;


const BoardRegion = (prop) => {

    const [content, setContent] = useState("");
    const [contentNad, setContentNad] = useState("");

    const [resp, setResp] = useState("");
    const [region, setSelRegion] = useState(global.region);
    const [B2, setB2] = useState(global.b2);
    const [selectBlok, setSelectBlok] = useState(false);



    const [phone, setPhone] = useState(window.innerWidth < 680 ? true : false)
    const handleResize = () => {
        if (window.innerWidth < 680) setPhone(true)
        else setPhone(false)
    }
    React.useEffect(() => {
        window.addEventListener("resize", handleResize, false);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const clickSite = async (devid) => {

        const makeid = (length) => {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        }

        const ikey = makeid(50)
        // console.log(ikey)

        UserService.setDeviceKey(devid, ikey).then(
            (response) => {
                let path
                // console.log(devid)
                if (devid == '57D88') path = 'http://server.ipmdevelop.cz:3003/57D88' //Pomoranska
                else path = 'http://89.221.217.59/device/' + ikey
                // console.log(path)
                window.open(path, "_parent");
            })
    }

    const clickB2 = (num) => {
        global.b2 = num
        setB2(num)
    }

    const setRegion = (val) => {
        setSelectBlok(false)
        setSelRegion(val);
        global.region = val;
    }
    const selectOnClick = () => {
        setSelectBlok(true)
    }
    const selectOnBlur = () => {
        setTimeout(() => {
            setSelectBlok(false)
        }, 150);

    }

    useEffect(() => {
        setTimeout(() => {
            UserService.getListRegion().then(
                (response) => {
                    setResp(response.data)
                    // console.log(response.data)
                })
        }, 100);

        const ti = setInterval(() => {
            UserService.getListRegion().then(
                (response) => {
                    setResp(response.data)
                })
        }, 5000);

        return () => {
            clearInterval(ti)
        };
    }, []);


    const st4 = {
        height: '100%',
        fontSize: 'clamp(0.5em, 1.3vw, 1em)'
    }

    let cont = []

    useEffect(() => {

        if (!selectBlok) {

            let actUsername = ''
            if (prop.user !== undefined) { actUsername = prop.user.username }

            const st5 = { height: '3.6vh', fontSize: 'clamp(0.5em, 1.3vw, 1em)' }
            const st6 = { height: '3.6vh' }
            const st7 = { width: '18px' }

            setContentNad(

                <div className="w100" style={{ background: '#E9ECEF', zIndex: '1000' }}>

                    <div className="radekclearRegion bg-14">
                        <div className="dfc fo-b f18 jcs bg-14" style={{ minWidth: '200px', height: '100%', flexWrap: 'wrap' }}>

                            <div className="dfc jc-s f1 bg-14" style={{ height: '50%', marginRight: "40px" }}>
                                <img className="" src={require("../images/UWlogo.png")} alt="Logo" height="60%" />
                            </div>
                            <div className="dfc jc-s f10 bg-14" style={{ height: '50%' }}>
                                <img className="" src={require("../images/UWcloud.png")} alt="Logo" height="60%" />
                            </div>


                            {/* <img className="f1x" src={require("../images/UWlogo.png")} alt="Logo" height="100%" />
                                <img className="f1x" src={require("../images/UWcloud.png")} alt="Logo" height="100%" /> */}
                        </div>

                        <div className="df f4 h100" style={{ fontSize: 'clamp(0.7em, 1.3vw, 0.9em)', maxWidth: '80px' }}>
                            <Radiobut txt='Vše' act={B2} pos={0} callb={() => clickB2(0)} />
                        </div>
                        <div className="df f1"> </div>
                        <div className="df f4 h100" style={{ fontSize: 'clamp(0.7em, 1.3vw, 0.9em)', maxWidth: '50px' }}>
                            <Radiobut txt='B2B' act={B2} pos={1} callb={() => clickB2(1)} />
                        </div>
                        <div className="df f1"> </div>

                        <div className="df f4 h100" style={{ fontSize: 'clamp(0.7em, 1.3vw, 0.9em)', maxWidth: '50px' }}>
                            <Radiobut txt='B2C' act={B2} pos={2} callb={() => clickB2(2)} />
                        </div>


                        <div className="dfc f10 jce" style={{ fontSize: 'clamp(0.7em, 1.3vw, 0.9em)', maxWidth: '170px' }}>{'Výběr regionu: '}&nbsp;&nbsp;</div>
                        <div className="dfc fo-b f12 jcc bg-14" style={{ maxWidth: '300px' }}>
                            <SelectRegion onChange={setRegion} onClick={selectOnClick} onBlur={selectOnBlur} />
                        </div>
                        <div className="dfc bg-4 f" style={{ flex: '0.1' }}></div>
                    </div>

                    <div style={{
                        background: '#E9ECEF', zIndex: '1000',
                        borderBottom: '1px solid rgb(140, 140, 140)'
                    }}>
                        <div className="radek-noline" style={{ minHeight: phone ? 'auto' : '3.6vh' }} >
                            <div className="dfc fo-b f20 fs-c3 jcs" >{'Umístění'}</div>
                            <div className="df f6"> </div>
                            <div className="dfc fo-b w12 fs-c3 bg-d" style={st6}>{'FVE'}</div>
                            <div className="dfc fo-b w12 fs-c3" style={st6}>{'Baterie'}</div>
                            <div className="dfc fo-b w12 fs-c3 bg-d" style={st6}>{'EV'}</div>
                            <div className="dfc fo-b w12 fs-c3" style={st6}>{'TČ'}</div>
                            <div className="dfc fo-b w12 fs-c3 bg-d" style={st6}>{'Voda'}</div>
                            {/* <div className="dfc fo-b fs-c3 bg-d" style={st7}>{''}</div> */}
                        </div>
                        <div className="radek-noline" style={{ minHeight: phone ? 'auto' : '3.6vh' }} >
                            <div className="dfc f20 jcs fo-b " >{ } </div>
                            <div className="df f6"> </div>
                            <div className="dfc w6 tx-ac fs-c1 bg-d" style={st6}>{'aktuální výkon'}</div>
                            <div className="dfc w6 tx-ac fs-c1 bg-d" style={st6}>{'disponibilní výkon'}</div>
                            <div className="dfc w6 tx-ac fs-c1" style={st6} >{'dostupná kapacita'}</div>
                            <div className="dfc w6 tx-ac fs-c1" style={st6} >{'celková kapacita'}</div>
                            <div className="dfc w6 tx-ac fs-c1 bg-d" style={st6}>{'dostupná kapacita'}</div>
                            <div className="dfc w6 tx-ac fs-c1 bg-d" style={st6}>{'celková kapacita'}</div>
                            <div className="dfc w6 tx-ac fs-c1" style={st6} >{'dostupná kapacita'}</div>
                            <div className="dfc w6 tx-ac fs-c1" style={st6} >{'celková kapacita'}</div>
                            <div className="dfc w6 tx-ac fs-c1 bg-d" style={st6}>{'objem'}</div>
                            <div className="dfc w6 tx-ac fs-c1 bg-d" style={st6}>{'dostupná kapacita'}</div>
                            {/* <div className="dfc w6 tx-ac fs-c1 bg-d" style={st6}>{'celková kapacita'}</div> */}
                            {/* <div className="dfc fo-b fs-c3 bg-d" style={st7}>{''}</div> */}
                        </div>
                    </div>
                </div>
            )

            let sum = {
                fve_c: 0,
                ba_c: 0,
                ev_c: 0,
                tc_c: 0,
                vo_o: 0,
                vo_c: 0,
                fve_d: 0,
                ba_d: 0,
                ev_d: 0,
                tc_d: 0,
                vo_d: 0
            }
            if ((region === '1' || region === '0') && (B2 === 1 || B2 === 0)) {//pomoranska v Praze
                sum.fve_d = 21200
                sum.fve_c = 49000
                sum.vo_o = 7000
            }
            for (let i = 0; i < resp.length; i++) {//hodnoty celkem
                if ((resp[i].reg.toString() === region || region === '0') && (resp[i].boc === B2 || B2 === 0)) {
                    if (resp[i]?.fve_c) sum.fve_c = sum.fve_c + resp[i]?.fve_c
                    if (resp[i]?.ba_c) sum.ba_c = sum.ba_c + resp[i]?.ba_c
                    if (resp[i]?.ev_c) sum.ev_c = sum.ev_c + resp[i]?.ev_c
                    if (resp[i]?.tc_c) sum.tc_c = sum.tc_c + resp[i]?.tc_c
                    if (resp[i]?.vo_o) sum.vo_o = sum.vo_o + resp[i]?.vo_o
                    if (resp[i]?.vo_c) sum.vo_c = sum.vo_c + resp[i]?.vo_c
                    if (resp[i]?.fve_d) sum.fve_d = sum.fve_d + resp[i]?.fve_d
                    if (resp[i]?.ba_d) sum.ba_d = sum.ba_d + resp[i]?.ba_d
                    if (resp[i]?.ev_d) sum.ev_d = sum.ev_d + resp[i]?.ev_ds
                    if (resp[i]?.tc_d) sum.tc_d = sum.tc_d + resp[i]?.tc_d
                    // if (resp[i]?.vo_d) sum.vo_d = sum.vo_d + resp[i]?.vo_d
                }
            }
            cont.push(
                <div className="radek bg-12" key={9999}>
                    <div className="df ai-c fo-b f26 jce cl-3 bg-12" style={st4}>{'Celkem:'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    {/* <div className="dfc f6 jcs" style={{ color: 'transparent' }}>o</div> */}
                    <div className="dfc w5 tx-ac bg-12" style={st4}>{''}</div>
                    <div className="dfc w6 fo-b cl-3 tx-ac cl-4 bg-11" style={st5}>{sum.fve_d ? (sum.fve_d / 1000).toFixed(2) : ''}</div>
                    <div className="dfc w6 fo-b cl-3 tx-ac cl-4 bg-11" style={st5}>{sum?.fve_c ? (sum.fve_c / 1000).toFixed(2) : ''}</div>
                    <div className="dfc w6 fo-b cl-3 tx-ac cl-4 bg-12" style={st5}>{sum?.ba_c ? ((sum?.ba_c / 1000000 * sum.ba_d)).toFixed(2) : ''}</div>
                    <div className="dfc w6 fo-b cl-3 tx-ac cl-4 bg-12" style={st5}>{sum?.ba_c ? sum.ba_c / 1000 : ''}</div>
                    <div className="dfc w6 fo-b cl-3 tx-ac cl-4 bg-11" style={st5}>{sum?.ev_d ? sum.ev_d / 1000 : ''}</div>
                    <div className="dfc w6 fo-b cl-3 tx-ac cl-4 bg-11" style={st5}>{sum?.ev_c ? sum.ev_c / 1000 : ''}</div>
                    <div className="dfc w6 fo-b cl-3 tx-ac cl-4 bg-12" style={st5}>{sum?.tc_d ? sum.tc_d / 1000 : ''}</div>
                    <div className="dfc w6 fo-b cl-3 tx-ac cl-4 bg-12" style={st5}>{sum?.tc_c ? sum.tc_c / 1000 : ''}</div>
                    <div className="dfc w6 fo-b cl-3 tx-ac cl-4 bg-11" style={st5}>{sum?.vo_o ? sum.vo_o : ''}</div>
                    <div className="dfc w6 fo-b cl-3 tx-ac cl-4 bg-11" style={st5}>{sum?.vo_d ? sum.vo_d : ''}</div>
                    {/* <div className="dfc w6 fo-b cl-3 tx-ac cl-4 bg-11" style={st5}>{sum?.vo_c ? sum.vo_c : ''}</div> */}
                </div >
            )




            for (let i = 0; i < resp.length; i++) {

                const devid = resp[i]?.id
                const site = resp[i]?.site

                if ((resp[i].reg.toString() === region || region === '0') && (resp[i].boc === B2 || B2 === 0)) {

                    cont.push(
                        <div className="radek" key={i}>

                            <div className="df f1">
                                <div className="df" style={{ width: '0.52vh', height: '0.5vh', background: resp[i]?.act ? '#00A600' : 'red' }}></div >
                            </div >

                            {serveradr == 'https://uniwatt.cloud' ?

                                <div className="df f19 ai-c hov"
                                    style={{ height: '100%', fontSize: 'clamp(0.7em, 1.4vw, 1em)', paddingLeft: '5px' }}>
                                    <a onClick={() => { clickSite(devid) }}>
                                        {resp[i] ? site : ' '}
                                    </a>
                                </div >
                                :
                                <Link className="df f19 ai-c"
                                    style={{ height: '100%', fontSize: 'clamp(0.7em, 1.4vw, 1em)' }}
                                    to={{ pathname: '/device_content', device: { dbid: devid } }}>
                                    {resp[i] ? site : ' '}
                                </Link>
                            }
                            {
                                resp[i]?.app === '2' ?
                                    <Link className="dfc f6 jcc" style={{ color: 'transparent' }} to={{ pathname: '/device_charts', state: { mac: resp[i].id, devname: resp[i].site } }}  >
                                        <IcoGraf style={{ width: '30%', maxWidth: '25px' }} />
                                    </Link>
                                    :
                                    resp[i]?.rtu !== null ?
                                        (
                                            <>
                                                {!site.includes("CIUR 4x60") ?
                                                    <Link className="dfc f6 jcc" style={{ color: 'transparent' }} to={{ pathname: '/rtu_data', id: resp[i].rtu }}  >
                                                        <IcoList style={{ width: '30%', maxWidth: '20px' }} />
                                                    </Link>
                                                    :
                                                    <a className="dfc f6 jcc" style={{}} href='https://rtu.cyberheroes.cz/d/adufth2ef7if4e/fve-prehled?orgId=1&kiosk' >
                                                        <IcoList style={{ width: '30%', maxWidth: '20px' }} />
                                                    </a>
                                                }
                                            </>
                                        )

                                        :
                                        <div className="dfc f6 jcs" style={{ color: 'transparent' }}>
                                            o
                                        </div>
                            }
                            <div className="dfc w5 tx-ac bg-12" style={st4}>{resp[i]?.inv ? resp[i]?.inv : ''}</div>
                            <div className="dfc w6 tx-ac bg-13" style={st4}>{resp[i]?.fve_d ? (resp[i].fve_d / 1000).toFixed(2) : ''}</div>
                            <div className="dfc w6 tx-ac bg-13" style={st4}>{resp[i]?.fve_c ? resp[i].fve_c / 1000 : ''}</div>
                            <div className="dfc w6 tx-ac bg-14" style={st4}>{resp[i]?.ba_c ? ((resp[i]?.ba_c / 1000000 * resp[i].ba_d)).toFixed(2) : ''}</div>
                            <div className="dfc w6 tx-ac bg-14" style={st4}>{resp[i]?.ba_c ? resp[i].ba_c / 1000 : ''}</div>
                            <div className="dfc w6 tx-ac bg-13" style={st4}>{resp[i]?.ev_d ? resp[i].ev_d / 1000 : ''}</div>
                            <div className="dfc w6 tx-ac bg-13" style={st4}>{resp[i]?.ev_c ? resp[i].ev_c / 1000 : ''}</div>
                            <div className="dfc w6 tx-ac bg-14" style={st4}>{resp[i]?.tc_d ? resp[i].tc_d / 1000 : ''}</div>
                            <div className="dfc w6 tx-ac bg-14" style={st4}>{resp[i]?.tc_c ? resp[i].tc_c / 1000 : ''}</div>
                            <div className="dfc w6 tx-ac bg-13" style={st4}>{resp[i]?.vo_o ? resp[i].vo_o : ''}</div>
                            <div className="dfc w6 tx-ac bg-13" style={st4}>{resp[i]?.vo_d ? resp[i].vo_d : ''}</div>
                            {/* <div className="dfc w6 tx-ac bg-13" style={st4}>{resp[i]?.vo_c ? resp[i].vo_c : ''}</div> */}
                        </div >
                    )
                }
            }

            
            // *****************************************************************************************************************************************************
            // POMORANSKA ******************************************************************************************************************************************
            if ((region === '0' || region === '1') && (B2 === 0 || B2 === 1)) {
                cont.push(
                    <div className="radek" key={22222}>
                        <div className="df f1">
                            <div className="df" style={{ width: '0.52vh', height: '0.5vh', background: '#00A600' }}></div >
                        </div >

                        <a className="dfc f26 jcs" style={{ height: '100%', fontSize: 'clamp(0.7em, 1.4vw, 1em)', paddingLeft: '5px' }}
                            href='http://server.ipmdevelop.cz:3003/57D88/' >
                            BD - Pomořanská, Praha 8
                        </a>
                        <div className="dfc w5 tx-ac bg-12" style={st4}>{'FR'}</div>
                        <div className="dfc w6 tx-ac bg-13" style={st4}>{'21.2'}</div>
                        <div className="dfc w6 tx-ac bg-13" style={st4}>{'49.00'}</div>
                        <div className="dfc w6 tx-ac bg-14" style={st4}>{''}</div>
                        <div className="dfc w6 tx-ac bg-14" style={st4}>{''}</div>
                        <div className="dfc w6 tx-ac bg-13" style={st4}>{''}</div>
                        <div className="dfc w6 tx-ac bg-13" style={st4}>{''}</div>
                        <div className="dfc w6 tx-ac bg-14" style={st4}>{''}</div>
                        <div className="dfc w6 tx-ac bg-14" style={st4}>{''}</div>
                        <div className="dfc w6 tx-ac bg-13" style={st4}>{'7000'}</div>
                        <div className="dfc w6 tx-ac bg-13" style={st4}>{''}</div>
                        {/* <div className="dfc w6 tx-ac bg-13" style={st4}>{''}</div> */}
                        {/* <div className="dfc f6 jcs" style={{ color: 'transparent' }}>o</div> */}
                    </div >
                )
            }
            // POMORANSKA ******************************************************************************************************************************************
            // *****************************************************************************************************************************************************


            setContent(cont);

            let elid = document.getElementById("selKraj");
            if (elid) { elid.blur(); }

        }
    }, [resp, region, B2]);


    return (
        <div className="df fdir-c"
            style={{
                width: '100vw',
                height: '94vh',
            }}>

            {contentNad}

            {/* <div className="df"
                style={{
                    width: '100vw',
                    height: '94vh',
                    overflowY: 'auto',
                }}> */}
            <div className="df" style={{
                width: '100%',
                flexDirection: 'column',
                background: '#E9ECEF',
                overflowY: 'auto',
            }}>
                {content}
            </div>
            {/* </div> */}

        </div>
    );
};

export default BoardRegion;



const SelectRegion = (pr) => {

    const [selectValue, setSelectValue] = React.useState('Vše');

    const onChange = (event) => {
        const value = event.target.value;
        // setSelectValue(value);
        pr.onChange(value)
    };

    return (

        <select id="selKraj"
            onClick={() => { pr.onClick() }}
            onBlur={() => { pr.onBlur() }}
            onChange={onChange}
            value={global.region}
            className="hov"
            style={{
                border: '1px solid #ccc',
                width: '100%',
                height: '2.8vh',
                fontSize: 'clamp(0.7em, 1.0vw, 1.1em)'
            }}
        >
            <option value={0}>Vše</option>
            <option value={1}>Hlavní město Praha</option>
            <option value={2}>Jihočeský kraj</option>
            <option value={3}>Jihomoravský kraj</option>
            <option value={4}>Karlovarský kraj</option>
            <option value={5}>Královéhradecký kraj</option>
            <option value={6}>Kraj Vysočina</option>
            <option value={7}>Liberecký kraj</option>
            <option value={8}>Moravskoslezský kraj</option>
            <option value={9}>Olomoucký kraj</option>
            <option value={10}>Pardubický kraj</option>
            <option value={11}>Plzeňský kraj</option>
            <option value={12}>Středočeský kraj</option>
            <option value={13}>Ústecký kraj</option>
            <option value={14}>Zlínský kraj</option>
        </select>
    );
};


const Radiobut = (pr) => {
    return (
        <div className="df">
            <div className="df jce aic f3">
                {pr.txt}&nbsp;&nbsp;
            </div>
            <div className="df f1 hov" style={{ position: 'relative', minWidth: '20px' }} onClick={() => pr.callb(pr.pos)}>
                <svg width="100%" height="100%">
                    <circle cx="50%" cy="50%" r="0.7vh" stroke="#999" strokeWidth="1" fill='#fff'
                    />
                </svg>
                <svg width="100%" height="100%" style={{ position: 'absolute', margin: '0 auto' }} >
                    <circle cx="50%" cy="50%" r="0.5vh" stroke="#444" strokeWidth="0"
                        fill={pr.act == pr.pos ? '#777' : '#fff'}
                    />
                </svg>
            </div>
        </div>
    )
}
