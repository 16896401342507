import React, { useState, useEffect, forwardRef } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import cs from 'date-fns/locale/cs';

import "./Board.css";

registerLocale('cs', cs)



const Datum = (props) => {

    const [startDate, setStartDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());


    useEffect(() => {
        let d = new Date()
        d.setFullYear(props.setdate.y)
        d.setMonth(props.setdate.m)
        d.setDate(props.setdate.d)
        setSelectedDate(d)
    }, [props.setdate]);

    const datumMinus = () => {
        let d = new Date(selectedDate)
        d.setDate(selectedDate.getDate() - 1)
        sOnChange(d)
    }
    const datumPlus = () => {
        let d = new Date(selectedDate)
        d.setDate(selectedDate.getDate() + 1)
        sOnChange(d)
    }

    const sOnChange = (date) => {
        setSelectedDate(date)
        props.callb(date.getFullYear(), date.getMonth() + 1, date.getDate())
    }

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className="custom-input" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));


    return (

        <div style={{
            display: 'flex', width: '100%', minWidth: '150px', maxWidth: '250px', height: '100%', alignItems: 'center', border: '1px solid #CCCCCC'
        }}>

            <div style={{
                display: 'flex', width: '100%', height: '80%',
            }}>

                <div className='sipka' onClick={() => { datumMinus() }} > &lt; </div>

                <div style={{
                    flex: '3', display: 'flex', 
                    justifyContent:'center',alignItems:'center',
                    maxHeight: '30px',
                }}>
                    <DatePicker
                        selected={selectedDate}
                        onChange={(date) => sOnChange(date)}
                        locale="cs"
                        dateFormat="dd-MM-yyyy"
                        customInput={<CustomInput />}
                    />

                </div>
                <div className='sipka' onClick={() => { datumPlus() }}> &gt; </div>

            </div>
        </div>

    )
};

export default Datum


