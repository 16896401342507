import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";

import gl from "../global_var.js";

import { ReactComponent as IcoCon } from '../images/sign-out-alt.svg';
import { ReactComponent as IcoGraf } from '../images/chart-line.svg';

const serveradr = require('../services/IPadr.js').serveradr;

const BoardAdmin = (user) => {

    // const fsmenu = { fontSize: '0.8em' }
    const [content, setContent] = useState("");

    const [phone, setPhone] = useState(window.innerWidth < 600 ? true : false)

    const handleResize = () => {
        if (window.innerWidth < 600) setPhone(true)
        else setPhone(false)
    }

    React.useEffect(() => {
        window.addEventListener("resize", handleResize, false);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const clickSite = async (devid, typ) => {

        if (typ === 'app') {


        }
        else {

            const makeid = (length) => {
                let result = '';
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                const charactersLength = characters.length;
                let counter = 0;
                while (counter < length) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                    counter += 1;
                }
                return result;
            }

            const ikey = makeid(50)

            UserService.setDeviceKey(devid, ikey).then(
                (response) => {
                    const path = 'http://89.221.217.59/device/' + ikey
                    // console.log(path)
                    window.open(path, "_parent");
                })
        }
    }


    useEffect(() => {
        UserService.getAdminBoard_all().then(
            (response) => {

                let cont = []
                let actUsername = ''
                if (user.user !== undefined) { actUsername = user.user.username }

                const fs = phone ? { fontSize: '0.8em' } : { fontSize: '1.0em' }

                for (let i = 0; i < response.data.length; i++) {

                    let dbid = response.data[i]?.id
                    let typ = response.data[i]?.typ

                    if (!(response.data[i].typ === '2' && actUsername === 'martin.petrasovsky')) {

                        cont.push(
                            <div className="radek" key={i}>

                                {serveradr == 'https://uniwatt.cloud' ?

                                    (typ === 'app' ?

                                        <Link className="f8" style={fs} to={{ pathname: '/app_structure', site: response.data[i].site }}  >
                                            {response.data[i].site}
                                        </Link>
                                        :
                                        <div className="f8 hov" style={fs} >
                                            <a onClick={() => { clickSite(dbid, typ) }}>
                                                {response.data[i].site}
                                            </a>
                                        </div >
                                    )
                                    :
                                    (
                                        <Link className="f8" style={fs} to={{ pathname: '/device_content', device: { dbid } }}>
                                            {response.data[i].site}
                                        </Link>
                                    )
                                }

                                <div className="f1"></div>

                                <div style={{ flex: '12', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                    <div className="f4" style={fs}>{response.data[i].id}&nbsp;&nbsp;</div>
                                    <div className="f4" style={fs}>{response.data[i].ipv}</div>
                                    <div className="f4" style={fs}>{response.data[i].ipe}</div>
                                </div>
                                <div className="f1"></div>
                                <div className="f5" style={fs}>{response.data[i].time}</div>

                                <div className="f1" style={fs}></div>

                                {/* <div className="f3">
                                    <div className="btncon" >
                                        <Link to={{ pathname: '/device_content', device: { dbid } }}>
                                            <IcoCon className="icocon" />
                                        </Link>
                                    </div> 
                                </div> */}
                            </div>
                        )
                    }
                }
                setContent(cont);

            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setContent(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        );
    }, []);

    return (

        <div style={{
            maxWidth: '100vw',
            height: '94vh',
            background: '#E9ECEF',
            overflowY: 'auto'
        }}>
            {content}
        </div>
    );
};

export default BoardAdmin;
