import React, { useState, useEffect } from "react";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";

import Login from "./components/Login";
import Register from "./components/Register";

import BoardUser from "./components/BoardUser";
import BoardAdminAll from "./components/BoardAdmin_all";
import BoardAdmin_uw from "./components/BoardAdmin_uw";
import BoardAdmin_ug from "./components/BoardAdmin_ug";
import BoardAdminModerators from "./components/BoardAdmin_moderators";
import BoardAdminUsers from "./components/BoardAdmin_users";
import BoardDeviceContent from "./components/BoardDeviceContent";
import BoardDeviceCharts from "./components/BoardDeviceCharts";
import Charts from "./components/ChartsPower";
import ChartsStat from "./components/ChartsStatistic";

import BoardDevicesOverview from "./components/BoardDevicesOverview.js";
import BoardRegion from "./components/BoardRegion";
import BoardUWmikro from "./components/BoardUWmikro";
import BoardUWmikroDetail from "./components/BoardUWmikroDetail";

import PageMain from "./devapp/page-main.js";
import RTUdata from "./components/RTUdata.js";

// import AuthVerify from "./common/AuthVerify";
import EventBus from "./common/EventBus";
import "./components/Board.css";


const App = () => {

    const [currentUser, setCurrentUser] = useState(undefined);
    const [currentUserRole, setCurrentUserRole] = useState('');
    const [currentDevice, setCurrentDevice] = useState('');


    useEffect(() => {
        const user = AuthService.getCurrentUser();

        if (user) {
            setCurrentUserRole(user.roles[0])
            setCurrentUser(user);
        }

        EventBus.on("logout", () => {
            logOut();
        });

        return () => {
            EventBus.remove("logout");
        };
    }, []);


    const logOut = () => {
        AuthService.logout();
        setCurrentUser(undefined);
        setCurrentUserRole({});
    };

    const [phone, setPhone] = useState(window.innerWidth < 660 ? true : false)

    const handleResize = () => {
        if (window.innerWidth < 660) setPhone(true)
        else setPhone(false)
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const fcCurrentDevice = (dev) => {
        setCurrentDevice(dev);
    };

    const fsmenu = { fontSize: '0.8em' }


    return (

        <div>

            <div className="df fdir-c bg-3" style={{ height: '6vh' }}> {/* height: '70px' */}


                {currentUser && currentUser?.roles[0].substring(5) !== 'USER' ? (

                    <div className="df jc-e bg-3"
                        style={{ height: '30%', fontSize: '0.7em', color: '#BBBBBB', marginRight: "2vw" }}>

                        <span style={{ color: "#777777" }}>USER:</span> &nbsp;{currentUser?.username}&nbsp;&nbsp;

                        {currentUser?.username !== 'martin.petrasovsky' && currentUser?.username !== 'tester' ?
                            <>
                                <span style={{ color: "#777777" }}>ROLE:</span> {
                                    currentUser?.roles[0].substring(5) === 'ADMIN' ? <>&nbsp;ADMIN</>
                                        :
                                        currentUser?.roles[0].substring(5) === 'MODERATOR' ? <>&nbsp;INSIGHT GROUP</>
                                            :
                                            <></>
                                }
                            </>
                            : <></>
                        }
                    </div>
                ) : (

                    <div className="df jc-e bg-3"
                        style={{ height: '30%', fontSize: '0.7em', color: '#BBBBBB', marginRight: "2vw" }}>

                        <span style={{ color: "#777777" }}></span> &nbsp;&nbsp;&nbsp;
                        <>
                            <span style={{ color: "#777777" }}></span> {
                                currentUser?.roles[0].substring(5) === 'ADMIN' ? <>&nbsp;</>
                                    :
                                    currentUser?.roles[0].substring(5) === 'MODERATOR' ? <>&nbsp;</>
                                        :
                                        <></>
                            }
                        </>

                    </div>
                )}




                <div className="df bg-3" style={{ height: '70%', paddingBottom: '5px' }}>

                    <div className="df ai-e f20 bg-3" >


                        {currentUserRole === "ROLE_ADMIN" ?
                            <>
                                <div className="" style={{ marginRight: "2vw" }}></div>

                                <div className="" style={{ marginRight: "2vw" }}>
                                    <Link to={"/admin_all"} className="cl-10 hov-c" style={phone ? fsmenu : {}}>
                                        Admin
                                    </Link>
                                </div>

                                {currentUser?.username !== 'martin.petrasovsky' && currentUser?.username !== 'tester' ?
                                    <>
                                        <div className="" style={{ marginRight: "2vw" }}>
                                            <Link to={"/admin_users"} className="cl-10 hov-c" style={phone ? fsmenu : {}}>
                                                {phone ? 'Uživatelé' : "Uživatelé"}
                                            </Link>
                                        </div>
                                        {/* <div className="" style={{ marginRight: "2vw" }}>
                                            <Link to={"/admin_moderators"} className="cl-10 hov-c" style={phone ? fsmenu : {}}>
                                                {phone ? 'Oprávnění' : 'Oprávnění'}
                                            </Link>
                                        </div> */}


                                        {currentUser?.username === 'petr' || currentUser?.username === 'karel.knetl' ?
                                            <>

                                                {/* <div className="" style={{ marginRight: "2vw" }}>
                                                    <Link to={"/devices_overview"} className="cl-10 hov-c" style={phone ? fsmenu : {}}>
                                                        {phone ? 'Přehled' : 'Přehled zařízení'}
                                                    </Link>
                                                </div>

                                                <div className="" style={{ marginRight: "2vw" }}>
                                                    <Link to={"/devices_region"} className="cl-10 hov-c" style={phone ? fsmenu : {}}>
                                                        {phone ? 'Region' : 'Region'}
                                                    </Link>
                                                </div>
                                                <div className="" style={{ marginRight: "2vw" }}>
                                                    <Link to={"/mikro_list"} className="cl-10 hov-c" style={phone ? fsmenu : {}}>
                                                        {phone ? 'UWmikro' : 'UW mikro'}
                                                    </Link>
                                                </div> */}

                                            </> : <></>
                                        }
                                    </> : <></>
                                }

                            </>

                            :
                            currentUserRole.includes("ROLE_MODERATOR") ?

                                <div className="df h100 w50 bg-3">
                                    <div className="" style={{ marginRight: "2vw" }}>
                                    </div>

                                    <div className="" style={{ marginRight: "2vw" }}>
                                        <Link to={"/admin_all"} className="cl-10 hov-c" style={phone ? fsmenu : {}}>
                                            Devices
                                        </Link>
                                    </div>
                                    <div className="" style={{ marginRight: "2vw" }}>
                                        <Link to={"/admin_users"} className="cl-10 hov-c" style={phone ? fsmenu : {}}>
                                            User's devices
                                        </Link>
                                    </div>
                                </div>

                                :
                                currentUserRole.includes("ROLE_USER") ?


                                    <div className="df h100 w50 bg-3">
                                        <div className="" style={{ marginRight: "2vw" }}></div>

                                        <a className="cl-10 hov-c" href='https://www.insightenergy.eu/' >
                                            UNI:WATT
                                        </a>
                                        <div className="cl-10 hov-c" style={{ marginRight: "2vw" }}>
                                        </div>

                                        <div className="" style={{ marginRight: "2vw" }}>
                                            <Link to={"/user"} className="cl-10 hov-c" style={phone ? fsmenu : {}}>
                                                Zařízení
                                            </Link>
                                        </div>

                                    </div>
                                    :
                                    <></>
                        }

                    </div>



                    {currentUser ? (

                        <div className="df f3 jc-e ai-e bg-3" style={{ marginRight: "2vw" }}>

                            <a href="/login" className="cl-10 hov-c" style={phone ? fsmenu : {}} onClick={logOut}>
                                Odhlásit
                            </a>
                        </div>
                    ) : (
                        <>
                            <div className="df f10 bg-3" style={{ width: '100%' }}></div>

                            <div className="df f10 ai-e" style={{ minWidth: '100px', maxWidth: '180px', marginBottom: '6px' }}     
                                                   >
                                <Link to={"/login"} className="df f1 cl-10 hov-c" style={phone ? fsmenu : { paddingRight: '10px' }}>
                                    Přihlásit
                                </Link>

                                <Link to={"/register"} className="df jc-c f2 cl-10 hov-c" style={phone ? fsmenu : { paddingLeft: '10px',paddingRight: '10px' }}>
                                    Zaregistrovat
                                </Link>

                            </div>
                        </>
                    )}

                </div>
            </div>


            <div>
                <Switch>

                    {/* <Route
                        exact
                        path="/"
                        render={() => {
                            return (
                                <Redirect to="/region_list" />
                                //   this.state.isUserAuthenticated ?
                                //   <Redirect to="/home" /> :
                                //   <Redirect to="/test1" /> 
                            )
                        }}
                    /> */}

                    <Route exact path="/login" component={Login} />
                    <Route exact path="/register" component={Register} />

                    <Route path="/admin_all" component={() => <BoardAdminAll user={currentUser} />} />
                    <Route path="/admin_users" component={() => <BoardAdminUsers user={currentUser} />} />
                    <Route path="/admin_moderators" component={() => <BoardAdminModerators user={currentUser} />} />
                    {/* hand={fcCurrentDevice} */}


                    <Route path="/devices_overview" component={() => <BoardDevicesOverview user={currentUser} />} />
 

                    <Route path="/admin_uw" component={BoardAdmin_uw} />
                    <Route path="/admin_ug" component={BoardAdmin_ug} />

                    <Route path="/user" component={BoardUser} />
                    <Route path="/device_content" component={BoardDeviceContent} />
{/* 
                    <Route path="/devices_region" component={BoardRegion} />

                    <Route path="/mikro_list" component={BoardUWmikro} />
                    <Route path="/uwmikro_detail" component={() => <BoardUWmikroDetail />} /> */}

                    <Route path="/device_charts" component={() => <BoardDeviceCharts user={currentUser} device={currentDevice} />} />

                    <Route path="/charts" component={Charts} />
                    <Route path="/charts_stat" component={ChartsStat} />


                    {/* <Route path="/dev-app" component={() => <PageMain user={currentUser} />} /> */}
                    <Route path="/app_structure" component={() => <PageMain user={currentUser} />} />

                    <Route path="/rtu_data" component={() => <RTUdata user={currentUser} />} />

                </Switch>
            </div>

            {/* <AuthVerify logOut={logOut}/> */}
        </div>
    );
};

export default App;
