import React, { useState, useEffect } from "react";
import AppService from "../services/app.service.js";

import gl from "../global_var.js";
import EventBJS from "../common/EventBusJS.js";
import Trace from "./trace.js";

import Top from "./top.js";
import { RowNadpis, RowNadpis_hand, Row_1_1, Line } from './rows.js';


import "./Page.css";


const PageVyroba = (ap, user) => {

    const [pr, setContent] = useState(
        { P: 0, today: 0, total: 0 }
    );
    const [pr1, setContent1] = useState(
        { P: 0, today: 0, total: 0 }
    );
    const [pr2, setContent2] = useState(
        { P: 0, today: 0, total: 0 }
    );
    const [pr3, setContent3] = useState(
        { P: 0, today: 0, total: 0 }
    );


    useEffect(() => {

        setTimeout(() => {
            getDataFromSource(); //pri nacteni stranky
        }, 100);

        EventBJS.on("refMenu", () => {
            getDataFromSource(); // pri prepnuti stranky z menu
        });

        const ti = setInterval(() => {
            getDataFromSource(); // pravidelny refresh
        }, 5000);

        return () => {
            clearInterval(ti)
            EventBJS.remove("refMenu");
        };
    }, []);



    const getDataFromSource = () => {

        let P = 0
        let today = 0
        let total = 0

        AppService.getPageData(gl.act.items[0].devid, gl.act.items[0].src).then(
            (response) => {
                // console.log('111', response.data)
                const res = { ...response.data }

                if (response.data !== 'timeout' && Object.keys(response.data).length !== 0) {
                    setContent1({ P: res.P, today: rozdelNaTrojice(res.today, 1), total: rozdelNaTrojice(res.total) })
                } else {
                    setContent1({ P: '', today: 'čekám na data...', total: '' })
                }
                P = P + res.P
                today = today + res.today
                total = total + res.total
            })

        if (gl.act.items.length > 1) {

            setTimeout(() => {
                AppService.getPageData(gl.act.items[1].devid, gl.act.items[1].src).then(
                    (response) => {
                        // console.log('222', response.data)
                        const res = { ...response.data }

                        if (response.data !== 'timeout' && Object.keys(response.data).length !== 0) {
                            setContent2({ P: res.P, today: rozdelNaTrojice(res.today, 1), total: rozdelNaTrojice(res.total) })
                        } else {
                            setContent2({ P: '', today: 'čekám na data...', total: '' })
                        }
                        P = P + res.P
                        today = today + res.today
                        total = total + res.total
                    })
            }, 200);
        }
        if (gl.act.items.length > 2) {

            setTimeout(() => {
                AppService.getPageData(gl.act.items[2].devid, gl.act.items[2].src).then(
                    (response) => {
                        // console.log('333', response.data)
                        const res = { ...response.data }

                        if (response.data !== 'timeout' && Object.keys(response.data).length !== 0) {
                            setContent3({ P: res.P, today: rozdelNaTrojice(res.today, 1), total: rozdelNaTrojice(res.total) })
                        } else {
                            setContent3({ P: '', today: 'čekám na data', total: '' })
                        }
                        P = P + res.P
                        today = today + res.today
                        total = total + res.total
                    })
            }, 400);
        }
        setTimeout(() => {
            setContent({ P: P, today: rozdelNaTrojice(today, 1), total: rozdelNaTrojice(total) })
            setTimeout(() => { setContent({ P: P, today: rozdelNaTrojice(today, 1), total: rozdelNaTrojice(total) }) }, 1000);//jeste jednou
        }, 1000);

    }


    function rozdelNaTrojice(ciselnyString, dec = 0) {
        return ciselnyString.toFixed(dec).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }


    const set1 = () => {
        Trace.switchApp(1)
    }
    const set2 = () => {
        Trace.switchApp(2)
    }
    const set3 = () => {
        Trace.switchApp(3)
    }

    return (
        <>
            <Top txt={gl.act?.top} />

            <div className="vspace"></div>

            {/* pro celkem musi byt vic nez jedna vyroba */}
            {gl.act.items.length > 1 ?
                <>
                    <RowNadpis txt='Celkem' />
                    <div className="cont-p bg-15">
                        <Row_1_1 txt='Aktuální výkon (kW)' v={(pr.P).toFixed(2)} />
                        <Line />
                        <Line />
                        <Line />
                        <Row_1_1 txt='Výroba dnes (kWh)' v={pr.today} />
                        <Line />
                        <Row_1_1 txt='Výroba celkem (kWh)' v={pr.total} />
                    </div>
                    <div className="vspace"></div>
                    <div className="vspace"></div>
                    <div className="vspace"></div>
                </>
                : <></>}


            {/* prvni vyroba je vzdycky videt*/}
            <RowNadpis_hand txt={gl.act?.items[0]?.name} hand={set1} />
            <div className="cont-p bg-15">
                <Row_1_1 txt='Aktuální výkon (kW)' v={(pr1.P * 1).toFixed(2)} />
            </div>
            <div className="cont-p bg-15">
                <Row_1_1 txt='Dnes (kWh)' v={pr1.today} />
                <Line />
                <Row_1_1 txt='Celkem (kWh)' v={pr1.total} />
            </div>
            <div className="vspace"></div>
            <div className="vspace"></div>
            <div className="vspace"></div>


            {/* musi byt vic nez jedna vyroba */}
            {gl.act.items.length > 1 ?
                <>
                    <RowNadpis_hand txt={gl.act?.items[1]?.name} hand={set2} />
                    <div className="cont-p bg-15">
                        <Row_1_1 txt='Aktuální výkon (kW)' v={(pr2.P * 1).toFixed(2)} />
                        <Line />
                        <Line />
                        <Line />
                        <Row_1_1 txt='Výroba dnes (kWh)' v={pr2.today} />
                        <Line />
                        <Row_1_1 txt='Výroba celkem (kWh)' v={pr2.total} />
                    </div>
                    <div className="vspace"></div>
                    <div className="vspace"></div>
                    <div className="vspace"></div>
                </>
                : <></>}


            {/* musi byt vic nez dve vyroby */}
            {gl.act.items.length > 2 ?
                <>
                    <RowNadpis_hand txt={gl.act?.items[2]?.name} hand={set3} />
                    <div className="cont-p bg-15">
                        <Row_1_1 txt='Aktuální výkon (kW)' v={(pr3.P * 1).toFixed(2)} />
                        <Line />
                        <Line />
                        <Line />
                        <Row_1_1 txt='Výroba dnes (kWh)' v={pr3.today} />
                        <Line />
                        <Row_1_1 txt='Výroba celkem (kWh)' v={pr3.total} />
                    </div>
                    <div className="vspace"></div>
                    <div className="vspace"></div>
                </>
                : <></>}
        </>
    );
};

export default PageVyroba;



