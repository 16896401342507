import React, { useState, useEffect } from 'react';

import './animace.css';


const AnimaceStrips = (pr) => {

    let animPar = {}

    function getSpeed(vykon) {
        let speed = 0
        if (vykon >= 10000) { speed = 100; }
        else if (vykon > 0) { speed = 1000000 / vykon; }
        if (speed > 1500 && vykon > 0) { speed = 1500 }
        if (vykon <= 0) { speed = 0 }
        return speed
    }
    function getSmer(vykon) {
        let smer
        if (vykon > 0) smer = false
        else smer = true
        return smer
    }

    if (pr?.onl === 'true' && pr?.con === 'true') {
        animPar.pw = getSpeed(pr.anim?.pw)
        animPar.grid = getSpeed(Math.abs(pr.anim?.grid))
        animPar.dum = getSpeed(pr.anim?.dum)
        animPar.bat = getSpeed(Math.abs(pr.anim?.bat))
        animPar.smerGrid = getSmer(pr.anim?.grid)
        animPar.smerBat = getSmer(pr.anim?.bat * (-1))

    }
    else {
        animPar.pw = 0
        animPar.grid = 0
        animPar.dum = 0
        animPar.bat = 0
        animPar.smerGrid = false
        animPar.smerBat = false
    }

    return (
        <>
            {/* elektarna */}
            <Strip startx={101} starty={50} stepx={0} stepy={7} count={6} smer={true} speed={animPar?.pw} />
            {/* stozar */}
            <Strip startx={122} starty={102} stepx={7} stepy={0} count={5} smer={animPar.smerGrid} speed={animPar?.grid} />
            {/* dum */}
            <Strip startx={101} starty={119} stepx={0} stepy={7} count={6} smer={true} speed={animPar?.dum} />
            {/* baterie */}
            <Strip startx={50} starty={102} stepx={7} stepy={0} count={5} smer={animPar.smerBat} speed={animPar?.bat} />
        </>
    )
}

const Strip = (props) => {

    const [ktera, setKtera] = useState(0);
    const [offs, schovejCervenou] = useState(0);

    let x = []
    let y = []

    x[0] = props.startx
    for (let i = 1; i < props.count; i++) {
        x[i] = x[i - 1] + props.stepx
    }

    y[0] = props.starty
    for (let i = 1; i < props.count; i++) {
        y[i] = y[i - 1] + props.stepy
    }

    let content = []
    for (let i = 0; i < props.count; i++) {
        let row = <Circle x={x[i]} y={y[i]} ord={i} act={ktera + offs} />
        content.push(row)
    }

    useEffect(() => {
        var timer = null

        if (props.speed === 0) { schovejCervenou(100) } else { schovejCervenou(0) }

        timer = setInterval(() => {
            if (props.smer === true) setKtera(ktera + 1)
            if (props.smer === false) setKtera(ktera - 1)
        }, 500);

        if (props.smer === true && ktera >= props.count) setKtera(0)
        if (props.smer === false && ktera < 0) setKtera(props.count)

        return () => clearInterval(timer);
    }, [ktera]);


    return (
        < >
            {content}
        </>
    );
};


let gcolor = '#333'
let bcolor = '#333'

const Circle = (props) => {

    const [color, setColor] = useState(gcolor);

    useEffect(() => {
        if (props.act === props.ord) setColor('#8FC643')
        else setColor(gcolor)
    }, [props.act]);

    return (
        <div className='containerx' style={{
            gridRow: props.y + ' / span 1', gridColumn: props.x + ' / span 1',
            // backgroundColor: '#8FC643'
        }}>
            <div style={{ marginLeft: '-9px', marginTop: '-9px' }}>
                <svg height="20" width="20">
                    <circle cx="10" cy="10" r="4" stroke={bcolor} strokeWidth="1" fill={color} />
                </svg>
            </div>

        </div>
    );
};


export default AnimaceStrips;