
import React, { useState, useEffect } from 'react';

// import '../objekty.css';
import './Board.css';


//****************************************************************************** */

const OTEgraf = (pr) => {

    const min = Math.min(...pr.series)
    const max = Math.max(...pr.series)
    const rmin = Math.floor(min)
    const rmax = Math.floor(max)

    const flmax = 1000 * max
    const flmin = 1000 * min

    const flupper = Math.floor(1000 * (max - rmax))

    const flab0 = min < 0 ? Math.floor(1000 * max) : Math.floor(1000 * max)
    const flun0 = min > 0 ? 0 : Math.floor(-1000 * min)

    const fllow = min > 0 ? flmin : 0



    let lines = []
    const stl = { display: 'flex', borderBottom: '2px solid #5554', width: '100%', alignItems: 'end', fontSize: '1vh' }

    lines.push(<div style={{ ...stl, flex: flupper }}>{rmax}</div>)

    for (let i = 0; i < rmax; i++) {
        lines.push(<div style={{ ...stl, flex: '1000' }}>
            {(rmax - i - 1)}
        </div>)
    }

    flun0 > 0 ?
        lines.push(<div style={{ ...stl, border: '', flex: flun0 }}>
        </div >)
        : <></>


    let bgline =
        <div style={{
            display: 'flex',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: '100%',
            flexDirection: 'row',
        }}>
            <div style={{ flex: '2', position: 'relative', }}>
                <div style={{
                    display: 'flex',
                    position: 'absolute',
                    bottom: '50px',
                    width: '30px',
                    transform: "rotate(270deg)",
                    whiteSpace: 'nowrap',
                    fontSize: '1vh',
                }}>Cena ( Kč )</div>
            </div>

            <div style={{ display: 'flex', flex: '25', height: '100%', flexDirection: 'column' }}>
                {lines}
            </div>
        </div>

    let obsah = []
    for (let i = 0; i <= 23; i++) {

        if (pr.series) {
            obsah.push(
                <div style={{ display: 'flex', flex: '1', flexDirection: 'column', borderRight: '2px solid #F7F7F7', background: '#eee' }}>
                    <div style={{
                        display: 'flex',
                        flex: flab0,
                        flexDirection: 'column',
                    }}>
                        {
                            pr.series[i] >= 0 ?
                                <>
                                    <div style={{
                                        display: 'flex',
                                        flex: flab0 - 1000 * (pr.series[i]),
                                        background: '#eee'
                                    }}></div>
                                    <div style={{
                                        display: 'flex',
                                        flex: 1000 * pr.series[i],
                                        background: pr.series[i] < 1 ? '#050' :
                                            pr.series[i] < 2 ? '#0a0' :
                                                pr.series[i] < 3 ? '#dd0' :
                                                    'red'
                                    }}></div>
                                </>
                                :
                                <></>
                        }
                    </div>

                    <div style={{
                        display: 'flex',
                        flex: flun0,
                        flexDirection: 'column',
                    }}>
                        {
                            pr.series[i] < 0 ?
                                <>
                                    <div style={{
                                        display: 'flex',
                                        flex: 1000 * (pr.series[i] * -1),
                                        background: '#050'
                                    }}></div>
                                    <div style={{
                                        display: 'flex',
                                        flex: flun0 + 1000 * (pr.series[i]),
                                        background: '#eee'
                                    }}></div>
                                </>
                                :
                                <></>
                        }
                    </div>
                </div>)
        }
    }

    let casy = []
    for (let i = 0; i <= 23; i++) {
        casy.push(<div style={{
            display: 'flex', flex: '1',
            justifyContent: 'center', alignItems: 'center',
            borderRight: '2px solid transparent',
            fontSize: '1vh'
        }}>
            {i + 1}
        </div>)
    }

    const move = (e) => {
        let obj = document.getElementById(e.target.id)
        changecol(obj)
    }
    const leave = (e) => {
        let ott = document.getElementById('ttOTE') // tooltip
        if (ott) ott.style.visibility = 'hidden'

        let objx = document.getElementById(actid)
        if (objx) objx.style.background = 'transparent'

        // let obj = document.getElementById(e.target.id)
        // obj.style.background = 'transparent'            
    }


    const [oldid, setoldid] = useState('')
    const [actid, setactid] = useState('')
    const [strtt, setstrtt] = useState('')
    const [castt, setcastt] = useState('')

    const mm = (e) => {
        if (e.type === 'touchstart' || e.type === 'touchmove' || e.type === 'touchend' || e.type === 'touchcancel') {
            let evt = (typeof e.originalEvent === 'undefined') ? e : e.originalEvent;
            let touch = evt.touches[0] || evt.changedTouches[0];
            let obj = document.elementFromPoint(touch.pageX, touch.pageY);

            if (e.type === 'touchstart' || e.type === 'touchmove') {
                changecol(obj)
            }
            if (e.type === 'touchend' || e.type === 'touchcancel') {
                let ott = document.getElementById('ttOTE') // tooltip
                ott.style.visibility = 'hidden'
                let objx = document.getElementById(actid)
                objx.style.background = 'transparent'
            }
        }
    }
    const changecol = (obj) => {

        if (obj?.id.slice(0, 3) === 'hov' && pr.series.length > 0) {
            obj.style.background = '#ddda'
            setactid(obj.id)

            if (oldid !== obj.id) {
                let oi = document.getElementById(oldid)
                if (oldid !== '') oi.style.background = 'transparent'


                let o1 = document.getElementById('hov0') // prvni zleva
                let wid1 = o1.clientWidth

                let ob = document.getElementById(obj.id) // prave vybranej
                let rect = ob.getBoundingClientRect();

                let ott = document.getElementById('ttOTE') // tooltip
                ott.style.top = (rect.y) + 'px'

                if (obj?.id.slice(3, 5) < 11) ott.style.left = (rect.x + wid1 / 2) + 'px'
                else ott.style.left = (rect.x - 100) + 'px'

                ott.style.visibility = 'visible'

                if (pr.series[obj?.id.slice(3, 5)]) {
                    setstrtt(`${pr.series[obj?.id.slice(3, 5)].toFixed(2)} Kč`)
                }
                setcastt(`${obj?.id.slice(3, 5)}:00 - ${1 * (obj?.id.slice(3, 5)) + 1}:00`)
                setoldid(obj.id)
            }
        }
    }

    let how = []
    for (let i = 0; i <= 23; i++) {
        how.push(
            <div style={{ display: 'flex', flex: '1', flexDirection: 'column', borderRight: '2px solid transparent' }}>
                <div id={'hov' + i}
                    onMouseOver={move}
                    // onMouseLeave={leave}
                    onTouchStart={mm}
                    onTouchMove={mm}
                    onTouchEnd={mm}
                    style={{
                        display: 'flex',
                        flex: flmax + fllow,
                        flexDirection: 'column',
                        background: 'transparent'
                    }}>
                </div>
            </div>)
    }

    return (
        <>
            <div onMouseLeave={leave}
                classname="df f1 fdir-c h100 bg-4" style={{ width: '100%' }}>

                <div style={{ display: 'flex', flex: '16', height: '100%', position: 'relative', background: '#F7F7F7' }}>
                    {bgline}
                    <div style={{ flex: '3', background: 'transparent' }}></div>
                    {obsah}

                    <div style={{ display: 'flex', flex: '16', width: '100%', height: '100%', position: 'absolute', top: '0', left: '0', }}>
                        <div style={{ flex: '3', background: 'transparent' }}></div>
                        {how}
                    </div>
                </div>

                <div style={{ display: 'flex', flex: '1', flexDirection: 'row', height: '10%' }}>
                    <div style={{ flex: '3', background: 'transparent' }}></div>
                    {casy}
                </div>
                <div style={{ display: 'flex', flex: '1', justifyContent: 'center', height: '10%', fontSize: '1vh' }}>
                    &nbsp;&nbsp;Hodina
                </div>
            </div>


            <div id="ttOTE"
                style={{
                    display: 'flex', height: '6vh', position: 'fixed', zIndex: '100', visibility: 'hidden',
                    background: 'white', border: '1px solid #ccc', borderRadius: '1vh',
                    flexDirection: 'column',
                    justifyContent: 'center', alignItems: 'center', fontSize: '1.4vh'
                }}>
                <div style={{
                    flex: '130', display: 'flex', alignItems: 'center', background: '#ECEFF1', borderRadius: '1vh 1vh 0 0'
                }}>
                    &nbsp;&nbsp;{castt}&nbsp;&nbsp;
                </div>
                <div style={{ flex: '130', display: 'flex', alignItems: 'center', background: 'greenx' }}>{strtt}</div>
            </div>
        </>
    )
}
export default OTEgraf


const Radiobut = (pr) => {

    return (
        <div className="df w100">
            <div className="df jce aic f3">
                {pr.txt}&nbsp;&nbsp;
            </div>
            <div className="df f1 hov" style={{ position: 'relative', minWidth: '20px' }} onClick={() => pr.callb(pr.pos)}>
                <svg width="100%" height="100%">
                    <circle cx="50%" cy="50%" r="0.8vh" stroke="#999" strokeWidth="1" fill='#fff'
                    />
                </svg>
                <svg width="100%" height="100%" style={{ position: 'absolute', margin: '0 auto' }} >
                    <circle cx="50%" cy="50%" r="0.6vh" stroke="#444" strokeWidth="0"
                        fill={pr.act == pr.pos ? '#777' : '#fff'}
                    />
                </svg>
            </div>
        </div>
    )
}


export const Radiobutton = (pr) => {

    return (

        <div className="df jce w100" style={{ height: '3vh'}}>

            <div className="dfc f15 fs3">
                Zohlednit poplatky za distribuci
            </div>

            <div className="df f15 h100">

                <div className="df f8 fs3">
                    <Radiobut txt='Offset s poplatky' act={pr.act} pos={1} callb={pr.callb} />
                </div>

                <div className="df f2"> </div>

                <div className="df f8 fs3">
                    <Radiobut txt='Bez poplatků' act={pr.act} pos={2} callb={pr.callb} />
                </div>

                <div className="df f4"> </div>
            </div>


        </div>
    )

}

