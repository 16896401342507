import React, { useState, useEffect } from 'react';

import AnimaceStrips from './animace-strips.js';

import './animace.css';
import './Board.css';

import { ReactComponent as Solar } from '../images/solar-panel.svg';
import { ReactComponent as Blesk } from '../images/electricity-svgrepo-com31.svg';
import { ReactComponent as Stozar } from '../images/electric-tower-tower-svgrepo-com.svg';
import { ReactComponent as Home } from '../images/web-home-svgrepo-com.svg';

import { ReactComponent as BatEmpt } from '../images/battery-slash.svg';
import { ReactComponent as Bat0 } from '../images/battery-empty.svg';
import { ReactComponent as Bat25 } from '../images/battery-quarter.svg';
import { ReactComponent as Bat50 } from '../images/battery-half.svg';
import { ReactComponent as Bat75 } from '../images/battery-three-quarters.svg';
import { ReactComponent as Bat100 } from '../images/battery-full.svg';


const Animace = (pr) => {

    //************************************************************************ */

    let Battery = Bat100;
    if (pr.anim?.soc < 90) Battery = Bat75;
    if (pr.anim?.soc < 65) Battery = Bat50;
    if (pr.anim?.soc < 40) Battery = Bat25;
    if (pr.anim?.soc < 10) Battery = Bat0;
    if (pr.anim?.soc < 2) Battery = BatEmpt;

    // pr.anim.socw = 0.5
    const socw = pr.anim?.socw ? ` ~ ` + pr.anim?.socw?.toFixed(2) + ` kWh` : ``

    return (
        <div className="grid-container" style={{ backgroundColor: '#F7F7F7' }}>

            <AnimaceStrips anim={pr?.anim} con={pr?.con} onl={pr?.onl} />

            {/* elektrarna */}
            <SvgXY x='99' y='8' w='1' h='22' ico={Solar} color='#F29F05' />
            {pr?.onl === 'true' && pr?.con === 'true' ? <Labxy x='93' y='33' wrap='no' color='#575757' txt={(pr?.anim?.pw / 1000)?.toFixed(3) + ' kW'} /> : <></>}

            {/* prostredek */}
            <SvgXY x='101' y='94' w='1' h='20' ico={Blesk} color='#575757' />

            {/* stozar */}
            <SvgXY x='170' y='95' w='1' h='16' ico={Stozar} color='#F23005' />
            {pr?.onl === 'true' && pr?.con === 'true' ? <Labxy x='162' y='114' wrap='no' color='#575757' txt={(pr.anim?.grid / 1000)?.toFixed(3) + ' kW'} /> : <></>}

            {/* dum */}
            <SvgXY x='101' y='178' w='1' h='14' ico={Home} color='#185359' />
            {pr?.onl === 'true' && pr?.con === 'true' ? <Labxy x='93' y='164' wrap='no' color='#575757' txt={(pr.anim?.dum / 1000)?.toFixed(3) + ' kW'} /> : <></>}

            {/* baterie */}
            <SvgXY x='30' y='97' w='1' h='14' ico={Battery} color='#8FC643' />
            {pr?.onl === 'true' && pr?.con === 'true' ?
                <>
                    <Labxy x='24' y='114' wrap='no' color='#575757' txt={(pr.anim?.bat / 1000)?.toFixed(3) + ' kW'} />
                    <Labxy x={socw == '' ? '22' : '28'} y='83' wrap='no' color='#575757' txt={`${pr.anim?.soc} % ${socw}`} fs={socw == '' ? '' : 'sm'} />
                </> : <></>}

        </div >
    )
}
export default Animace


export class Labxy extends React.Component {

    render() {
        let x = Math.floor(this.props.x)
        let y = Math.floor(this.props.y)
        return (
            <>
                <div className='containerx' style={{ gridRow: y + ' / span 1', gridColumn: x + 7 + ' / span 1' }}>
                    <div className='divincont' >
                        <LabCent fs={this.props.fs} wrap={this.props.wrap} color={this.props.color} txt={this.props.txt} />
                    </div >
                </div>
            </>
        )
    }
}

const LabCent = (props) => {

    let cl = Math.floor(props.s)

    let wrap
    if (props.wrap === 'yes') wrap = 'wrap'
    if (props.wrap === undefined || props.wrap === 'no') wrap = 'nowrap'

    let fs = props.fs == 'sm' ? 'fs-c4' : 'fs-c6'

    return (
        <>
            <div className='cellin' style={{ gridRow: '1 / span 1', gridColumn: cl + ' / span 1', position: 'relative' }} >
                <div
                    className={fs}
                    style={{
                        display: 'flex', justifyContent: 'center',
                        whiteSpace: wrap,
                        color: props.color,
                        background: props.bg ? props.bg : 'transparent',
                    }}
                    onClick={props.handler}
                >
                    {props.txt}
                </div>
            </div>
        </>
    )

}

export class SvgXY extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let x = this.props.x
        let y = this.props.y
        let h = this.props.h
        let w = this.props.w

        return (
            <>
                <div className='containerx' style={{
                    gridRow: y + ' / span ' + h, gridColumn: x + ' / span ' + w,
                    // border: '1px solid white',
                }}
                >
                    <div style={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        border: "none",
                        backgroundColor: "transparent",
                    }}
                        onClick={this.props.hand}
                    >
                        <this.props.ico fill={this.props.color}
                            style={{
                                position: 'absolute',
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                height: '100%',
                                width: 'auto',
                                backgroundColor: "rtransparent",
                                userSelect: 'none',
                                filter: this.props.filter ? this.props.filter : ''
                            }}
                        />
                    </div>
                </div >
            </>
        )
    }
}

