import React, { useState, useEffect, forwardRef } from "react";
import { useLocation } from 'react-router-dom'
import { Link } from "react-router-dom";

import Chart from "react-apexcharts";
// import DatePicker from "react-datepicker";
import Month from './DateMonthpicker.js';
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import cs from 'date-fns/locale/cs';
import './Charts.css';

import ChartService from "../services/chart.service";

import "./Board.css";

registerLocale('cs', cs)


const Charts = (props) => {

    const phoneWidth = 600;

    let chartOptions = {

        chart: {
            id: 'Data',
            height: 350,
            zoom: {
                enabled: false
            },
            events: {
                mouseMove: function (event, chartContext, config) {
                    var tooltip = chartContext.el.querySelector('.apexcharts-tooltip');

                    let position = config.dataPointIndex
                    let pos = config.globals.clientX

                    // tooltip.style.top = '80px'

                    if (position !== -1) {

                        if (grafDim[0] > phoneWidth) {

                            tooltip.style.top = '80px'

                            if (pos < config.globals.gridWidth / 4) {
                                tooltip.style.left = pos + 150 + 'px';
                            }
                            else {
                                tooltip.style.left = pos - 200 + 'px';
                            }
                        }
                        else {
                            tooltip.style.top = '80px'

                            if (pos < config.globals.gridWidth / 3) {
                                tooltip.style.left = config.globals.gridWidth - tooltip.style.width - 35 + 'px';
                            }
                            else {
                                tooltip.style.left = '10px';
                            }
                        }

                        // if (pos < config.globals.gridWidth / 4) {
                        //     tooltip.style.left = pos + 150 + 'px';
                        // }
                        // else {
                        //     tooltip.style.left = pos - 300 + 'px';
                        // }

                    }
                },
                beforeZoom: function (chartContext, { xaxis }) {//beforeZoom

                    let pocetnaobrazovce = xaxis.max - xaxis.min
                    if (pocetnaobrazovce < 5) chartContext.w.config.plotOptions.bar.columnWidth = '200%'
                    else if (pocetnaobrazovce < 10) chartContext.w.config.plotOptions.bar.columnWidth = '200%'
                    else if (pocetnaobrazovce < 20) chartContext.w.config.plotOptions.bar.columnWidth = '150%'
                    else chartContext.w.config.plotOptions.bar.columnWidth = '70%'
                },
                beforeResetZoom: function (chartContext, { xaxis }) {
                    chartContext.w.config.plotOptions.bar.columnWidth = '70%'
                },
                updated: function (chartContext, config) {
                    chartContext.w.config.plotOptions.bar.columnWidth = '70%'
                }
            },
        },
        xaxis: {
            categories: [],
            tickPlacement: 'on'
        },
        yaxis: {
            labels: { formatter: function (val, index) { return val.toFixed(2) + ' kW'; } },
            tickAmount: 4,
            min: 0,
            forceNiceScale: true
        },
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            bar: {
                columnWidth: '70%'
            }
        },
        colors: ['#E6A23C', '#FF4A55', '#409EFF', '#C09852', '#3DAF2C', '#999999', '#666666', '#31AECA'],
        tooltip: {
            enabled: true,
            enabledOnSeries: [0, 1, 2, 3, 4, 5, 6, 7],
            shared: true,
            intersect: false,
            // followCursor: true,
            fixed: {
                enabled: true,
            },
            y:
                [
                    { formatter: function (val, index) { if (val != null) return val.toFixed(2) + ' kWh'; } },
                    { formatter: function (val, index) { if (val != null) return val.toFixed(2) + ' kWh'; } },
                    { formatter: function (val, index) { if (val != null) return val.toFixed(2) + ' kWh'; } },
                    { formatter: function (val, index) { if (val != null) return val.toFixed(2) + ' kWh'; } },
                    { formatter: function (val, index) { if (val != null) return val.toFixed(2) + ' kWh'; } },
                    { formatter: function (val, index) { if (val != null) return val.toFixed(2) + ' kWh'; } },
                    { formatter: function (val, index) { if (val != null) return val.toFixed(2) + ' kWh'; } },
                    { formatter: function (val, index) { if (val != null) return val.toFixed(2) + ' kWh'; } }
                ]
        },
    }

    let dataset = [[], [], [], [], [], [], [], []]
    let dbid = props.location.dbid


    const getMonthData = (y, m) => {
        // console.log('dbid',dbid)

        ChartService.getMonth(dbid, y, m).then(
            (response) => {
                // console.log(response.data)

                dataset = [[], [], [], [], [], [], [], []]
                let cat = []//categories

                // console.log(response.data)

                for (let i = 0; i < response.data.length; i++) {

                    // cat.push('i+4')

                    dataset[0][i] = (response.data[i].pv.toFixed(2))
                    dataset[1][i] = (response.data[i].feed.toFixed(2))
                    dataset[2][i] = (response.data[i].load.toFixed(2))
                    dataset[3][i] = (response.data[i].grid.toFixed(2))
                    dataset[4][i] = (response.data[i].charge.toFixed(2))
                    dataset[5][i] = (response.data[i].disch.toFixed(2))
                    dataset[6][i] = (response.data[i].auto.toFixed(2))
                    dataset[7][i] = (response.data[i].surplus.toFixed(2))
                }

                setSeries([
                    { name: 'PV', data: dataset[0] },
                    { name: 'Feed-in', data: dataset[1] },
                    { name: 'Load', data: dataset[2] },
                    { name: 'Grid Compsuption', data: dataset[3] },
                    { name: 'Charge', data: dataset[4] },
                    { name: 'Discharge', data: dataset[5] },
                    { name: 'Car', data: dataset[6] },
                    { name: 'Surplus', data: dataset[7] }

                ])

                // chartOptions.xaxis.categories = cat

                setOptions(chartOptions)
            }
        )
    }

    const [series, setSeries] = useState([{}])
    const [options, setOptions] = useState(chartOptions)


    //** dimension ************

    const getDivchartSize = () => {
        return ([document.documentElement.clientWidth, document.documentElement.clientHeight])
    }

    const [grafDim, setGrafDim] = useState(getDivchartSize());

    useEffect(() => {
        setGrafDim(getDivchartSize())//prvotni nastaveni vysky grafu podle parent divu

        const resizeListener = () => {
            setGrafDim(getDivchartSize())
        };
        window.addEventListener('resize', resizeListener);

        return () => { window.removeEventListener('resize', resizeListener); }
    }, [])

    //***************************


    let content = <></>

    if (grafDim[0] >= phoneWidth) {

        // **** Desktop ******************************************************************************* 

        content =

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: '95vh',
                justifyContent: 'center',
            }}>


                <div style={{
                    display: 'flex', height: '5vw', maxHeight: '50px',
                    alignItems: 'center',
                    backgroundColor: '#F0F0F0'
                }}
                >
                    <div style={{
                        flex: '1', display: 'flex',
                        height: '100%', width: '50%',
                        alignItems: 'center',
                    }}>

                        <div style={{
                            display: 'flex',
                            fontSize: '1.01em',
                            whiteSpace: 'nowrap',
                            margin: '0px 20px 0px 10px'
                        }}>
                            DATA MONITORING
                        </div>

                        <div className='link_cont' >
                            <Link to={{ pathname: '/charts', dbid }}>
                                Power Diagram
                            </Link>
                        </div>

                        <div className='link_cont link_under' >
                            <Link className='linkact' to={{ pathname: '/charts_stat', dbid }}>
                                Statistical Diagram
                            </Link>
                        </div>

                    </div>

                    <div style={{ flex: '1', maxWidth: '202px', zIndex: '999' }}>
                        <Month selectdate={getMonthData} />
                    </div>

                </div>

                <div style={{ flex: '0.1', display: 'flex', backgroundColor: '#F0F0F0', borderTop: '2px solid #DDDDDD', marginTop: '-2px' }}></div>


                <div id='divchart' style={{ flex: '7', display: 'flex', height: '100px', backgroundColor: 'white' }}>

                    <Chart
                        options={options}
                        series={series}
                        type="bar"
                        width={grafDim[0] - 20}
                        height={grafDim[1] * 0.85}
                    />

                </div>
                <div style={{ flex: '0.1', display: 'flex', backgroundColor: 'white' }}></div>
                <div style={{ flex: '0.3', display: 'flex', backgroundColor: 'lightgray' }}></div>

            </div>
    }
    else {

        // **** Phone ******************************************************************************* 

        content =

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: '95vh',
                justifyContent: 'center',
            }}>


                <div style={{
                    display: 'flex', height: '15vw', maxHeight: '50px',
                    alignItems: 'center',
                    backgroundColor: '#F0F0F0'
                }}
                >
                    <div style={{
                        flex: '1', display: 'flex',
                        height: '100%', width: '50%',
                        alignItems: 'center',
                    }}>

                        <div style={{
                            flex: '2',
                            display: 'flex',
                            fontSize: '1.01em',
                            whiteSpace: 'nowrap',
                            margin: '0px 20px 0px 10px'
                        }}>
                            DATA MONITORING
                        </div>

                        <div style={{ flex: '1' }} className='link_cont ' >
                            <Link to={{ pathname: '/charts', dbid }}>
                                Power
                            </Link>
                        </div>

                        <div style={{ flex: '1' }} className='link_cont link_under' >
                            <Link className='linkact' to={{ pathname: '/charts_stat', dbid }}>
                                Statistics
                            </Link>
                        </div>

                        <div style={{ flex: '0.5' }} className='link_cont' ></div>

                    </div>

                </div>



                <div style={{ flex: '0.6', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>

                    <div style={{ display: 'flex', minHeight: '30px' }}>
                        <Month selectdate={getMonthData} />
                    </div>

                </div>
                <div style={{ display: 'flex', backgroundColor: '#C0C0C0', height: '1px' }}></div>


                <div id='divchart' style={{ flex: '7', display: 'flex', height: '100px', backgroundColor: 'white' }}>

                    <Chart
                        options={options}
                        series={series}
                        type="bar"
                        width={grafDim[0] - 20}
                        height={grafDim[1] * 0.79}
                    />

                </div>
                <div style={{ flex: '0.1', display: 'flex', backgroundColor: 'white' }}></div>
                <div style={{ flex: '0.2', display: 'flex', backgroundColor: 'lightgray' }}></div>

            </div>
    }
    return (
        <>
            {content}
        </>
    );
}

export default Charts;

//************************************************************************* */








