import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import UserService from "../services/user.service.js";
import EventBus from "../common/EventBus.js";

import { ReactComponent as IcoCon } from '../images/sign-out-alt.svg';
import { ReactComponent as IcoGraf } from '../images/chart-bar.svg';

import "./Board.css";

const serveradr = require('../services/IPadr.js').serveradr;

const BoardDevicesOverview = (user) => {

    const [texty, setTexty] = useState("");
    const [content, setContent] = useState("");

    const [resp, setResp] = useState("");

    const [phone, setPhone] = useState(window.innerWidth < 680 ? true : false)
    const handleResize = () => {
        if (window.innerWidth < 680) setPhone(true)
        else setPhone(false)
    }
    React.useEffect(() => {
        window.addEventListener("resize", handleResize, false);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const clickSite = async (devid) => {

        const makeid = (length) => {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        }

        const ikey = makeid(50)

        UserService.setDeviceKey(devid, ikey).then(
            (response) => {
                const path = 'http://89.221.217.59/device/' + ikey
                // console.log(path)
                window.open(path, "_parent");
            })
    }


    useEffect(() => {

        UserService.getDevicesOverview().then(
            (response) => {

                const st4 = { height: '3.6vh', }

                let cont = []
                let actUsername = ''
                if (user !== undefined) { actUsername = user.username }

                cont.push(
                    <div style={{
                        position: 'sticky', top: '0', background: '#E9ECEF', zIndex: '1000',
                        borderBottom: '1px solid rgb(140, 140, 140)'
                    }}
                        key={1}
                    >
                        <div className="radek-noline" style={{ minHeight: phone ? 'auto' : '3.6vh' }} >
                            <div className="dfc fo-b f20 fs-c3 jcs" >{'Umístění'}</div>
                            <div className="dfc fo-b w12 fs-c3 bg-d" style={st4}>{'FVE'}</div>
                            <div className="dfc fo-b w12 fs-c3" style={st4}>{'Baterie'}</div>
                            <div className="dfc fo-b w12 fs-c3 bg-d" style={st4}>{'EV'}</div>
                            <div className="dfc fo-b w12 fs-c3" style={st4}>{'TČ'}</div>
                            <div className="dfc fo-b w18 fs-c3 bg-d" style={st4}>{'Voda'}</div>
                            <div className="df f6"> </div>
                        </div>
                        <div className="radek-noline" style={{ minHeight: phone ? 'auto' : '3.6vh' }} >
                            <div className="dfc f20 jcs fo-b " >{ } </div>
                            <div className="dfc w6 tx-ac fs-c1 bg-d" style={st4}>{'aktuální výkon'}</div>
                            <div className="dfc w6 tx-ac fs-c1 bg-d" style={st4}>{'disponibilní výkon'}</div>
                            <div className="dfc w6 tx-ac fs-c1" style={st4} >{'dostupná kapacita'}</div>
                            <div className="dfc w6 tx-ac fs-c1" style={st4} >{'celková kapacita'}</div>
                            <div className="dfc w6 tx-ac fs-c1 bg-d" style={st4}>{'dostupná kapacita'}</div>
                            <div className="dfc w6 tx-ac fs-c1 bg-d" style={st4}>{'celková kapacita'}</div>
                            <div className="dfc w6 tx-ac fs-c1" style={st4} >{'dostupná kapacita'}</div>
                            <div className="dfc w6 tx-ac fs-c1" style={st4} >{'celková kapacita'}</div>
                            <div className="dfc w6 tx-ac fs-c1 bg-d" style={st4}>{'objem'}</div>
                            <div className="dfc w6 tx-ac fs-c1 bg-d" style={st4}>{'dostupná kapacita'}</div>
                            <div className="dfc w6 tx-ac fs-c1 bg-d" style={st4}>{'celková kapacita'}</div>
                            <div className="df f6"> </div>
                        </div>
                    </div>
                )
                setTexty(cont);

                setResp(response.data)
                
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setContent(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        )

        const ti = setInterval(() => {
            UserService.getDevicesOverview().then(
                (response) => {
                    setResp(response.data)
                })
        }, 5000);

        return () => {
            clearInterval(ti)
        };
    }, []);


    useEffect(() => {

        const st4 = {
            height: '100%',
            fontSize: 'clamp(0.5em, 1.3vw, 1em)'
        }

        let cont = []
        for (let i = 0; i < resp.length; i++) {

            const devid = resp[i]?.id
            const site = resp[i]?.site

            cont.push(
                <div className="radek"
                    style={{ minHeight: phone ? 'auto' : '40px' }}
                    key={i}
                >
                    {serveradr == 'https://uniwatt.cloud' ?

                        <div className="df f20 ai-c hov"
                            style={{ height: '100%', fontSize: 'clamp(0.7em, 1.4vw, 1em)' }} >
                            <a onClick={() => { clickSite(devid) }}>
                                {resp[i] ? site : ' '}
                            </a>
                        </div >
                        :
                        <Link className="df f20 ai-c"
                            style={{ height: '100%', fontSize: 'clamp(0.7em, 1.4vw, 1em)' }}
                            to={{ pathname: '/device_content', device: { dbid: devid } }}>
                            {resp[i] ? site : ' '}
                        </Link>
                    }

                    <div className="dfc w6 tx-ac bg-d" style={st4}>{resp[i]?.fve_d ? (resp[i].fve_d / 1000).toFixed(2) : ''}</div>
                    <div className="dfc w6 tx-ac bg-d" style={st4}>{resp[i]?.fve_c ? resp[i].fve_c / 1000 : ''}</div>
                    <div className="dfc w6 tx-ac" style={st4} >{resp[i]?.ba_c ? ((resp[i]?.ba_c / 1000000 * resp[i].ba_d)).toFixed(2) : ''}</div>
                    <div className="dfc w6 tx-ac" style={st4} >{resp[i]?.ba_c ? resp[i].ba_c / 1000 : ''}</div>
                    <div className="dfc w6 tx-ac bg-d" style={st4}>{resp[i]?.ev_d ? resp[i].ev_d / 1000 : ''}</div>
                    <div className="dfc w6 tx-ac bg-d" style={st4}>{resp[i]?.ev_c ? resp[i].ev_c / 1000 : ''}</div>
                    <div className="dfc w6 tx-ac" style={st4} >{resp[i]?.tc_d ? resp[i].tc_d / 1000 : ''}</div>
                    <div className="dfc w6 tx-ac" style={st4} >{resp[i]?.tc_c ? resp[i].tc_c / 1000 : ''}</div>
                    <div className="dfc w6 tx-ac bg-d" style={st4}>{resp[i]?.vo_o ? resp[i].vo_o : ''}</div>
                    <div className="dfc w6 tx-ac bg-d" style={st4}>{resp[i]?.vo_d ? resp[i].vo_d : ''}</div>
                    <div className="dfc w6 tx-ac bg-d" style={st4}>{resp[i]?.vo_c ? resp[i].vo_c : ''}</div>

                    {
                        resp[i]?.app === '2' ?
                            <Link className="dfc f6 jcc" style={{ color: 'transparent' }} to={{ pathname: '/device_charts', state: { mac: devid, devname: site } }}  >
                                <IcoGraf style={{ width: '30%', maxWidth: '25px' }} />
                            </Link>
                            :
                            (
                                <div className="dfc f6 jcs" style={{ color: 'transparent' }}>
                                    o
                                </div>
                            )
                    }
                </div >
            )
        }
        setContent(cont);

    }, [resp]);



    useEffect(() => {

        // const st4 = { height: '3.6vh', }

        // let cont = []
        // let actUsername = ''
        // if (user !== undefined) { actUsername = user.username }

        // cont.push(
        //     <div style={{
        //         position: 'sticky', top: '0', background: '#E9ECEF', zIndex: '1000',
        //         borderBottom: '1px solid rgb(140, 140, 140)'
        //     }}
        //         key={1}
        //     >
        //         <div className="radek-noline" style={{ minHeight: phone ? 'auto' : '3.6vh' }} >
        //             <div className="dfc fo-b f20 fs-c3 jcs" >{'Umístění'}</div>
        //             <div className="dfc fo-b w12 fs-c3 bg-d" style={st4}>{'FVE'}</div>
        //             <div className="dfc fo-b w12 fs-c3" style={st4}>{'Baterie'}</div>
        //             <div className="dfc fo-b w12 fs-c3 bg-d" style={st4}>{'EV'}</div>
        //             <div className="dfc fo-b w12 fs-c3" style={st4}>{'TČ'}</div>
        //             <div className="dfc fo-b w18 fs-c3 bg-d" style={st4}>{'Voda'}</div>
        //             <div className="df f6"> </div>
        //         </div>
        //         <div className="radek-noline" style={{ minHeight: phone ? 'auto' : '3.6vh' }} >
        //             <div className="dfc f20 jcs fo-b " >{ } </div>
        //             <div className="dfc w6 tx-ac fs-c1 bg-d" style={st4}>{'aktuální výkon'}</div>
        //             <div className="dfc w6 tx-ac fs-c1 bg-d" style={st4}>{'disponibilní výkon'}</div>
        //             <div className="dfc w6 tx-ac fs-c1" style={st4} >{'dostupná kapacita'}</div>
        //             <div className="dfc w6 tx-ac fs-c1" style={st4} >{'celková kapacita'}</div>
        //             <div className="dfc w6 tx-ac fs-c1 bg-d" style={st4}>{'dostupná kapacita'}</div>
        //             <div className="dfc w6 tx-ac fs-c1 bg-d" style={st4}>{'celková kapacita'}</div>
        //             <div className="dfc w6 tx-ac fs-c1" style={st4} >{'dostupná kapacita'}</div>
        //             <div className="dfc w6 tx-ac fs-c1" style={st4} >{'celková kapacita'}</div>
        //             <div className="dfc w6 tx-ac fs-c1 bg-d" style={st4}>{'objem'}</div>
        //             <div className="dfc w6 tx-ac fs-c1 bg-d" style={st4}>{'dostupná kapacita'}</div>
        //             <div className="dfc w6 tx-ac fs-c1 bg-d" style={st4}>{'celková kapacita'}</div>
        //             <div className="df f6"> </div>
        //         </div>
        //     </div>
        // )
        // setTexty(cont);
    }, []);


    return (
        <>
            <div className="df" style={{
                height: '94vh',
                overflowY: 'auto',
            }}>
                <div className="df" style={{
                    width: '100%',
                    flexDirection: 'column',
                    background: '#E9ECEF'
                }}>
                    {texty}
                    {content}
                </div>
            </div>
        </>
    );
};

export default BoardDevicesOverview;
