import React, { useState, useEffect } from "react";

import UserService from "../services/user.service.js";
import EventBJS from "../common/EventBusJS.js";

import gl from "../global_var.js";

import PageMain from "./page-main.js";

import { ReactComponent as Itest } from '../images/inverter4.svg';

import "./Page.css";

const Menu = (pr, user) => {

    useEffect(() => {
        // console.log(pr)
    }, []);


    const set1 = () => {
        gl.act = { ...gl.app.items[0] }
        EventBJS.emit("refMenu");
    }
    const set2 = () => {
        gl.act = { ...gl.app.items[1] }
        EventBJS.emit("refMenu");
    }
    const set3 = () => {
        gl.act = { ...gl.app.items[2] }
        EventBJS.emit("refMenu");
    }
    const set4 = () => {
        gl.act = { ...gl.app.items[3] }
        EventBJS.emit("refMenu");
    }

    return (
        <>
            <div className="df bg-15 pos-a" style={{ bottom: '1%', height: '10vh', width: '96%', borderRadius: '6px 6px 6px 6px' }}>

                <div className="df fdir-c jc-c ai-c" style={{ width: '100%' }}>
                    <MenuBut txt={gl.app.items[0].top} h={() => { set1() }} />
                </div>
                <div className="df fdir-c jc-c ai-c" style={{ width: '100%' }}>
                    <MenuBut txt={gl.app.items[1].top} h={() => { set2() }} />
                </div>
                <div className="df fdir-c jc-c ai-c" style={{ width: '100%' }}>
                    {gl.app.items.length > 2 ? <MenuBut txt={gl.app.items[2]?.top} h={() => { set3() }} /> : <></>}
                </div>
                <div className="df fdir-c jc-c ai-c" style={{ width: '100%' }}>
                    {gl.app.items.length > 3 ? <MenuBut txt={gl.app.items[3]?.top} h={() => { set4() }} /> : <></>}
                </div>
            </div>
        </>
    )
}
export default Menu;

const MenuBut = (pr) => {
    return (
        <>
            <div className="df" style={{ width: '50%', height: '45%' }}>
                <BtnSvg ico={Itest} hand={pr.h} />
            </div>
            <div className="df jc-c fs-menu-but" style={{ width: '80%', height: '10%' }}>
                {pr.txt}
            </div>
        </>
    )
}


export const BtnSvg = (pr) => {

    const [press, setPress] = useState(false)

    const cpress = () => {
        if (!press) { pr.hand() }
        setPress(true)
    }
    const crelease = () => {
        setPress(false)
    }
    return (
        <div style={{
            position: "relative",
            width: "100%",
            height: "100%",
            border: "none",
            backgroundColor: "transparent",
        }}
            // onClick={() => cpress()}
            onMouseDown={() => cpress()}
            onTouchStart={() => cpress()}

            onMouseUp={() => crelease()}
            onMouseOut={() => crelease()}
            onTouchEnd={() => crelease()}
        >
            <pr.ico
                className='hov'
                style={{
                    position: 'absolute',
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: '100%',
                    width: 'auto',
                    userSelect: 'none',
                    filter: press ?
                        'brightness(0) saturate(100%) invert(58%) sepia(99%) saturate(729%) hue-rotate(359deg) brightness(99%) contrast(101%)'
                        :
                        'brightness(0) saturate(100%) invert(32%) sepia(80%) saturate(1206%) hue-rotate(178deg) brightness(99%) contrast(108%)'
                }}
            />
        </div>
    )
}


