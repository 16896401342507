import axios from "axios";
import authHeader from "./auth-header";

const serveradr = require('./IPadr.js').serveradr;
const port = require('./IPadr.js').port;

//*******************************************************************
const API_URL = serveradr + port + "/api/test/";


const getPublicContent = () => {
    return axios.get(API_URL + "all");
};

const getUserBoard = () => {
    return axios.get(API_URL + "user", { headers: authHeader() });
};

const getDeviceContent = (id) => {
    if (id === undefined) return axios.get(API_URL + "device_content", { headers: authHeader() });
    else return axios.get(API_URL + "device_content?dev=" + id.dbid, { headers: authHeader() });
};

const getAdminBoard = () => {
    return axios.get(API_URL + "admin", { headers: authHeader() });
};

const getAdminBoard_all = () => {
    return axios.get(API_URL + "admin_all", { headers: authHeader() });
};



const getListRegion = () => {
    return axios.get(API_URL + "devices_region", { headers: authHeader() });
};


const getDevicesOverview = () => {
    return axios.get(API_URL + "devices_overview", { headers: authHeader() });
};



const getAdminBoard_uw = () => {
    return axios.get(API_URL + "admin_uw", { headers: authHeader() });
};

const getAdminBoard_ug = () => {
    return axios.get(API_URL + "admin_ug", { headers: authHeader() });
};

const getAdminBoard_users = () => {
    return axios.get(API_URL + "admin_users", { headers: authHeader() });//******************************** */
};

const getAdminBoard_moderators = () => {
    return axios.get(API_URL + "admin_moderators", { headers: authHeader() });
};

const getDeleteUser = (user, dev) => {
    return axios.get(API_URL + "delete_user?user=" + user + "&dev=" + dev, { headers: authHeader() });
};

const getAddDeviceToUser = (user, dev) => {
    return axios.get(API_URL + "add_device_to_user?user=" + user + "&dev=" + dev, { headers: authHeader() });
};

const getChangeRole = (user, newrole) => {
    return axios.get(API_URL + "change_role?user=" + user + "&role=" + newrole, { headers: authHeader() });
};

const getDeviceCharts = (user) => {
    return axios.get(API_URL + "device_charts?user=" + user, { headers: authHeader() });
};

const getUWmikroList = () => {
    return axios.get(API_URL + "admin_list_UWmikro", { headers: authHeader() });
};

const getUWmikroDetail = (devid) => {
    return axios.get(API_URL + "uwmikro_detail?id=" + devid, { headers: authHeader() });
};

const postUWmikroFunkce = (devid, param) => {
    return axios.post(API_URL + "uwmikro_funkce", { devid: devid, param: param }, { headers: authHeader() });
};

const getOTEdata = (devid) => {
    return axios.get(API_URL + "uwmikro_ote?id=" + devid, { headers: authHeader() });
};

const setDeviceKey = (devid, key) => {
    return axios.post(API_URL + "set_device_key", { devid: devid, key: key }, { headers: authHeader() });
};

const getRTUdata = (id) => {
    console.log(id)
    return axios.get(API_URL + "rtu_data?id=" + id, { headers: authHeader() });
};

const getTest = () => {
    return axios.get(API_URL + "admin_all", { headers: authHeader() });
};



export default {
    getPublicContent,
    getUserBoard,
    getAdminBoard,
    getAdminBoard_all,
    getAdminBoard_uw,
    getAdminBoard_ug,
    getAdminBoard_users,
    getDeviceContent,
    getDeleteUser,
    getAddDeviceToUser,
    getChangeRole,
    getAdminBoard_moderators,
    // getDeviceStatus,
    getDeviceCharts,
    getListRegion,
    getDevicesOverview,
    getUWmikroList,
    getUWmikroDetail,
    postUWmikroFunkce,
    getOTEdata,
    setDeviceKey,
    getRTUdata,
    getTest
};
