'use strict'

export default {

    region: '0',
    b2: 0,

    // values pro spustenou aplikaci

    appStruct: [], // main obj popisujici aplikaci
    app: [], // aktualni aplikace (stranka) vykostena z appSructure by index v poli appStruct
    act: {} // aktivni page
}