import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import "./Board.css";

import BoardUWMdetail from "./BoardUWmikroDetail";

import { ReactComponent as IcoCon } from '../images/sign-out-alt.svg';
import { ReactComponent as IcoGraf } from '../images/chart-line.svg';

const serveradr = require('../services/IPadr.js').serveradr;

const BoardUser = () => {

    const [content, setContent] = useState("");


    const clickSite = async (devid) => {
        const makeid = (length) => {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        }
        const ikey = makeid(50)
        UserService.setDeviceKey(devid, ikey).then(
            (response) => {
                const path = 'http://89.221.217.59/device/' + ikey
                // console.log(path)
                window.open(path, "_parent");
            })
    }


    useEffect(() => {
        UserService.getUserBoard().then(
            (response) => {

                let cont = []

                if (response.data?.uwm === 'yes') { // device je uw micro

                    cont.push(<BoardUWMdetail devid={response.data?.devices} />)
                }

                else { // device je uniwatt

                    cont.push(<h6>Zařízení dostupná pro uživatele: {response.data.username}</h6>)
                    cont.push(<h8>&nbsp;</h8>)

                    for (let i = 0; i < response.data.devices.length; i++) {

                        if (response.data.devices[i] != null) {//v databazi je asi poskozena vazba user-device

                            let dbid = response.data.devices[i].id

                            let buttonchart = <></>

                            cont.push(

                                <div className="radek">

                                    {serveradr == 'https://uniwatt.cloud' ?

                                        <div className="f7 hov">
                                            <a onClick={() => { clickSite(dbid) }}>
                                                {response.data.devices[i].site}
                                            </a>
                                        </div >
                                        :
                                        <div className="f7">
                                            <Link to={{ pathname: '/device_content', device: { dbid } }}>
                                                {response.data.devices[i].site}
                                            </Link>
                                        </div>
                                    }


                                    <div className="f1"></div>

                                    <div className="f1">
                                        {buttonchart}
                                    </div>
                                    <div className="f1">
                                        <div className="btncon" >

                                            {serveradr == 'https://uniwatt.cloud' ?

                                                <a onClick={() => { clickSite(dbid) }}>
                                                    <IcoCon className="icocon" />
                                                </a>
                                                :
                                                <Link to={{ pathname: '/device_content', device: { dbid } }}>
                                                    <IcoCon className="icocon" />
                                                </Link>
                                            }

                                        </div>
                                    </div>
                                    {
                                        // elektromery
                                        response.data.devices[i].app === '2' ?
                                            <div className="f1">
                                                <div className="btncon" >
                                                    <Link to={{ pathname: '/device_charts', state: { mac: response.data.devices[i].id } }}>
                                                        <IcoGraf className="icocon" />
                                                    </Link>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }
                                </div >
                            )
                        }
                    }
                }
                setContent(cont);

            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setContent(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        );
    }, []);

    return (

        <div className="df" style={{
            width: '100vw',
            height: '94vh',
            overflowY: 'auto',
        }}>
            <div className="df" style={{
                width: '100%',
                flexDirection: 'column',
                background: '#E9ECEF'
            }}>
                <>
                    {content}
                </>

            </div>
        </div>


        // <div className="container-fluid">
        //     <header className="jumbotron">
        //         {content}
        //     </header>
        // </div>
    );
};

export default BoardUser;
