import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import "./Board.css";


const BoardDeviceContent = (props) => {

    const [content, setContent] = useState("");

// console.log(props.location.device)

    useEffect(() => {

        UserService.getDeviceContent(props.location.device).then(
            (response) => {

                let cont = []

                cont.push(

                    <iframe src={response.data}
                        style={{
                            width: '100%',
                            height: '94.8vh',
                            maxWidth: '80vh',
                            background: 'black',
                            border: 'none'
                        }}>
                    </iframe>
                )

                setContent(cont);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setContent(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        );
    }, []);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'black'
        }}>
            {content}
        </div>
    );
};

export default BoardDeviceContent;