import axios from "axios";
import authHeader from "./auth-header.js";

const serveradr = require('./IPadr.js').serveradr;
const port = require('./IPadr.js').port;

//*******************************************************************
const API_URL = serveradr + port + "/api/app/";


const getAppStructure = (site) => {
    return axios.get(API_URL + "app_structure?&site=" + site, { headers: authHeader() });
};

const getPageData = (devid, page) => {
    return axios.get(API_URL + "page_data?&devid=" + devid + "&page=" + page, { headers: authHeader() });
};

// const setDeviceKey = (devid, key) => {
//     return axios.post(API_URL + "set_device_key", { devid: devid, key: key }, { headers: authHeader() });
// };




export default {

    getPageData,
    getAppStructure,

};
