import React, { useState, useEffect } from "react";
import EventBJS from "../common/EventBusJS.js";
import gl from "../global_var.js";
import Trace from "./trace.js";

import { ReactComponent as IcoLeft } from '../images/angle-left.svg';

import "./Page.css";

const Top = (pr, user) => {

    useEffect(() => {
        // console.log(pr)
    }, []);


    const clickBack = () => {
        Trace.switchApp(gl.app.back)
    }


    return (
        <>
            <div className="vspace"></div>

            <div className="df ai-c w100" style={{ height: '6vh' }}>
                <div className="dfc f1" style={{ maxWidth: '6vh', height: '70%' }}>

                    {gl.app.back !== undefined ? <BtnSvg ico={IcoLeft} hand={clickBack} /> : <></>}

                </div>
                <div className="dfc f1 fs-top">{pr.txt}</div>

                <div className="dfc f1" style={{ maxWidth: '6vh' }}>

                </div>
            </div>
        </>
    )
}
export default Top;



export const BtnSvg = (pr) => {

    const [press, setPress] = useState(false)

    const cpress = () => {
        if (!press) { pr.hand() }
        setPress(true)
    }
    const crelease = () => {
        setPress(false)
    }
    return (
        <div style={{
            position: "relative",
            width: "100%",
            height: "100%",
            border: "none",
            backgroundColor: "transparent",
        }}
            // onClick={() => cpress()}
            onMouseDown={() => cpress()}
            onTouchStart={() => cpress()}

            onMouseUp={() => crelease()}
            onMouseOut={() => crelease()}
            onTouchEnd={() => crelease()}
        >
            <pr.ico
                className='hov'
                style={{
                    position: 'absolute',
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: '100%',
                    width: 'auto',
                    userSelect: 'none',
                    filter: press ?
                        'brightness(0) saturate(100%) invert(58%) sepia(99%) saturate(729%) hue-rotate(359deg) brightness(99%) contrast(101%)'
                        :
                        'brightness(0) saturate(100%) invert(32%) sepia(80%) saturate(1206%) hue-rotate(178deg) brightness(99%) contrast(108%)'
                }}
            />
        </div>
    )
}


