import React, { useState, useEffect } from "react";

import "./Page.css";

import { ReactComponent as IcoRight } from '../images/angle-right.svg';

export const RowNadpis = (pr) => {
    return (
        <div className="dfc jc-s fo-b fs-txt" style={{ width: '96%', height: '4vh' }}>{pr.txt}</div>
    )
}

export const RowNadpis_hand = (pr) => {
    return (

        <div className="dfc jc-s fo-b" style={{ width: '96%', height: '4vh' }}>
            <div className="f10 fs-txt">{pr.txt}</div>
            <div className="df f1 jc-e" style={{ width: '5%', height: '100%' }}>
                <BtnSvg ico={IcoRight} hand={() => { pr.hand() }} param={''} />
            </div>
        </div>

    )
}

export const Row_1_1 = (pr) => {
    return (
        <div className="row-g">

            <div className="itemg fs-txt" style={{ gridColumn: '2/ span 50' }}>{pr.txt}</div>
            <div className="itemg jc-e cl-m fs-val" style={{ gridColumn: '50/ span 48' }}>{pr.v} </div>
        </div>
    )
}

export const Row_1_3 = (pr) => {
    return (
        <div className="row-g">
            <div className="itemg-s fs-txt" style={{ gridColumn: '2/ span 50' }}>{pr.txt}</div>

            <div className="itemg-e cl-m fs-val" style={{ gridColumn: '40/ span 13' }}>{pr.v1}</div>
            <div className="itemg-e cl-m fs-val" style={{ gridColumn: '63/ span 13' }}>{pr.v2}</div>
            <div className="itemg-e cl-m fs-val" style={{ gridColumn: '85/ span 13' }}>{pr.v3}</div>
        </div>
    )
}

export const Row_1_5 = (pr) => {
    return (
        <div className="row-g">
            <div className="itemg-s fs-txt" style={{ gridColumn: '2/ span 50' }}>{pr.txt}</div>

            <div className="itemg-e cl-m fs-val" style={{ gridColumn: '25/ span 13' }}>{pr.v1}</div>
            <div className="itemg-e cl-m fs-val" style={{ gridColumn: '40/ span 13' }}>{pr.v2}</div>
            <div className="itemg-e cl-m fs-val" style={{ gridColumn: '55/ span 13' }}>{pr.v3}</div>
            <div className="itemg-e cl-m fs-val" style={{ gridColumn: '70/ span 13' }}>{pr.v4}</div>
            <div className="itemg-e cl-m fs-val" style={{ gridColumn: '85/ span 13' }}>{pr.v5}</div>
        </div>
    )
}


export const Line = () => {
    return (
        <hr className="cara1"></hr>
    )
}

export const BtnSvg = (pr) => {

    const [press, setPress] = useState(false)

    const cpress = () => {
        if (!press) { pr.hand() }
        setPress(true)
    }
    const crelease = () => {
        setPress(false)
    }
    return (
        <div style={{
            position: "relative",
            width: "100%",
            height: "100%",
            border: "none",
            backgroundColor: "transparent",
        }}
            // onClick={() => cpress()}
            onMouseDown={() => cpress()}
            onTouchStart={() => cpress()}

            onMouseUp={() => crelease()}
            onMouseOut={() => crelease()}
            onTouchEnd={() => crelease()}
        >
            <pr.ico
                className='hov'
                style={{
                    position: 'absolute',
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: '100%',
                    width: 'auto',
                    userSelect: 'none',
                    filter: press ?
                        'brightness(0) saturate(100%) invert(58%) sepia(99%) saturate(729%) hue-rotate(359deg) brightness(99%) contrast(101%)'
                        :
                        'brightness(0) saturate(100%) invert(32%) sepia(80%) saturate(1206%) hue-rotate(178deg) brightness(99%) contrast(108%)'
                }}
            />
        </div>
    )
}