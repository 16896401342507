import axios from "axios";
import authHeader from "./auth-header";

const serveradr = require('./IPadr.js').serveradr;
const port = require('./IPadr.js').port;

//*******************************************************************
const API_URL = serveradr + port +"/api/";


const getData = (id, y, m, d) => {
    // console.log('eeeeeee',id)
    return axios.get(API_URL + "get_data?id=" + id + "&y=" + y + "&m=" + m + "&d=" + d, { headers: authHeader() });
};

const getMonth = (id, y, m) => {
    // console.log(y, m, 'click')
    return axios.get(API_URL + "get_month?id=" + id + "&y=" + y + "&m=" + m, { headers: authHeader() });
};





const getDeviceCharts = (id, ts, co) => {
    return axios.get(API_URL + "chart?id=" + id + "&ts=" + ts + "&co=" + co, { headers: authHeader() });
};

const getDeviceChartsConfig = (id) => {
    return axios.get(API_URL + "chart_config?id=" + id, { headers: authHeader() });
};


export default {
    getData,
    getMonth,
    getDeviceCharts,
    getDeviceChartsConfig
};