import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AnimacePhone from './animace.js';
import "./Board.css";
import UserService from "../services/user.service";

import Select from 'react-select';
import GetPcDetail, { GetPcSettings, GetPcControl, GetPcUWstav } from './BoardUWmikroDetailPC';
import GetPhoneDetail, { GetPhoneSettings, GetPhoneControl, GetPhoneUWstav } from './BoardUWmikroDetailPhone';
import GetPhoneOTE from './BoardUWmikroOTE';

import { ReactComponent as ButtonPress } from '../images/o-button-svgrepo-com.svg'
import { ReactComponent as IcoSettings } from '../images/settings-svgrepo-com1.svg';
import { ReactComponent as IcoExit } from '../images/cross.svg';

import { ReactComponent as IcoLive } from '../images/eye-svgrepo-com.svg';
import { ReactComponent as IcoReg } from '../images/list.svg';
import { ReactComponent as IcoSet } from '../images/list.svg';
import { ReactComponent as iGraph } from '../images/chart-bar.svg';


const UWmikroDetail = (prop) => {

    const location = useLocation()

    const [resp, setResp] = useState("");
    const [stridac, setStridac] = useState('Alpha ESS');

    const [smenu, setMenu] = useState(1)
    const [phoneCont, setPhoneCont] = useState("")
    const [pcCont, setPcCont] = useState("")

    const [actdevid, setActdevid] = useState("")

    //************************* sirka okna *************************************
    const [wit, setWit] = useState(window.innerWidth)
    const [hei, setHei] = useState(window.innerHeight)
    const handleResize = () => { setWit(window.innerWidth); setHei(window.innerHeight) }

    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    //***************************************************************************

    const getData = () => {

        if (prop?.devid) { // called from BoardUser ***********************
            setActdevid(prop?.devid)
            UserService.getUWmikroDetail(prop?.devid).then(
                (response) => {
                    setResp(response.data)
                    // console.log(response.data)
                })
        }
        else {  // called from BoardUWmikro *********************************

            if (location.device?.devid) {
                setActdevid(location.device?.devid)
                UserService.getUWmikroDetail(location.device.devid).then(
                    (response) => {
                        setResp(response.data)
                        // console.log(response.data)
                    })
            }
        }
    }

    useEffect(() => {
        setTimeout(() => { getData() }, 100);
        const ti = setInterval(() => { getData() }, 5000);
        return () => {
            clearInterval(ti)
        };
    }, []);



    useEffect(() => { //>>>>>>>>>>>>>>>>>>>>>>>>>>>>> NASTAVENI >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
        setStridac(resp.invert)
    }, [resp]);


    const resetUW = () => { set({ fc: 'reboot' }) }
    const scanlan = () => { set({ fc: 'scanlan' }) }
    const setport = (port) => { set({ fc: 'ipport', val: port }) }
    const setip = (param) => { set({ fc: 'ipadr', val: param }) }
    const zakpretok = (param) => { set({ fc: 'zakpretok', val: param }) }

    // const test = (param) => {console.log(param); set({fc: 'test', val: param }) }

    const test = (param) => {
        setTimeout(() => { getData() }, 100);
    }
    const testcontrol = (param) => { { set({ fc: 'testcontrol', val: param }) } }


    const selectOnChange = (value) => { // from select stridac 
        setStridac(value.label)
        set({ fc: 'setstridac', val: value.label })
    }
    const fnObj = {
        resetUW: resetUW,
        setport: setport,
        setip: setip,
        setstridac: selectOnChange,
        scanlan: scanlan,
        testcontrol: testcontrol,
        zakpretok: zakpretok
    }


    const set = (param) => {
        UserService.postUWmikroFunkce(actdevid, param).then(
            (response) => {
                // console.log(response.data) 
            })
    }


    useEffect(() => { //>>>>>>>>>>>>>>>>>>>>>>>>>>>>> NASTAVENI >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        if (smenu == 1) {
            setPhoneCont(
                <div className="w100">
                    <div className="df jc-s ai-c w100" style={{ height: '50px', paddingLeft: '3vw' }}>
                        {resp.site}
                    </div>
                    <div className="df jc-c bg-16 w100" style={{ height: '87%' }}>
                        <AnimacePhone anim={resp.anim} con={resp.connect} onl={resp.online} />
                    </div>

                </div >
            )
            setPcCont(<GetPcDetail resp={resp} />)
        }
        else if (smenu == 2) {
            setPhoneCont(<div className="w100"><GetPhoneDetail resp={resp} /></div>)
            setPcCont(<GetPcDetail resp={resp} />)
        }
        else if (smenu === 3) {
            setPhoneCont(<div className="w100"><GetPhoneSettings resp={resp} stridac={stridac} fn={fnObj} /></div>)
            setPcCont(<GetPcSettings resp={resp} stridac={stridac} fn={fnObj} />)
        }
        else if (smenu === 4) {
            setPhoneCont(<div className="w100"><GetPhoneControl resp={resp} stridac={stridac} fn={fnObj} /></div>)
        }
        else if (smenu === 5) {
            setPhoneCont(<div className="w100x"><GetPhoneOTE devid={actdevid} fn={fnObj} wid='96vw' /></div>)
            setPcCont(
                <div className="df fdir-c pos-r w100">
                    <div className="w100"><GetPhoneOTE devid={actdevid} fn={fnObj} wid='80vw' /></div>
                </div >
            )
        }
    }, [smenu, resp]);


    const clickMenu = (val) => { setMenu(val) }

    const menu = () => {
        return (
            <div className="dfc fdir-r bg-16 w100">
                <div className="df fdir-r bg-15" style={{ width: '96%', height: '90%' }}>

                    <div className="dfc f1 fdir-c bg-15">
                        <BtnSvgMenu ico={IcoLive} hand={() => { clickMenu(1) }} param={''} />
                        <div className="dfc cl-m fs-c3">Přehled</div>
                    </div>
                    <div className="dfc f1 fdir-c bg-15">
                        <BtnSvgMenu ico={IcoReg} hand={() => { clickMenu(2) }} param={''} />
                        <div className="dfc cl-m fs-c3">Detail</div>
                    </div>
                    {/* <div className="dfc f1 fdir-c bg-15">
                        <BtnSvgMenu ico={IcoReg} hand={() => { clickMenu(4) }} param={''} />
                        <div className="dfc cl-m fs-c3">Funkce</div>
                    </div> */}
                    <div className="dfc f1 fdir-c bg-15">
                        <BtnSvgMenu ico={iGraph} hand={() => { clickMenu(5) }} param={''} />
                        <div className="dfc cl-m fs-c3">OTE</div>
                    </div>
                    <div className="dfc f1 fdir-c bg-15">
                        <BtnSvgMenu ico={IcoSettings} hand={() => { clickMenu(3) }} param={''} />
                        <div className="dfc cl-m fs-c3">Nastavení</div>
                    </div>

                </div>
            </div>
        )
    }

    const PCmenu = () => {
        return (
            <div className="dfc fdir-rw100">
                <div className="df fdir-r " style={{ width: '96%' }}>
                    <div className="dfc f10">
                    </div>
                    <div className="dfc fno" style={{ width: '40px' }}>
                        <BtnSvgMenu ico={IcoReg} hand={() => { clickMenu(2) }} param={''} size='sm' brigh={70} />
                    </div>
                    <div className="dfc fno" style={{ width: '40px' }}>
                        <BtnSvgMenu ico={iGraph} hand={() => { clickMenu(5) }} param={''} size='sm' brigh={70} />
                    </div>
                    <div className="dfc fno" style={{ width: '40px' }}>
                        <BtnSvgMenu ico={IcoSettings} hand={() => { clickMenu(3) }} param={''} size='sm' brigh={70} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        wit < 600 ? // phone resolution >>>>>>>>>>>>>>>>

            <div className="df fdir-c pos-r bg-4x" style={{ height: `${hei - 80}px` }}>

                <div className="df bg-16" style={{ height: '90%', overflowY: 'auto', scrollbarWidth: 'none' }}>
                    {phoneCont}
                </div>
                < div className="df w100 pos-a" style={{ height: '10%', bottom: '0' }}>
                    {menu()}
                </div >
            </div >

            : // PC resolution >>>>>>>>>>>>>>>>>>>>>>>>>

            <div className="df jc-c bg-2" style={{ height: `${hei - 90}px` }}>

                <div className="df jc-c bg-2" style={{ width: '1600px', height: '97%', flexWrap: 'wrap' }}>

                    {/* menu */}
                    <div className="df jc-c fdir-c bg-13 w100" style={{ height: '40px' }}>
                        <PCmenu resp={resp} />
                    </div>

                    {/* animace */}
                    <div className="df jc-c f1 bg-16"
                        style={{ minWidth: '500px', width: '100%', height: wit < 1000 ? '37%' : '100%' }}>
                        <div className="w100 pos-r">
                            <div className="df jc-s ai-c w100 pos-a" style={{ height: '40px', paddingLeft: '3vw' }}>
                                {resp.site}
                            </div>

                            <div className="df" style={{ height: wit < 1000 ? '0' : '10%' }}></div>
                            <div className="df jc-c bg-16 w100" style={{ height: wit < 1000 ? '100%' : '70%' }}>
                                <AnimacePhone anim={resp.anim} con={resp.connect} onl={resp.online} />
                            </div>
                        </div >
                    </div>

                    {/* obsah */}
                    <div className="df f1 fdir-c" style={{ minWidth: '500px', maxWidthx: '800px', height: wit < 1000 ? '63%' : '100%' }}>
                        <div className="df f1 bg-14" style={{ overflowY: 'auto', scrollbarWidth: 'thin' }}>
                            <div className="w100" style={{ width: '100%', padding: '5px' }}>
                                {pcCont}
                            </div>
                        </div>
                    </div>

                </div>
            </div >
    );
};
export default UWmikroDetail;

//***********************************************************************************************************************************************************************************************************

const BtnSvgMenu = (pr) => {

    const [press, setPress] = useState(false)

    const cpress = () => {
        if (!press) { pr.hand(pr.param) }
        setPress(true)
    }
    const crelease = () => {
        setPress(false)
    }

    return (
        <div className="dfc" style={{ minHeight: '20px', height: pr.size == 'sm' ? '1.8vh' : '4.5vh', marginLeft: '-3px' }}>

            <div style={{
                position: "relative",
                width: "100%",
                height: "100%",
                border: "none",
                backgroundColor: "transparent",
            }}
                // onClick={() => cpress()}
                onMouseDown={() => cpress()}
                onTouchStart={() => cpress()}

                onMouseUp={() => crelease()}
                onMouseOut={() => crelease()}
                onTouchEnd={() => crelease()}
            >
                <pr.ico
                    className='hov'
                    fill={pr.fill ? pr.fill : ''}
                    style={{
                        position: 'absolute',

                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",

                        height: '100%',
                        width: 'auto',
                        userSelect: 'none',
                        filter: press ?
                            'saturate(100%) invert(58%) sepia(99%) saturate(729%) hue-rotate(359deg) brightness(99%) contrast(101%)'
                            :
                            pr.fill ? '' :
                                `brightness(0) saturate(100%) invert(32%) sepia(80%) saturate(1206%) hue-rotate(178deg) brightness(${pr.brigh ? pr.brigh : 99}%) contrast(108%)`
                    }}
                />
            </div>
        </div>
    )
}