import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";

import "./Board.css";
import { ReactComponent as IcoCheck } from '../images/check-square.svg';
import { ReactComponent as IcoUncheck } from '../images/square.svg';

const BoardAdmin_users = (user) => {
    const [content, setContent] = useState("");
    const [fake, setFake] = useState(0);

    const clickChangeStatus = (user) => {
        if (user.role !== 'admin') {
            let newrole
            if (user.role === 'moderator') {
                newrole = 'user'
            }
            else {
                newrole = 'moderator'
            }
            UserService.getChangeRole(user.username, newrole).then(
                (response) => {
                    setFake(fake + 1)//redraw
                    //window.location.reload();
                }
            )
        }
    }

    useEffect(() => {

        UserService.getAdminBoard_moderators().then(
            (response) => {

                let cont = []
                response.data.forEach((el, index) => {

                    if (el.devices.length === 0) el.devices.push({ 'id': 0, 'site': '' })

                    cont.push(
                        <div className={el.role === 'moderator' ? "radekmoderator" : "radek"}>
                            <div className="f4">{el.username}</div>
                            <div className="f7"></div>
                            <div className="f1"></div>
                            <div className="f1">
                                <div className="btncon" onClick={() => { clickChangeStatus(el) }}>
                                    {el.role === 'admin' ? <div >Admin</div> :
                                        el.role === 'moderator' ? <div ><IcoCheck className="icocon" /></div> :
                                            <div ><IcoUncheck className="icocon" /></div>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                });

                setContent(cont);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setContent(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        );

    }, [fake]);

    return (

        <div style={{
            maxWidth: '100vw',
            height: '94vh',
            background: '#E9ECEF',
            overflowY: 'auto'
        }}>
            {content}
        </div>
    );
};

export default BoardAdmin_users;
