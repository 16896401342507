import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";

import Chart from "react-apexcharts";
import Datum from './Datepicker.js';
import ChartService from "../services/chart.service";

import './Charts.css';
import "./Board.css";

import fakedata from "./Fakedata.js";


const Charts = (props) => {

    const phoneWidth = 600;

    const [maxY, setMaxY] = useState(2)
    const [toolbarshow, setToolbarShow] = useState(false)
    const [act, setAct] = useState({ pv: 0, load: 0, bat: 0, grid: 0, feed: 0, auto: 0, surplus: 0 })

    const [positionLeft, setPositionLeft] = useState(100)



    let chartOptions = {

        chart: {
            id: 'Data',
            type: 'area',
            height: 350,
            toolbar: {
                show: toolbarshow,
                offsetX: 0,
                offsetY: -15,
            },
            zoom: {
                autoScaleYaxis: true
            },
            //events: {

                // mouseMove: function (event, chartContext, config) {
                //     var tooltip = chartContext.el.querySelector('.apexcharts-tooltip');

                //     var pointsArray = config.globals.pointsArray;
                //     var seriesIndex = config.seriesIndex;
                //     var dataPointIndex = config.dataPointIndex === -1 ? 0 : config.dataPointIndex;

                //     if (seriesIndex !== -1) {
                //         var position = pointsArray[seriesIndex][dataPointIndex];

                //         if (grafDim[0] > phoneWidth) {


                //             if (position[0] < config.globals.gridWidth / 5) {
                //                 setPositionLeft(position[0] + 150);
                                
                //             }
                //             else {
                //                 setPositionLeft(position[0] - 200);
                //             }

                //             tooltip.style.left = positionLeft + 'px'
                //             console.log(`${positionLeft}px`)

                //         }
                //         else {
                            //tooltip.fixed.offsetX = '500px'
                            //console.log(position)

                        // tooltip.style.top = '0px'

                        // if (position[0] < config.globals.gridWidth / 3) {
                        //     tooltip.style.left = config.globals.gridWidth - tooltip.style.width - 35 + 'px';
                        // }
                        // else {
                        //     tooltip.style.left = '10px';
                        // }
                        // console.log(tooltip.style.left)
            //            }
            //        }
            //    }
            //}
        },
        legend: {// zap-vyp grafy
            //position: 'top',
            // showForZeroSeries: false,
            onItemClick: { toggleDataSeries: true }
        },
        dataLabels: {// text na grafech
            enabled: false
        },
        stroke: {// krivka
            curve: 'straight',//'smooth',
            width: '1'
        },
        markers: {
            size: 0,
            // style: 'hollow',
        },
        xaxis: {
            type: 'category',
            tickAmount: 11, //pocet popisku pod x
        },
        yaxis: {
            labels: {
                formatter: function (val, index) {
                    return val.toFixed(2) + ' kW';
                }
            },
            tickAmount: 4,
            max: maxY,
            min: 0,
            // forceNiceScale: true
        },
        colors: ['#3DAF2C', '#409EFF', '#E6A23C', '#FF4A55', '#C09852', '#666666', '#31AECA'],


        tooltip: {
            enabled: true,
            enabledOnSeries: [0, 1, 2, 3, 4, 5, 6],
            shared: true,
            intersect: false,
            // followCursor: true,

            fixed: {
                enabled: true,
                offsetY: 0,
                position: 'topRight',
            },

            y:
                [
                    { formatter: function (val, index) { if (val != null) return (val * (100 / maxY)).toFixed(2) + ' %'; } },
                    { formatter: function (val, index) { if (val != null) return val.toFixed(2) + ' kW'; } },
                    { formatter: function (val, index) { if (val != null) return val.toFixed(2) + ' kW'; } },
                    { formatter: function (val, index) { if (val != null) return val.toFixed(2) + ' kW'; } },
                    { formatter: function (val, index) { if (val != null) return val.toFixed(2) + ' kW'; } },
                    { formatter: function (val, index) { if (val != null) return val.toFixed(2) + ' kW'; } },
                    { formatter: function (val, index) { if (val != null) return val.toFixed(2) + ' kW'; } },
                ],
        },


        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100]
            }
        },
    }


    let dataset = [[], [], [], [], [], [], []]
    let dbid = props.location.dbid

    const getData = (y, m, d) => {
        // console.log('dbid',dbid)

        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        ChartService.getData(dbid, y, m, d).then(
            (response) => {

        // console.log(fakedata.fakedata)
        //let response = fakedata.fakedata

        //data pro PV *****************************************************

        // console.log(response.data)
        let PVsuma = 0
        for (let i = 1; i <= response.data.length - 1; i++) {
            PVsuma += response.data[i].pv
        }
        setPVtoday(((PVsuma / 12)).toFixed(2))
        //***************** */

        let LOADsuma = 0
        for (let i = 1; i <= response.data.length - 1; i++) {
            LOADsuma += response.data[i].load
        }
        setLOADtoday(((LOADsuma / 12)).toFixed(2))
        //*************** */

        let FEEDsuma = 0
        for (let i = 1; i <= response.data.length - 1; i++) {
            FEEDsuma += response.data[i].feed
        }
        setFEEDtoday((FEEDsuma / 12).toFixed(2))
        //*************** */

        let GRIDsuma = 0
        for (let i = 1; i <= response.data.length - 1; i++) {
            GRIDsuma += response.data[i].grid
        }
        setGRIDtoday(((GRIDsuma / 12)).toFixed(2))
        //*************** */

        let SURPLUSsuma = 0
        for (let i = 1; i <= response.data.length - 1; i++) {
            SURPLUSsuma += response.data[i].surplus
        }
        setSURPLUStoday(((SURPLUSsuma / 12)).toFixed(2))


        //data pro  main graf ***********************************************

        dataset = [[], [], [], [], [], [], []]
        let cn = 0
        let maxval = 1

        //vytvori model s nulovejma hodnotama
        for (let i = 0; i <= 1440; i += 5) {
            let strx = Math.floor(i / 60).toString() + ':' + ((i % 60).toString()).padStart(2, '0')
            dataset[0].push({ x: strx, y: 0 })
            dataset[1].push({ x: strx, y: 0 })
            dataset[2].push({ x: strx, y: 0 })
            dataset[3].push({ x: strx, y: 0 })
            dataset[4].push({ x: strx, y: 0 })
            dataset[5].push({ x: strx, y: 0 })
            dataset[6].push({ x: strx, y: 0 })
        }

        //najde nejvyssi hodnotu pro axisY
        for (let j = 0; j < response.data.length; j++) {

            let errval = 4

            if (response.data[j].pv > errval) errval = response.data[j].pv
            if (response.data[j].load > errval) errval = response.data[j].load
            if (response.data[j].grid > errval) errval = response.data[j].grid
            if (response.data[j].feed > errval) errval = response.data[j].feed
            if (response.data[j].auto > errval) errval = response.data[j].auto
            if (response.data[j].surplus > errval) errval = response.data[j].surplus

            if (response.data[j].pv > maxval) maxval = response.data[j].pv
            if (response.data[j].load > maxval) maxval = response.data[j].load
            if (response.data[j].grid > maxval) maxval = response.data[j].grid
            if (response.data[j].feed > maxval) maxval = response.data[j].feed
            if (response.data[j].auto > maxval) maxval = response.data[j].auto
            if (response.data[j].surplus > maxval) maxval = response.data[j].surplus
        }
        maxval = Math.ceil(maxval)
        let deleniBaterie = 100 / maxval

        // console.log(response.data.length)

        //naplni model response hodnotama podle shody time
        for (let i = 0; i <= 1440; i += 5) {
            let h = Math.floor(i / 60)
            let m = (i % 60)

            for (let j = 0; j < response.data.length; j++) {

                if (d === response.data[j].d && h === response.data[j].h && m === response.data[j].mi) {

                    dataset[0][cn].y = response.data[j].pv
                    dataset[1][cn].y = response.data[j].load
                    dataset[2][cn].y = (response.data[j].bat / deleniBaterie)
                    dataset[3][cn].y = response.data[j].grid
                    dataset[4][cn].y = response.data[j].feed
                    dataset[5][cn].y = response.data[j].auto
                    dataset[6][cn].y = response.data[j].surplus

                    j = response.data.length
                }
            }
            cn++
        }
        //opakovani posledniho stavu pro pulnoc, ktera uz je jiny den
        dataset[0][288].y = dataset[0][287].y
        dataset[1][288].y = dataset[1][287].y
        dataset[2][288].y = dataset[2][287].y
        dataset[3][288].y = dataset[3][287].y
        dataset[4][288].y = dataset[4][287].y
        dataset[5][288].y = dataset[5][287].y
        dataset[6][288].y = dataset[6][287].y

        if (response.data.length > 0) setAct({ ...response.data[response.data.length - 1] })


        if (grafDim[0] > phoneWidth) {
            setSeries([
                { name: 'Battery', data: dataset[2] },
                { name: 'Load', data: dataset[1] },
                { name: 'PV', data: dataset[0] },
                { name: 'Feed-in', data: dataset[4] },
                { name: 'Grid Compsuption', data: dataset[3] },
                { name: 'Car charging', data: dataset[5] },
                { name: 'Surplus energy', data: dataset[6] }
            ])
        }
        //phone
        if (grafDim[0] <= phoneWidth) {
            setSeries([
                { name: 'Batt', data: dataset[2] },
                { name: 'Load', data: dataset[1] },
                { name: 'PV', data: dataset[0] },
                { name: 'Feed', data: dataset[4] },
                { name: 'Grid', data: dataset[3] },
                { name: 'Car', data: dataset[5] },
                { name: 'Sur', data: dataset[6] }
            ])
        }

        setMaxY(maxval)
        }
        ) //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
    }


    const [series, setSeries] = useState([{}])
    const [options, setOptions] = useState(chartOptions)

    const [PVtoday, setPVtoday] = useState(0)
    const [LOADtoday, setLOADtoday] = useState(0)
    const [FEEDtoday, setFEEDtoday] = useState(0)
    const [GRIDtoday, setGRIDtoday] = useState(0)
    const [SURPLUStoday, setSURPLUStoday] = useState(0)


    //** dimension ********************************

    const getDivchartSize = () => {
        // let element = document.getElementById('divchart');
        // let positionInfo = element.getBoundingClientRect();
        // return ([positionInfo.width, positionInfo.height])

        return ([document.documentElement.clientWidth, document.documentElement.clientHeight])
    }

    const [grafDim, setGrafDim] = useState(getDivchartSize());



    useEffect(() => {
        //desktop
        if (grafDim[0] > phoneWidth) {
            setToolbarShow(true)
        }
        //phone
        if (grafDim[0] <= phoneWidth) {
            setToolbarShow(false)
        }

        setOptions(chartOptions)
    }, [grafDim, maxY])


    useEffect(() => {
        const resizeListener = () => {
            setGrafDim(getDivchartSize())
        };
        window.addEventListener('resize', resizeListener);

        return () => { window.removeEventListener('resize', resizeListener); }
    }, [])

    //***************************


    const gray = { background: 'rgb(200,200,200)' };
    const lgray = { background: 'rgb(220,220,220)' };
    const vlgray = { background: 'rgb(240,240,240)' };


    const font1 = { fontSize: '0.7em', flex: '0.5' };
    const font2 = { fontSize: '1em', flex: '1' };

    let fsize = document.documentElement.clientWidth / 75
    if (fsize > 16) fsize = 16.5

    const stylgraf = { flex: '8', display: 'flex' };
    const flexCentV = { display: 'flex', alignItems: 'center' };
    const flexPodsebe = { display: 'flex', flexDirection: 'column', alignItems: 'center' };


    if (isNaN(act.surplus)) act.surplus = 0//???????????????????
    if (isNaN(SURPLUStoday)) setSURPLUStoday(0)


    //***************************************************

    let content = <></>

    if (grafDim[0] >= phoneWidth) {

        // **** Desktop ******************************************************************************* 

        content =

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: '95vh',
                justifyContent: 'center',
            }}>


                <div style={{
                    display: 'flex', height: '5vw', maxHeight: '50px',
                    alignItems: 'center',
                    backgroundColor: '#F0F0F0'
                }}
                >
                    <div style={{
                        flex: '1', display: 'flex',
                        height: '100%', width: '50%',
                        alignItems: 'center',
                    }}>

                        <div style={{
                            display: 'flex',
                            fontSize: '1.01em',
                            whiteSpace: 'nowrap',
                            margin: '0px 20px 0px 10px'
                        }}>
                            DATA MONITORING
                        </div>

                        <div className='link_cont link_under' >
                            <Link className='linkact' to={{ pathname: '/charts', dbid }}>
                                Power Diagram
                            </Link>
                        </div>

                        <div className='link_cont' >
                            <Link to={{ pathname: '/charts_stat', dbid }}>
                                Statistical Diagram
                            </Link>
                        </div>

                    </div>

                    <div style={{ flex: '1', maxWidth: '202px', zIndex: '999' }}>
                        <Datum selectdate={getData} />
                    </div>

                </div>

                <div style={{ flex: '0.1', display: 'flex', backgroundColor: '#F0F0F0', borderTop: '2px solid #DDDDDD', marginTop: '-2px' }}></div>

                {/* ********************************* */}

                <div className='media_f_direction' style={{ flex: '3' }}>


                    <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                        <div className='popisNad' style={{ ...gray }}>PV</div>
                        <div style={{ ...stylgraf, ...lgray, ...flexPodsebe }}>

                            <div style={{ flex: '1.5' }}></div>
                            <div className='popisL' style={{ color: '#E6A23C' }}> {act.pv.toFixed(2)}</div>
                            <div style={{ ...font2, color: '#E6A23C' }}>kWh</div>
                            <div style={{ flex: '1.5' }}></div>
                            <div style={{ ...font1 }}>Today's Generation</div>
                            <div style={{ ...font2 }}> {PVtoday + ' kWh'}</div>
                        </div>

                    </div>

                    <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                        <div className='popisNad' style={{ ...lgray }}>LOAD </div>
                        <div style={{ ...stylgraf, ...vlgray, ...flexPodsebe }}>

                            <div style={{ flex: '1.5' }}></div>
                            <div className='popisL' style={{ color: '#409EFF' }}> {act.load.toFixed(2)}</div>
                            <div style={{ ...font2, color: '#409EFF' }}>kWh</div>
                            <div style={{ flex: '1.5' }}></div>
                            <div style={{ ...font1 }}>Today's Consumption</div>
                            <div style={{ ...font2 }}> {LOADtoday + ' kWh'}</div>
                        </div>
                    </div>

                    <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                        <div className='popisNad' style={{ ...gray }}>BATTERY</div>
                        <div style={{ ...stylgraf, ...lgray, ...flexPodsebe }}>

                            <div style={{ flex: '1.5' }}></div>
                            <div className='popisL' style={{ color: '#3DAF2C' }}> {act.bat.toFixed(2)}</div>
                            <div style={{ ...font2, color: '#3DAF2C' }}>%</div>
                            <div style={{ flex: '1.5' }}></div>
                            <div style={{ ...font1, }}>State of charge</div>
                            <div style={{ ...font2, color: 'transparent' }}>*</div>
                        </div>

                    </div>

                    <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                        <div className='popisNad' style={{ ...lgray }}>FEED-IN </div>
                        <div style={{ ...stylgraf, ...vlgray, ...flexPodsebe }}>

                            <div style={{ flex: '1.5' }}></div>
                            <div className='popisL' style={{ color: '#FF4A55' }}> {act.feed.toFixed(2)}</div>
                            <div style={{ ...font2, color: '#FF4A55' }}>kWh</div>
                            <div style={{ flex: '1.5' }}></div>
                            <div style={{ ...font1 }}>Today's Feed in</div>
                            <div style={{ ...font2 }}> {FEEDtoday + ' kWh'}</div>
                        </div>

                    </div>
                    <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                        <div className='popisNad' style={{ ...gray }}>GRID CONSUMPTION</div>
                        <div style={{ ...stylgraf, ...lgray, ...flexPodsebe }}>

                            <div style={{ flex: '1.5' }}></div>
                            <div className='popisL' style={{ color: '#C09852' }}> {act.grid.toFixed(2)}</div>
                            <div style={{ ...font2, color: '#C09852' }}>kWh</div>
                            <div style={{ flex: '1.5' }}></div>
                            <div style={{ ...font1 }}>Today's Grid C.</div>
                            <div style={{ ...font2 }}> {GRIDtoday + ' kWh'}</div>
                        </div>
                    </div>

                    <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                        <div className='popisNad' style={{ ...lgray }}>SURPLUS + DELIVERY ENERGY </div>
                        <div style={{ ...stylgraf, ...vlgray, ...flexPodsebe }}>

                            <div style={{ flex: '1.5' }}></div>
                            <div className='popisL' style={{ color: '#31AECA' }}> {act.surplus.toFixed(2)}</div>
                            <div style={{ ...font2, color: '#31AECA' }}>kWh</div>
                            <div style={{ flex: '1.5' }}></div>
                            <div style={{ ...font1 }}>Today's Surplus + DE</div>
                            <div style={{ ...font2 }}> {SURPLUStoday + ' kWh'}</div>
                        </div>

                    </div>
                </div>

                {/* *********************************************** */}

                <div style={{ flex: '0.5', display: 'flex', backgroundColor: 'white' }}></div>
                <div id='divchart' style={{ flex: '7', display: 'flex', height: '100px', backgroundColor: 'white' }}>

                    <Chart
                        options={options}
                        series={series}
                        type="area"
                        width={grafDim[0] - 25}
                        height={grafDim[1] * 0.55}
                    />

                </div>
                <div style={{ flex: '0.1', display: 'flex', backgroundColor: 'white' }}></div>
                <div style={{ flex: '0.3', display: 'flex', backgroundColor: 'lightgray' }}></div>

            </div>
    }
    else {

        // **** Phone ******************************************************************************* 

        content =

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: '95vh',
            }}>

                <div style={{
                    display: 'flex', height: '15vw', maxHeight: '50px',
                    alignItems: 'center',
                    backgroundColor: '#F0F0F0'
                }}
                >
                    <div style={{
                        flex: '1', display: 'flex',
                        height: '100%', width: '50%',
                        alignItems: 'center',
                    }}>

                        <div style={{
                            flex: '2',
                            display: 'flex',
                            fontSize: '1.01em',
                            whiteSpace: 'nowrap',
                            margin: '0px 20px 0px 10px'
                        }}>
                            DATA MONITORING
                        </div>

                        <div style={{ flex: '1' }} className='link_cont link_under' >
                            <Link className='linkact' to={{ pathname: '/charts', dbid }}>
                                Power
                            </Link>
                        </div>

                        <div style={{ flex: '1' }} className='link_cont' >
                            <Link to={{ pathname: '/charts_stat', dbid }}>
                                Statistics
                            </Link>
                        </div>

                        <div style={{ flex: '0.5' }} className='link_cont' ></div>

                    </div>

                </div>


                < div style={{ flex: '0.1', display: 'flex' }}> </div>{/* mezera */}

                {/* ********************************* */}

                < div style={{ flex: '4', display: 'flex', flexDirection: 'column' }}>

                    <div style={{ flex: '1', display: 'flex', flexDirection: 'row' }}>


                        <div style={{ flex: '1', display: 'flex', flexDirection: 'column', alignItems: 'center', ...lgray }}>
                            <div style={{ flex: '1', fontSize: '4vw' }}>PV</div>
                            <div style={{ flex: '1', fontSize: '8vw', marginTop: '-10px', marginBottom: '-10px', color: '#E6A23C' }}> {act.pv.toFixed(2)}</div>
                            <div style={{ flex: '1', fontSize: '3vw', color: '#E6A23C' }}>kWh</div>
                            <div style={{ flex: '0.5', }}> {PVtoday + ' kWh'}</div>
                        </div>

                        <div style={{ flex: '1', display: 'flex', flexDirection: 'column', alignItems: 'center', ...vlgray }}>
                            <div style={{ flex: '1', fontSize: '4vw' }}>LOAD</div>
                            <div style={{ flex: '1', fontSize: '8vw', marginTop: '-10px', marginBottom: '-10px', color: '#409EFF' }}> {act.load.toFixed(2)}</div>
                            <div style={{ flex: '1', fontSize: '3vw', color: '#409EFF' }}>kWh</div>
                            <div style={{ flex: '0.5', }}> {LOADtoday + ' kWh'}</div>
                        </div>

                        <div style={{ flex: '1', display: 'flex', flexDirection: 'column', alignItems: 'center', ...lgray }}>
                            <div style={{ flex: '1', fontSize: '4vw' }}>BATTERY</div>
                            <div style={{ flex: '1', fontSize: '8vw', marginTop: '-10px', marginBottom: '-10px', color: '#3DAF2C' }}> {act.bat.toFixed(2)}</div>
                            <div style={{ flex: '1', fontSize: '3vw', color: '#3DAF2C' }}>%</div>
                            <div style={{ flex: '0.5', color: 'transparent' }}>*</div>
                        </div>

                    </div>

                    <div style={{ flex: '0.05', minHeight: '4px' }}></div>{/* mezera */}

                    <div style={{ flex: '1', display: 'flex', flexDirection: 'row' }}>

                        <div style={{ flex: '1', display: 'flex', flexDirection: 'column', alignItems: 'center', ...vlgray }}>
                            <div style={{ flex: '1', fontSize: '4vw' }}>FEED-IN</div>
                            <div style={{ flex: '1', fontSize: '8vw', marginTop: '-10px', marginBottom: '-10px', color: '#FF4A55' }}> {act.feed.toFixed(2)}</div>
                            <div style={{ flex: '1', fontSize: '3vw', color: '#FF4A55' }}>kWh</div>
                            <div style={{ flex: '0.5', }}> {FEEDtoday + ' kWh'}</div>
                        </div>
                        <div style={{ flex: '1', display: 'flex', flexDirection: 'column', alignItems: 'center', ...lgray }}>
                            <div style={{ flex: '1', fontSize: '3.1vw' }}>GRID CONSUMPTION</div>
                            <div style={{ flex: '1', fontSize: '8vw', marginTop: '-10px', marginBottom: '-10px', color: '#C09852' }}> {act.grid.toFixed(2)}</div>
                            <div style={{ flex: '1', fontSize: '3vw', color: '#C09852' }}>kWh</div>
                            <div style={{ flex: '0.5', }}> {GRIDtoday + ' kWh'}</div>
                        </div>
                        <div style={{ flex: '1', display: 'flex', flexDirection: 'column', alignItems: 'center', ...vlgray }}>
                            <div style={{ flex: '1', fontSize: '3vw' }}>SURPLUS + DELIVERY ENERGY</div>
                            <div style={{ flex: '1', fontSize: '8vw', marginTop: '-10px', marginBottom: '-10px', color: '#31AECA' }}> {act.surplus.toFixed(2)}</div>
                            <div style={{ flex: '1', fontSize: '3vw', color: '#31AECA' }}>kWh</div>
                            <div style={{ flex: '0.5', }}> {SURPLUStoday + ' kWh'}</div>
                        </div>

                    </div>

                </div >

                < div style={{ flex: '0.1', display: 'flex' }}> </div>{/* mezera */}


                <div style={{ flex: '0.8', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>

                    <div style={{ display: 'flex', minHeight: '30px' }}>
                        <Datum selectdate={getData} />
                    </div>

                </div>
                <div style={{ display: 'flex', backgroundColor: '#C0C0C0', height: '1px' }}></div>

                {/* *********************************************** */}

                <div style={{ flex: '0.5', display: 'flex', backgroundColor: 'white' }}></div>
                <div id='divchart' style={{ flex: '7', display: 'flex', height: '100px', backgroundColor: 'white' }}>

                    <Chart
                        options={options}
                        series={series}
                        type="area"
                        width={grafDim[0] - 20}
                        height={grafDim[1] * 0.48}
                    />

                </div>
                <div style={{ flex: '0.1', display: 'flex', backgroundColor: 'white' }}></div>
                <div style={{ flex: '0.3', display: 'flex', backgroundColor: 'lightgray' }}></div>

            </div >
    }

    return (
        <>
            {content}
        </>
    );
}

export default Charts;















