import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";

import OTEgraf from './ote-graf.js'

import "./Board.css";

import { ReactComponent as IcoRight } from '../images/angle-right.svg';
import { ReactComponent as IcoBut } from '../images/o-button-svgrepo-com.svg';
import { ReactComponent as ButtonPress } from '../images/o-button-svgrepo-com.svg'
import { ReactComponent as IcoBon } from '../images/switchon.svg';
import { ReactComponent as IcoBoff } from '../images/switchoff.svg';


const GetPhoneOTE = (prop) => { //***************************************************************************************************

    const [otedata, setOtedata] = useState()
    const [otezitra, setOtezitra] = useState([])
    const [btnPretAct, setBtnPretAct] = useState(false)
    const [btnCeny, setbtnCeny] = useState(1)

    useEffect(() => {
        UserService.getOTEdata(prop?.devid)
            .then(
                (response) => {
                    // console.log(response.data)
                    setOtedata(response.data.dnes)
                    setOtezitra(response.data.zitra)
                    setBtnPretAct(response.data.zakPretok)
                })
    }, []);


    const clickZakPretok = (val) => {
        setBtnPretAct(val)
        prop.fn.zakpretok(val)
    }


    let cont = []

    if (otedata !== undefined) {
        cont.push(<OTEgraf series={btnCeny == 1 ? otedata : otezitra} />)
    }
    else { cont.push(<></>) }

    return (
        <>
            < Radiobutton act={btnCeny} callb={(val) => { setbtnCeny(val) }} />

            <div className="dfc w100" style={{height: '1vh'}}></div>

            <div className="dfc jcc">
                <div className="gblok" style={{ display: 'flex', height: '32vh', width: prop.wid }}>
                    {cont}
                </div>
            </div>

            <div className="dfc jcs" style={{height: '10vh' }}></div>

            < RadekBtnOO t1='Zákaz přetoků při záporných cenách' act={btnPretAct} callb={(val) => { clickZakPretok(val) }} />
        </>
    )
}
export default GetPhoneOTE;


const Radiobut = (pr) => {

    return (
        <div className="df w100">
            <div className="df jce aic f3 fs25" style={{fontSize:'1em'}}>
                {pr.txt}&nbsp;&nbsp;
            </div>
            <div className="df f1 hov" style={{ position: 'relative', minWidth: '20px' }} onClick={() => pr.callb(pr.pos)}>
                <svg width="100%" height="100%">
                    <circle cx="50%" cy="50%" r="0.8vh" stroke="#999" strokeWidth="1" fill='#fff'
                    />
                </svg>
                <svg width="100%" height="100%" style={{ position: 'absolute', margin: '0 auto' }} >
                    <circle cx="50%" cy="50%" r="0.6vh" stroke="#444" strokeWidth="0"
                        fill={pr.act == pr.pos ? '#777' : '#fff'}
                    />
                </svg>
            </div>
        </div>
    )
}


export const Radiobutton = (pr) => {

    return (
        <>
            <div className="df jce w100" style={{ height: '8vh'}}>

                {/* <div className="dfc f15 fs3" style={{ minWidth: '40pxq' }}>
                Zohlednit poplatky za distribuci
            </div> */}

                <div className="df f15 h100">

                    <div className="df f8">
                        <Radiobut txt='Ceny dnes' act={pr.act} pos={1} callb={pr.callb} />
                    </div>

                    <div className="df f2"> </div>

                    <div className="df f8">
                        <Radiobut txt='Ceny zítra' act={pr.act} pos={2} callb={pr.callb} />
                    </div>

                    <div className="df f4"> </div>
                </div>

            </div>
            {/* <div className="dfc f15" style={{ minHeight: '40px' }}></div> */}
        </>
    )
}


const RadekBtnOO = (pr) => {

    return (
        <>
            <div className="df bg-15" style={{ minHeight: '70px' }}>
                <div className="dfc f1" style={{ width: '2%' }}></div>
                <div className="dfc f12 jcs fs-c4" >{pr.t1}</div>
                <div className="dfc f5 jcs" ></div>
                <div className="dfc f3 jcs fs-c4" >
                    {
                        pr.act ?
                            <BtnSvg ico={IcoBon} fill='#007BD2' hand={() => pr.callb(!pr.act)} />
                            :
                            <BtnSvg ico={IcoBoff} fill='#888' hand={() => pr.callb(!pr.act)} />
                    }
                </div>

                <div className="dfc f1" style={{ width: '2%' }}></div>
            </div >
            <hr className="cara1"></hr>
        </>
    )
}


const BtnSvg = (pr) => {

    const [press, setPress] = useState(false)

    const cpress = () => {
        if (!press) { pr.hand(pr.param) }
        setPress(true)
    }
    const crelease = () => {
        setPress(false)
    }

    return (
        <div className="dfc" style={{ height: '4vh', marginLeft: '-3px' }}>

            <div style={{
                position: "relative",
                width: "100%",
                height: "80%",
                border: "none",
                backgroundColor: "transparent",
            }}
                // onClick={() => cpress()}
                onMouseDown={() => cpress()}
                onTouchStart={() => cpress()}

                onMouseUp={() => crelease()}
                onMouseOut={() => crelease()}
                onTouchEnd={() => crelease()}
            >
                <pr.ico
                    className='hov'
                    fill={pr.fill ? pr.fill : ''}
                    style={{
                        position: 'absolute',
                        transform: pr.pos === 'left' ? 'translate(-150%)' : 'translate(0%)',

                        // top: "50%",
                        // left: "50%",
                        // transform: "translate(-50%, -50%)",

                        height: '100%',
                        width: 'auto',
                        userSelect: 'none',
                        // filter: press ?
                        //     'saturate(100%) invert(58%) sepia(99%) saturate(729%) hue-rotate(359deg) brightness(99%) contrast(101%)'
                        //     :
                        //     pr.fill ? '' : 'brightness(0) saturate(100%) invert(32%) sepia(80%) saturate(1206%) hue-rotate(178deg) brightness(99%) contrast(108%)'
                    }}
                />
            </div>
        </div>
    )
}