import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";

const BoardAdmin = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    UserService.getAdminBoard_ug().then(
      (response) => {


        let cont = []

        //    cont.push(<h6>Seznam UNI:WATTŮ dostupných pro uživatele: {response.data[1]}</h6>) 

            for (let i = 0; i < response.data.length; i++) {
                cont.push(
                <div className="radek">{response.data[i].username}</div>)
            }


        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>{content}</h3>
      </header>
    </div>
  );
};

export default BoardAdmin;
