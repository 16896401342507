const eventBusJS = require('js-event-bus')();

const eventBJS = {

  on(event, callback) { eventBusJS.on(event, callback) },

  emit(event, data) { eventBusJS.emit(event, data); },

  remove(event) { eventBusJS.die(event); },
};

export default eventBJS;