
//** https */

// const serveradr = 'https://uniwatt.cloud'
// const port = ''


//** http 8081 */

// const serveradr = 'http://uniwatt.cloud'
// const port = ':8081'


/** http 80 */

const serveradr = 'http://uniwatt.cloud'
const port = ''


module.exports = {
    serveradr: serveradr,
    port: port
};
