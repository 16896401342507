import gl from "../global_var.js";
import EventBJS from "../common/EventBusJS.js";

const trace = {

    switchApp(appID) {

        gl.app = { ...gl.appStruct[appID] }

        if (gl.app.type === 'fve-vyroba') { gl.act = { ...gl.app } }
        if (gl.app.type === 'menu') { gl.act = { ...gl.app.items[0] } }

        // console.log(gl.app)
        // console.log(gl.act)

        EventBJS.emit("refMain");

    },

};

export default trace;