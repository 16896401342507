import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";

import "./Board.css";
import { ReactComponent as IcoPlus } from '../images/plus-square.svg';
import { ReactComponent as IcoMinus } from '../images/trash-alt.svg';
import { unstable_renderSubtreeIntoContainer } from "react-dom";


const BoardAdmin_users = (user) => {
    const [content, setContent] = useState("");
    const [selUser, setSelUser] = useState("");
    const [u_d, setUD] = useState(0); //jestli stranka users nebo devices


    const clickDel = (user, dev) => {
        UserService.getDeleteUser(user, dev).then(
            (response) => {
                window.location.reload();
            }
        )
    }

    const clickPlus = (user) => {
        setSelUser(user)
        setUD(1)
    }

    const clickAdd = (user, dev) => {

        UserService.getAddDeviceToUser(user, dev).then(
            (response) => {
                setUD(0)
            }
        )
    }

    //************************* sirka okna *************************************
    const [wit, setWit] = useState(window.innerWidth)
    const handleResize = () => { setWit(window.innerWidth) }
    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const showWindowWidth = () => {
        return (
            <>
                <div className="radek">
                    <div className="f4">{wit}</div>
                </div>
            </>
        )
    }
    //***************************************************************************


    useEffect(() => {

        if (u_d === 0) { //stranka users
            UserService.getAdminBoard_users().then(
                (response) => {

                    let cont = []

                    // cont.push(showWindowWidth())

                    response.data.forEach((el, id) => {

                        if (el.devices.length === 0) el.devices.push({ 'id': 0, 'site': '' })

                        cont.push(
                            <div className="radek" key={id}>
                                <div className="f4">{el.username}</div>
                                <div className="f7">{el.devices[0].site}</div>
                                <div className="f1">
                                    <div className="btncon" onClick={() => { clickDel(el.username, 0) }}>
                                        <div ><IcoMinus className="icocon" /></div>
                                    </div>
                                </div>
                                <div className="f1">
                                    <div className="btncon" onClick={() => { clickPlus(el.username) }}>
                                        <div ><IcoPlus className="icocon" /></div>
                                    </div>
                                </div>
                            </div>
                        )
                        if (el.devices.length > 1) {
                            for (let j = 1; j < el.devices.length; j++) {
                                cont.push(
                                    <div className="radekclear" key={1000 * id + j}>
                                        <div className="f4"></div>
                                        <div className="f7">{el.devices[j].site}</div>
                                        <div className="f1">
                                            <div className="btncon" onClick={() => { clickDel(el.username, j) }}>
                                                <div ><IcoMinus className="icocon" /></div>
                                            </div>
                                        </div>
                                        <div className="f1"></div>
                                    </div>
                                )
                            }
                        }
                    });

                    setContent(cont);
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setContent(_content);

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                }
            );

        } else { //stranka devices

            UserService.getAdminBoard_all().then(
                (response) => {
                    // console.log('getAdminBoard_users - 1',response.data)//seznam devices to add

                    let actUsername = ''
                    if (user.user !== undefined) { actUsername = user.user.username }

                    let cont = []
                    response.data.forEach((el, index) => {

                        cont.push(
                            <div className="radek">
                                <div className="f4">{el.site}</div>
                                <div className="f4"></div>
                                <div className="f1"></div>
                                <div className="f1">
                                    <div className="btncon" onClick={() => { clickAdd(selUser, el.id) }}>
                                        <div ><IcoPlus className="icocon" /></div>
                                    </div>
                                </div>
                            </div>
                        )
                    });

                    setContent(cont)

                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setContent(_content);

                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        }
    }, [u_d]);

    return (
        <div style={{
            maxWidth: '100vw',
            height: '94vh',
            background: '#E9ECEF',
            overflowY: 'auto'
        }}>
            {content}
        </div>

    );
};

export default BoardAdmin_users;
