// import React from "react";
// import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ReactDOM from 'react-dom/client';

// ReactDOM.render(
//   <BrowserRouter>
//   <App />
//   </BrowserRouter>, document.getElementById("root"));

//


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
  <App />
  </BrowserRouter>
  ); 
  
  
  
  
  serviceWorker.unregister();