import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import UserService from "../services/user.service.js";
import EventBus from "../common/EventBus.js";

import { ReactComponent as IcoCon } from '../images/sign-out-alt.svg';
import { ReactComponent as IcoGraf } from '../images/chart-bar.svg';

import "./Board.css";


const RTUdata = (prop) => {

    const location = useLocation()

    const [texty, setTexty] = useState("");
    const [content, setContent] = useState("");

    const [resp, setResp] = useState("");


    const [phone, setPhone] = useState(window.innerWidth < 680 ? true : false)
    const handleResize = () => {
        if (window.innerWidth < 680) setPhone(true)
        else setPhone(false)
    }
    React.useEffect(() => {
        window.addEventListener("resize", handleResize, false);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    useEffect(() => {
        setTimeout(() => {
            UserService.getRTUdata(location?.id).then(
                (response) => {
                    setResp(response.data)
                    console.log(response.data)
                })
        }, 100);

        const ti = setInterval(() => {
            UserService.getRTUdata(location?.id).then(
                (response) => {
                    // setResp(response.data)
                })
        }, 5000);

        return () => {
            clearInterval(ti)
        };
    }, []);



    useEffect(() => {

        const st4 = {
            height: '100%',
            fontSize: 'clamp(0.5em, 1.3vw, 1em)'
        }


        let cont = []

        let ar = Object.keys(resp)
        console.log(ar)

        for (let i = 0; i < ar.length; i++) {

            let nadpis
            if (ar[i] === 'oVN') { nadpis = 'Vysoké napětí' }
            else if (ar[i] === 'o50zam') { nadpis = '2 x 50 kWp - zámečnická dílna' }
            else if (ar[i] === 'o50vs') { nadpis = '2 x 50 kWp - třídicí linka' }
            else if (ar[i] === 'o60vs') { nadpis = '4 x 60 kWp - třídicí linka' }

            cont.push(
                <div className="radek"
                    style={{ minHeight: phone ? 'auto' : '40px' }}
                >
                    <div className="dfc f1 fs-c1" style={st4} ></div>
                    <div className="dfc f8 jc-s fo-b fs-c+" style={{ ...st4, fontSize: 'clamp(0.7em, 1.4vw, 1em)' }} >{nadpis}</div>
                    <div className="dfc f10 jc-s fs-c1" style={st4} ></div>

                </div >
            )

            for (const [key, value] of Object.entries(resp[ar[i]])) {

                console.log(`${key}: ${value}`);
                cont.push(
                    <div className="radek"
                        style={{ minHeight: phone ? 'auto' : '40px' }}
                    >
                        <div className="dfc f1 fs-c1" style={st4} ></div>
                        <div className="dfc f4 jc-s fs-c1" style={st4} >{key}</div>
                        <div className="dfc f4 jc-s fs-c1" style={st4} >{value.toFixed(3)}</div>
                        <div className="dfc f10 jc-s fs-c1" style={st4} ></div>
                    </div >
                )
            }
            cont.push(
                <div className="radek" >
                    {/* <div className="dfc f1 fs-c1" style={st4} ></div> */}
                </div >
            )
        }

        setContent(cont);




    }, [resp]);



    useEffect(() => {
        UserService.getAdminBoard_all().then(
            (response) => {

                const st4 = {
                    height: '3.6vh',
                }

                let cont = []
                let actUsername = ''
                if (prop.user !== undefined) { actUsername = prop.user.username }

                cont.push(
                    <div style={{
                        position: 'sticky', top: '0', background: '#E9ECEF', zIndex: '1000',
                        borderBottom: '1px solid rgb(140, 140, 140)'
                    }}>
                        <div className="radek-noline" style={{ minHeight: phone ? 'auto' : '3.6vh' }} >
                            <div className="dfc fo-b f20 fs-c3 jcs" >{'Umístění'}</div>
                            <div className="dfc fo-b w12 fs-c3 bg-d" style={st4}>{'FVE'}</div>
                            <div className="dfc fo-b w12 fs-c3" style={st4}>{'Baterie'}</div>
                            <div className="dfc fo-b w12 fs-c3 bg-d" style={st4}>{'EV'}</div>
                            <div className="dfc fo-b w12 fs-c3" style={st4}>{'TČ'}</div>
                            <div className="dfc fo-b w18 fs-c3 bg-d" style={st4}>{'Voda'}</div>
                            <div className="df f6"> </div>
                        </div>
                    </div>
                )
                setTexty(cont);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setContent(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        );
    }, []);


    return (
        <>
            <div className="df" style={{
                height: '96vh',
                overflowY: 'auto',
            }}>
                <div className="df" style={{
                    width: '100%',
                    flexDirection: 'column',
                    background: '#E9ECEF'
                }}>
                    {/* {texty} */}
                    {content}
                </div>
            </div>
        </>
    );
};

export default RTUdata;
