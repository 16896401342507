import React, { useState, useEffect, PureComponent } from "react";
import { Link, useLocation } from "react-router-dom";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';

import { toExcel } from 'to-excel';
import csvDownload from 'json-to-csv-export'

import { mkConfig, generateCsv, download } from "export-to-csv";


import UserService from "../services/user.service";
import ChartService from "../services/chart.service";
import EventBus from "../common/EventBus";
// import Select from 'react-select';
import "./Select.css";
import "./Board.css";

// import Chart from "react-apexcharts";
import Datum from './Datepicker.js';
import Month from './DateMonthpicker.js';



const BoardDeviceCharts = (prop) => {

    const location = useLocation()


    const [dimW, setW] = useState(0);
    const [dimH, setH] = useState(0);

    const [series, setSeries] = useState([{}])
    const [total, setTotal] = useState(0)

    const [table, setTable] = useState(location.state.mac)
    const [tableId, setTableId] = useState(1)

    const [time, setTime] = useState('today') // jaky interval z db
    const [co, setCo] = useState(1) // radiobuttons - jake hodnoty z db (1 => import total)

    const [obTime, setObTime] = useState({})

    const [chartStep, setChartStep] = useState('day')

    const [config, setConfig] = useState({})



    let d = new Date();

    const [newDay, setNewDay] = useState({ y: d.getFullYear(), m: d.getMonth(), d: d.getDate() })
    const [newMonth, setNewMonth] = useState({ y: d.getFullYear(), m: d.getMonth() })



    //** dimension ********************************

    const getDivSize = () => {
        const dim = document.getElementById('graf1').getBoundingClientRect()
        setW(dim.width)
        setH(dim.height)
    }

    useEffect(() => {
        getDivSize()
        const resizeListener = () => {
            getDivSize()
        };
        window.addEventListener('resize', resizeListener);
        return () => { window.removeEventListener('resize', resizeListener); }
    }, [])

    useEffect(() => {
        setToday()
        getConfig()
    }, []);

    useEffect(() => {
        getChart()
    }, [time, co, table]);

    //****************************************************

    // let time

    const getDate = (y, m, d) => {
        setObTime({ y: y, m: m, d: d })

        let n = new Date();
        if (y == n.getFullYear() && m == n.getMonth() + 1 && d == n.getDate()) {
            setTime('today')
        }
        else {
            setTime(JSON.stringify({ time: 'objDay', interval: `${y}-${m}-${d}` }))
        }
        setChartStep('day')
        // getChart()
    }
    const getMonthDate = (y, m) => {
        setObTime({ y: y, m: m, d: 1 })
        setTime(JSON.stringify({ time: 'objMonth', interval: `${y}-${m}` }))
        setChartStep('month')
        // getChart()
    }

    const setToday = () => {
        let d = new Date();
        setObTime({ y: d.getFullYear(), m: d.getMonth() + 1, d: d.getDate() })
        setNewDay({ y: d.getFullYear(), m: d.getMonth(), d: d.getDate() })
        setTime('today')
        setChartStep('day')
        // getChart()
    }

    const setYesterday = () => {
        let d = new Date();
        d.setDate(d.getDate() - 1)
        setObTime({ y: d.getFullYear(), m: d.getMonth(), d: d.getDate() })
        setNewDay({ y: d.getFullYear(), m: d.getMonth(), d: d.getDate() })
        getDate(d.getFullYear(), d.getMonth() + 1, d.getDate())
    }

    const setTMesic = () => {
        let d = new Date();
        setNewMonth({ y: d.getFullYear(), m: d.getMonth() })
        getMonthDate(d.getFullYear(), d.getMonth() + 1)
    }


    const getCSVconfig = () => { // konfig CSV

        let zdroj = config[`nam${tableId}`]
        let time = chartStep === 'day' ? `${obTime.y}-${obTime.m}-${obTime.d}` : `${obTime.y}-${obTime.m}`
        let sss = ''
        if (co == 1) sss = 'Total'
        else if (co == 2) sss = 'L1'
        else if (co == 3) sss = 'L2'
        else if (co == 4) sss = 'L3'

        const csvConfig = mkConfig({
            showTitle: true,
            title: 'Čas;Spotřeba kWh',
            useKeysAsHeaders: true,
            showColumnHeaders: false,
            filename: `${zdroj} ${sss} ${time}`,
            fieldSeparator: ';',
            quoteStrings: false,
        });

        return csvConfig;
    }

    const toCsv = () => {

        let serCorrect = []
        series.forEach(el => {
            serCorrect.push({ tim: el.time, val: el.val.toFixed(3).replace('.', ',') })
        });

        const csv = generateCsv(getCSVconfig())(serCorrect);
        download(getCSVconfig())(csv)
    }



    const getConfig = () => { // CONFIGURACE STRANKY  ********************************************************************************

        ChartService.getDeviceChartsConfig(location.state.mac ? location.state.mac : 0).then(   //data.dbid

            (response) => {
                // console.log(response.data)
                setConfig(...response.data)
            },
            (error) => {
                // const _content =
                //     (error.response &&
                //         error.response.data &&
                //         error.response.data.message) ||
                //     error.message ||
                //     error.toString();
                // setContent(_content);
                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        );
    }


    const getChart = () => { // OBSAH GRAFU ********************************************************************************
        // console.log(time)

        let sco = 'it'
        if (co == 1) sco = 'it'
        else if (co == 2) sco = 'i1'
        else if (co == 3) sco = 'i2'
        else if (co == 4) sco = 'i3'

        ChartService.getDeviceCharts(table ? table : 0, time, sco).then(   //data.dbid

            (response) => {
                // console.log(response.data)

                const sum = response.data.reduce((total, el) => total + el.val, 0);
                setTotal(Math.round(sum / 1000))

                const ser = response.data.map(el => {
                    return { time: el.time, val: el.val / 1000 }
                });

                setSeries(ser)

            },
            (error) => {
                // const _content =
                //     (error.response &&
                //         error.response.data &&
                //         error.response.data.message) ||
                //     error.message ||
                //     error.toString();

                // setContent(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        );
    }

    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload } = this.props;

            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                        {payload.value}
                    </text>
                </g>
            );
        }
    }

    return (

        <div className="df" style={{
            width: '100vw',
            height: '94vh',
            overflowY: 'auto',
        }}>
            <div className="df" style={{
                width: '100%',
                height: 'auto',
                flexDirection: 'column',
                background: '#E9ECEF'
            }}>
                <div style={{ position: 'sticky', top: '0', background: '#E9ECEF', zIndex: '1000' }}>
                    <div className="radek-noline">
                        <div className="dfc fo-b f16" >{location.state.devname}</div>
                    </div>
                </div>

                <div className="dfc fdir-c" style={{ height: 'auto', background: '#E9ECEF' }}>

                    <br></br><br></br><br></br>

                    <div className="df jce" style={{ width: dimW, height: '3vh' }}>


                        <div className="df" style={{ width: '100%', maxWidth: '700px', height: '100%', background: 'redx' }}>

                            <div className="df f9" style={{ background: 'white' }}>
                                <Datum callb={getDate} setdate={newDay} />
                            </div>

                            <div className="df f1"> </div>

                            <div className="df f9" style={{ background: 'white' }}>
                                <Month callb={getMonthDate} setdate={newMonth} />
                            </div>

                            <div className="df f1"> </div>

                            <div className="df f9" style={{ background: 'greenx' }}>
                                <div className="df aic"
                                    style={{ width: '100%', minWidth: '150px', maxWidth: '250px', height: '100%' }}>


                                    <div className="dfc tx-ac hov bg-15 act" style={{ flex: '8', height: '100%' }}
                                        onClick={() => { setTMesic() }}
                                    >
                                        Tento měsíc
                                    </div>
                                    <div className="df f1"></div>

                                    <div className="dfc hov bg-15 act" style={{ flex: '4', height: '100%', background: 'greenx' }}
                                        onClick={() => { setYesterday() }}
                                    >
                                        Včera
                                    </div>

                                    <div className="df f1"></div>

                                    <div className="dfc hov bg-15 act" style={{ flex: '4', height: '100%', background: 'greenx' }}
                                        onClick={() => { setToday() }}
                                    >
                                        Dnes
                                    </div>

                                    {/* <SelectTimeInterval onChange={setTI} /> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <br></br>

                    <div className="df aic" style={{ width: '80vw', height: '2.5vh' }}>

                        <div className="df " style={{}}>
                            <SelectELM config={config} onChange={setTable} onChange2={setTableId} />
                            &nbsp;&nbsp;- &nbsp;{
                                chartStep === 'day' ? `${obTime.d?.toString().padStart(2, '0')}-${obTime.m?.toString().padStart(2, '0')}-${obTime.y}`
                                    : `${obTime.m?.toString().padStart(2, '0')}-${obTime.y}`
                            }
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <div className="df fo-b">{total} </div>
                        &nbsp;&nbsp;
                        <div className="df">kWh</div>
                        <div className="df f20" style={{}}>
                        </div>
                    </div>


                    <br></br>
                    <div id='graf1' className="df" style={{ width: '80vw', height: '50vh', background: 'white' }}>


                        <BarChart width={dimW} height={dimH} data={series}

                            margin={{ top: 60, right: 30, left: 30, bottom: 50 }}
                        >
                            <XAxis dataKey="time" stroke="#8884d8" fontSize='1.1vh'
                                padding={{ left: 0 }}
                                label={{ value: chartStep == 'day' ? "Čas" : 'Den', position: "Center", dy: 30 }}

                                // tick={<CustomizedAxisTick />}

                                tick={{ dx: chartStep === 'day' ? dimW / -58 : 0, fill: 'black' }}

                                tickFormatter={value =>
                                    chartStep === 'day' ? value
                                        : value
                                }

                            // interval={1}
                            // tick={false}
                            />

                            <YAxis label={{ value: "kWh", position: "top", dx: 0, dy: -20 }}
                            // tickFormatter={value => `${value}  ee`}
                            />

                            {/* <Tooltip wrapperStyle={{ width: 200, backgroundColor: 'red' }} /> */}
                            <Tooltip content={chartStep == 'day' ? <CustomTooltip /> : <CustomTooltip2 />} />

                            {/* <Legend width={100} wrapperStyle={{ top: 10, right: 20, backgroundColor: '#f5f5f5', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '40px' }} /> */}

                            <CartesianGrid stroke="#ccc" strokeDasharray="1 3" />

                            <Bar dataKey="val" fill="#8884d8" barSize={35} unit="kWh"
                                label={<CustomizedLabel chst={chartStep} />}
                            />
                        </BarChart>
                    </div>

                    <br></br><br></br>

                    <div className="df jce" style={{ width: dimW, height: '3vh', background: 'bluex' }}>


                        <div className="df f15" style={{ width: '100%', maxWidth: '600px', height: '100%', background: 'redx' }}>

                            <div className="df f8" style={{ background: 'whitex' }}>
                                <Radiobut txt='Total' act={co} pos={1} callb={setCo} />
                            </div>

                            <div className="df f2"> </div>

                            <div className="df f8" style={{ background: 'whitex' }}>
                                <Radiobut txt='Fáze 1' act={co} pos={2} callb={setCo} />
                            </div>

                            <div className="df f2"> </div>

                            <div className="df f8" style={{ background: 'whitex' }}>
                                <Radiobut txt='Fáze 2' act={co} pos={3} callb={setCo} />
                            </div>

                            <div className="df f2"> </div>

                            <div className="df f8" style={{ background: 'whitex' }}>
                                <Radiobut txt='Fáze 3' act={co} pos={4} callb={setCo} />
                            </div>
                        </div>

                        <div className="df f10" style={{ minWidth: '40px' }}></div>

                        <div className="df jce f1" style={{ background: 'yellowx' }}>

                            <div className="df aic"
                                style={{ width: '100%', minWidth: '150px', maxWidth: '250px', height: '100%' }}>

                                <div className="dfc hov bg-15x act" style={{ flex: '8', height: '100%', background: 'greenx' }} >
                                    Export do:
                                </div>
                                <div className="dfc hov bg-15 act" style={{ flex: '4', height: '100%', background: 'greenx' }}
                                    onClick={() => { toCsv() }}
                                >
                                    csv
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div >

    );
};
export default BoardDeviceCharts;

const Radiobut = (pr) => {

    return (
        <div className="df w100">
            <div className="df jce aic f3">
                {pr.txt}&nbsp;&nbsp;
            </div>
            <div className="df f1 hov" style={{ position: 'relative', minWidth: '20px' }} onClick={() => pr.callb(pr.pos)}>
                <svg width="100%" height="100%">
                    <circle cx="50%" cy="50%" r="0.7vh" stroke="#999" strokeWidth="1" fill='#fff'
                    />
                </svg>
                <svg width="100%" height="100%" style={{ position: 'absolute', margin: '0 auto' }} >
                    <circle cx="50%" cy="50%" r="0.5vh" stroke="#444" strokeWidth="0"
                        fill={pr.act == pr.pos ? '#777' : '#fff'}
                    />
                </svg>
            </div>
        </div>
    )
}

const CustomizedLabel = (props) => {

    const { x, y, fill, value } = props;

    const dim = document.getElementById('graf1').getBoundingClientRect()
    let fs = dim.x / 10
    if (fs > 15) fs = 15

    let sval = ''
    if (value > 0) { sval = props.chst === 'day' ? value.toFixed(1) : value.toFixed(0) }

    return <text
        x={x + 4}
        y={y}
        dy={-4}
        // dx={4}
        fontSize={fs}
        fill="black"
        textAnchor="right"
    >
        {sval}
    </text>
};


const CustomTooltip = ({ active, payload, label }) => {

    return (
        <div style={{ border: '1px solid #aaa', backgroundColor: 'white', padding: '15px 15px 5px 15px' }}>
            <p className="label">{label}:00 - {label + 1}:00</p>
            <p className="intro">{payload[0]?.payload.val.toFixed(2)} kWh</p>
        </div>
    );
}

const CustomTooltip2 = ({ active, payload, label }) => {

    return (
        <div style={{ border: '1px solid #aaa', backgroundColor: 'white', padding: '15px 15px 5px 15px' }}>
            <p className="label">den {label}</p>
            <p className="intro">{payload[0]?.payload.val.toFixed(2)} kWh</p>
        </div>
    );
}


const SelectELM = (pr) => {

    const onChange = (event) => {
        const value = event.target.value;
        const num = value > 0 ? `_${value}` : ``
        pr.onChange(`${pr.config.devid}${num}`)
        pr.onChange2(1 * value + 1)
    };

    let content = []
    for (let i = 0; i < pr.config.pocet; i++) {
        content.push(<option value={i}>{pr.config[`nam${i + 1}`]}</option>)
    }

    return (
        pr.config.pocet > 1 ?

            <select onChange={onChange}
                className="hov"
                style={{
                    border: '1px solid #ccc',
                    minWidth: '15vw',
                }}
            >
                {content}
            </select>
            :
            <>{pr.config.nam1}</>

    );
};

