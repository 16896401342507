import React, { useState, useEffect } from "react";
import AppService from "../services/app.service";

import gl from "../global_var.js";
import EventBJS from "../common/EventBusJS.js";

import Top from "./top.js";
import { RowNadpis, Row_1_1, Row_1_3, Row_1_5, Line } from './rows.js';


import "./Page.css";


const PageInv = (ap, user) => {

    const [pr, setContent] = useState(
        {
            P: '',
            today: '',
            total: '',
            uL1: '-', uL2: '-', uL3: '-',
            iL1: '-', iL2: '-', iL3: '-',
            fL1: '-', fL2: '-', fL3: '-',
            uP1: '-', uP2: '-', uP3: '-', uP4: '-', uP5: '-', uP6: '-',
            iP1: '-', iP2: '-', iP3: '-', iP4: '-', iP5: '-', iP6: '-',
            iS1: '', iS3: '', iS5: '', iS7: '', iS9: '', iS11: '',
            iS2: '', iS4: '', iS6: '', iS8: '', iS10: '', iS12: '',
            T: '',
            typ: '',
            sn: '',
        }
    );


    useEffect(() => {

        setTimeout(() => {
            getDataFromSource(); //pri nacteni stranky
        }, 100);

        EventBJS.on("refMenu", () => {
            getDataFromSource(); // pri prepnuti stranky z menu
        });

        const ti = setInterval(() => {
            getDataFromSource(); // pravidelny refresh
        }, 5000);

        return () => {
            clearInterval(ti)
            EventBJS.remove("refMenu");
        };
    }, []);


    const getDataFromSource = () => {
        // console.log(gl.act.devid,gl.act.src)
        AppService.getPageData(gl.act.devid, gl.act.src).then(
            (response) => {

                // console.log(response.data)
                response.data.total = rozdelNaTrojice(response.data.total)
                setContent(response.data)
            })
    }

    function rozdelNaTrojice(ciselnyString, dec = 0) {
        return ciselnyString.toFixed(dec).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }


    return (
        <>
            <Top txt={gl.act.top}/>

            <div className="vspace"></div>

            <div className="cont-p bg-15">
                <Row_1_1 txt='Aktuální výkon (kW)' v={pr.P} />
            </div>

            <RowNadpis txt='Výroba' />
            <div className="cont-p bg-15">
                <Row_1_1 txt='Dnes (kWh)' v={pr.today} />
                <Line />
                <Row_1_1 txt='Celkem (kWh)' v={pr.total} />
            </div>

            <div className="vspace"></div>

            <RowNadpis txt='Provozní hodnoty' />
            <div className="cont-p bg-15">
                <Row_1_3 txt='Napětí (V)' v1={pr.uL1} v2={pr.uL2} v3={pr.uL3} />
                <Line />
                <Row_1_3 txt='Proud (A)' v1={pr.iL1} v2={pr.iL2} v3={pr.iL3} />
                <Line />
                <Row_1_3 txt='Frekvence (Hz)' v1={pr.fL1} v2={pr.fL2} v3={pr.fL3} />
            </div>

            <div className="vspace"></div>

            <RowNadpis txt='PV' />
            <div className="cont-p bg-15">
                <Row_1_5 txt='Napětí (V)' v1={pr.uP1} v2={pr.uP2} v3={pr.uP3} v4={pr.uP4} v5={pr.uP5} />
                <Line />
                <Row_1_5 txt='Proud (A)' v1={pr.iP1} v2={pr.iP2} v3={pr.iP3} v4={pr.iP4} v5={pr.iP5} />
            </div>

            <div className="vspace"></div>

            <RowNadpis txt='Stringy' />
            <div className="cont-p bg-15">
                <Row_1_5 txt='Proud (A)' v1={pr.iS1} v2={pr.iS3} v3={pr.iS5} v4={pr.iS7} v5={pr.iS9} />
                <Line />
                <Row_1_5 v1={pr.iS2} v2={pr.iS3} v3={pr.iS4} v4={pr.iS5} v5={pr.iS10} />
            </div>

            <div className="vspace"></div>

            <div className="cont-p bg-15">
                <Row_1_1 txt='Teplota střídače (°C)' v={pr.T} />
            </div>

            <div className="vspace"></div>

            <RowNadpis txt='Střídač' />
            <div className="cont-p bg-15">
                <Row_1_1 txt='typ' v={pr.typ} />
                <Line />
                <Row_1_1 txt='SN' v={pr.sn} />
            </div>

            <div className="vspace"></div>
        </>
    );
};

export default PageInv;

// PageInv.defaultProps = {
//     P: '',
//     today: '',
//     total: '',
//     uL1: '-', uL2: '-', uL3: '-',
//     iL1: '-', iL2: '-', iL3: '-',
//     fL1: '-', fL2: '-', fL3: '-',
//     uP1: '-', uP2: '-', uP3: '-', uP4: '-', uP5: '-', uP6: '-',
//     iP1: '-', iP2: '-', iP3: '-', iP4: '-', iP5: '-', iP6: '-',
//     iS1: '', iS3: '', iS5: '', iS7: '', iS9: '', iS11: '',
//     iS2: '', iS4: '', iS6: '', iS8: '', iS10: '', iS12: '',
//     tep: '',
//     typ: '',
//     sn: '',
// };

