const fakedata = {
    "data": [
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 7,
            "mi": 35,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 7,
            "mi": 40,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 7,
            "mi": 45,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 7,
            "mi": 50,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 7,
            "mi": 55,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 8,
            "mi": 0,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 8,
            "mi": 5,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 8,
            "mi": 10,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 8,
            "mi": 15,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 8,
            "mi": 20,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 8,
            "mi": 25,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 8,
            "mi": 30,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 8,
            "mi": 35,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 8,
            "mi": 40,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 8,
            "mi": 45,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 8,
            "mi": 50,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 8,
            "mi": 55,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 9,
            "mi": 0,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 9,
            "mi": 5,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 9,
            "mi": 10,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 9,
            "mi": 15,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 9,
            "mi": 20,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 9,
            "mi": 25,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 9,
            "mi": 30,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 9,
            "mi": 35,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 9,
            "mi": 40,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 9,
            "mi": 45,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 9,
            "mi": 50,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 9,
            "mi": 55,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 10,
            "mi": 0,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 10,
            "mi": 5,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 10,
            "mi": 10,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 10,
            "mi": 15,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 10,
            "mi": 20,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 10,
            "mi": 25,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 10,
            "mi": 30,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 10,
            "mi": 35,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 10,
            "mi": 40,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 10,
            "mi": 45,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 10,
            "mi": 50,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 10,
            "mi": 55,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 11,
            "mi": 0,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 11,
            "mi": 5,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 11,
            "mi": 10,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 11,
            "mi": 15,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 11,
            "mi": 20,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 11,
            "mi": 25,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 11,
            "mi": 30,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 11,
            "mi": 35,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 11,
            "mi": 40,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 11,
            "mi": 45,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 11,
            "mi": 50,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 11,
            "mi": 55,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 12,
            "mi": 0,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 12,
            "mi": 5,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 12,
            "mi": 10,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 12,
            "mi": 15,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 12,
            "mi": 20,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 12,
            "mi": 25,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 12,
            "mi": 30,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 12,
            "mi": 35,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 12,
            "mi": 40,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 12,
            "mi": 45,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 12,
            "mi": 50,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 12,
            "mi": 55,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 13,
            "mi": 0,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 13,
            "mi": 5,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 13,
            "mi": 10,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 13,
            "mi": 15,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 13,
            "mi": 20,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 13,
            "mi": 25,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 13,
            "mi": 30,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 13,
            "mi": 35,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 13,
            "mi": 40,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 13,
            "mi": 45,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 13,
            "mi": 50,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 13,
            "mi": 55,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 14,
            "mi": 0,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 14,
            "mi": 5,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 14,
            "mi": 10,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 14,
            "mi": 15,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 14,
            "mi": 20,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 14,
            "mi": 25,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 14,
            "mi": 30,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 14,
            "mi": 35,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 14,
            "mi": 40,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 14,
            "mi": 45,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 14,
            "mi": 50,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 14,
            "mi": 55,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 15,
            "mi": 0,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 15,
            "mi": 5,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 15,
            "mi": 10,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 15,
            "mi": 15,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 15,
            "mi": 20,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 15,
            "mi": 25,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 15,
            "mi": 30,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 15,
            "mi": 35,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 15,
            "mi": 40,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 15,
            "mi": 45,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 15,
            "mi": 50,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 15,
            "mi": 55,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 16,
            "mi": 0,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 16,
            "mi": 5,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 16,
            "mi": 10,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 16,
            "mi": 15,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 16,
            "mi": 20,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 16,
            "mi": 25,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 16,
            "mi": 30,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 16,
            "mi": 35,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 16,
            "mi": 40,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        },
        {
            "y": 2022,
            "mo": 11,
            "d": 23,
            "h": 16,
            "mi": 45,
            "pv": 0,
            "load": 0,
            "bat": 51,
            "charge": 38,
            "disch": 0,
            "auto": 0,
            "grid": 0,
            "feed": 457,
            "surplus": 0
        }
    ]
}

module.exports = {
    fakedata: fakedata
};
